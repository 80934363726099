import React, { useState, useContext} from "react";
import styles from "./styles";
import oneMerchantOneGuarantor from '../../templates/1 Merchant 1 Guarantor.docx';
import oneMerchantTwoGuarantors from '../../templates/1 Merchant 2 Guarantors.docx';
import soleProprietor from '../../templates/Sole Proprietor.docx';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GenerateButtons from "./GenerateButtons";
import SelectButton from "../shared/SelectButton";
import SelectField from "../shared/SelectField";
import { AppContext } from "../AppContext";

const exclusions = ["the", "llc", "inc"];

function LawsuitGenerator({ merchant, setActionsFlag, selectedDate }) {
    // Component States...
    const [open, setOpen] = useState(true);
    const [selectedNames, setSelectedNames] = useState({
        merchShortNm: null,
        guar1ShortNm: null,
        guar2ShortNm: null,
    });
    const [inputNames, setInputNames] = useState({
        merchShortNm: "",
        guar1ShortNm: "",
        guar2ShortNm: "",
    });
    // Import context
    const { court, setCourt } = useContext(AppContext);
    // Formatted vars
    const merchantName  = merchant.merchants_legal_name_title_case;
    const firstGuarName = merchant.first_guarantor_title_case;
    const secondGuarName = merchant.second_guarantor_title_case;
    const entityType = merchant.type_of_entity.toLowerCase();
    // Boolean check for isSoleProp or not
    const isSoleProp = merchantName === firstGuarName || entityType === "sole proprietorship";

    let docToBeGenerated = null;
    if (isSoleProp) {
        docToBeGenerated = soleProprietor
    }
    else if (secondGuarName) {
        docToBeGenerated = oneMerchantTwoGuarantors
    } else {
        docToBeGenerated = oneMerchantOneGuarantor
    };

    function formatRow(row) {
        const splitWords = row.split(" ")
        const noSymbols  = splitWords.map(word => word.replace(/[^\w\s]/g, ''))
        const fltWords   = noSymbols.filter(word =>!exclusions.includes(word.toLowerCase()))
        const longWords  = fltWords.filter(word => word.length > 2)
        return longWords
    };

    const merchWords = formatRow(merchantName);
    const guar1Words = formatRow(firstGuarName);
    const guar2Words = formatRow(secondGuarName);

    function handleSelect(name, option) {
        setSelectedNames({...selectedNames, [name]: option})
        setInputNames({...inputNames, [name]: ""})
    };
    
    function handleChange(event) {
        setInputNames({...inputNames, [event.target.name]: event.target.value})
        setSelectedNames({...selectedNames, [event.target.name]: ""})
    };

    const handleClose = () => {setOpen(false); setActionsFlag(false)};
    
    const merchantRow = merchWords.slice(0,3).map(opt => (
        <SelectButton 
            key={opt} 
            opt={opt}
            optToSelect={selectedNames.merchShortNm}
            handleClick={() => handleSelect("merchShortNm", opt)}
        />
    ));
    const firstGuarRow = guar1Words.slice(0,3).map(opt => (
        <SelectButton
            key={opt} 
            opt={opt}
            optToSelect={selectedNames.guar1ShortNm}
            handleClick={() => handleSelect("guar1ShortNm", opt)}
        />
    ));
    const secondGuarRow = guar2Words.slice(0,3).map(opt => (
        <SelectButton
            key={opt} 
            opt={opt}
            optToSelect={selectedNames.guar2ShortNm}
            handleClick={() => handleSelect("guar2ShortNm", opt)}
        />
    ));

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{sx: styles.paperProps}}>
            <Typography sx={styles.title}>GENERATE LAWSUIT</Typography>
            <Box sx={styles.box}>
                <Typography sx={styles.boxTitle}>Court</Typography>
                <SelectButton opt="KINGS"  optToSelect={court} handleClick={() => setCourt("KINGS")}/>
                <SelectButton opt="NASSAU" optToSelect={court} handleClick={() => setCourt("NASSAU")}/>
            </Box>
            <Divider/>
            <Box sx={styles.box}>
                <Typography sx={styles.boxTitle}>{merchantName}</Typography>
                {merchantRow}
                <SelectField handleChange={handleChange} name="merchShortNm" value={inputNames.merchShortNm}/>
            </Box>
            <Divider/>
            {guar1Words.length > 0 && !isSoleProp && (
                <>
                <Box sx={styles.box}>
                    <Typography sx={styles.boxTitle}>{firstGuarName}</Typography>
                    {firstGuarRow}
                    <SelectField handleChange={handleChange} name="guar1ShortNm" value={inputNames.guar1ShortNm}/>
                </Box>
                <Divider/>
                </>
            )}
            {guar2Words.length > 0 && !isSoleProp && (
                <>
                <Box sx={styles.box}>
                    <Typography sx={styles.boxTitle}>{secondGuarName}</Typography>
                    {secondGuarRow}
                    <SelectField handleChange={handleChange} name="guar2ShortNm" value={inputNames.guar2ShortNm}/>
                </Box>
                <Divider/>
                </>
            )}
            <GenerateButtons 
                merchant={merchant} 
                inputNames={inputNames} 
                selectedNames={selectedNames}
                isSoleProp={isSoleProp}
                docToBeGenerated={docToBeGenerated}
                selectedDate={selectedDate}
                firstGuarName={firstGuarName}
                secondGuarName={secondGuarName}
            />
        </Dialog>
    );
};
export default LawsuitGenerator;