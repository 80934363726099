const styles = {

    container: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        width: '500px',
        py: 3
    },

    title: {
        fontSize: '2.5rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        mb: 1
    },

    button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none', 
        borderRadius: '0px',
        width: '350px',
        backgroundColor: '#5d9bd5',
        color: 'black',
        p: 1,
        mt: 2,
        '&:hover': {backgroundColor: '#4a82b0'}
    },
    
    error: {
        fontFamily: "AmazonEmberRegular",
        color: '#d32f2f',
        pt: '1rem' 
    }
}
export default styles