import React, { useState, useEffect, useContext } from "react";
import SettlementTurnoverCard from "./SettlementTurnoverCard";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import styles from "./styles";
import { AppContext } from "../AppContext";
import { formatCurrency } from '../utils'

function SettlementTurnover({ merchant }) {

    const { postTurnover } = useContext(AppContext);

    const [turnoverData, setTurnoverData] = useState({
        settlement_id: '',
        ucc_recipient: '', 
        ucc_letter_date: '',
        amount: '', 
    });

    useEffect(() => {
        if(merchant.settlement) {
            setTurnoverData({
                settlement_id: merchant.settlement.id,
                ucc_recipient: '', 
                ucc_letter_date: '',
                amount: '', 
            });
        };
    }, [merchant]);

    const [errors, setErrors] = useState([]);

    const errorMessages = errors.map(error => <li key={error} style={styles.errors}>{error}</li>);

    let turnoverCards = [];

    if(merchant.settlement) {
        const turnovers = merchant.settlement.turnovers
        turnoverCards = turnovers.map(turnover => <SettlementTurnoverCard key={turnover.id} turnover={turnover} />)
    };
    
    function formatAmountBlur() {
        const cleanedAmount = turnoverData.amount.replace(/[^\d.-]/g, '');
        const cleanedAmountNum = formatCurrency(parseFloat(cleanedAmount)) ;
        setTurnoverData({...turnoverData, amount: cleanedAmountNum === "NaN" ? "" : cleanedAmountNum});
    };

    function handleSubmit(event) {
        event.preventDefault()
        fetch('/turnovers', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(turnoverData)
        })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => postTurnover(data))
            } else {
                response.json().then(data => setErrors(data.errors))
            }
        })
    };

    function turnoverChange(event) {
        setErrors([]);
        setTurnoverData({...turnoverData, [event.target.name]: event.target.value});
    };

    return (
        
        <Box sx={styles.containerBorder} p={1} mr={0.5} component="form" onSubmit={handleSubmit}>
            
            <Typography sx={styles.sectionTitle}>UCC Turnover</Typography>
            
            <Box sx={styles.fieldBox}>
                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder='UCC Recipient'
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.turnoverFieldRecipient}}
                    onChange={turnoverChange}
                    name="ucc_recipient"
                    value={turnoverData.ucc_recipient}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder='Amount'
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{
                        style: styles.turnoverFieldAmount, 
                        startAdornment:<Typography sx={styles.inputAdornment}>$</Typography>
                    }} 
                    onBlur={formatAmountBlur}
                    onChange={turnoverChange}
                    name="amount"   
                    value={turnoverData.amount}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder='Letter Date'
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.turnoverFieldDate}} 
                    onChange={turnoverChange}
                    name="ucc_letter_date" 
                    value={turnoverData.ucc_letter_date}
                />

                <Button type="submit" sx={styles.submitButton}>Add Row</Button>
            </Box>
            
            {errorMessages}
            
            <Divider sx={styles.divider}/>
            
            {turnoverCards}  
        </Box> 
    );
};
export default SettlementTurnover;