import React, { useContext, useState } from 'react';
import styles from "./styles";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AppContext } from "../AppContext";

function AutoComplete({merchantId, inputProps, InputProps, handleInputChange, inputValue}) {
    const { lawyers, editMerchant } = useContext(AppContext);
    const [value, setValue] = useState(null);

    function assignLawyer(newValue) {
        fetch(`/merchants/${merchantId}`, {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({lawyer_id: newValue.id})
        })
        .then(response => response.json())
        .then(data => editMerchant(data))        
    }
    function handleChange(_, newValue) {
        setValue(newValue)
        assignLawyer(newValue)
    }

    return (
        <Autocomplete
            freeSolo
            disableClearable
            disablePortal
            options={lawyers}
            getOptionLabel={(option) => option.lawyer}
            filterOptions={(options) => options}
            onChange={handleChange}
            onInputChange={handleInputChange}
            value={value}
            inputValue={inputValue}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    size="small"
                    inputProps={{...params.inputProps, sx: styles[inputProps]}}
                    InputProps={{...params.InputProps, sx: styles[InputProps]}}
                />
            )}
        />
    );
};
export default AutoComplete;