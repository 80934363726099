import React from 'react'
import styles from './styles'
import TemporaryLinkForm from '../login/TemporaryLinkForm'
import DropZoneContainer from '../dropzones/DropZoneContainer'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LogoImage from '../../images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({ breakpoints: { values: { xs: 0, sm: 600, md: 1500, lg: 1850 } } });

function SubmissionsContainer() {
   
   return (
      <ThemeProvider theme={theme}>
         <TemporaryLinkForm  />
         <Container maxWidth="xl" sx={styles.container}>
            <Box>
               <img src={LogoImage} alt="logo" />
            </Box>
            <Box>
               <Typography sx={styles.instructions}>
                  Please drag and drop the appropriate document into the designated drop zone.
               </Typography>
            </Box>
            <DropZoneContainer/>
         </Container>
      </ThemeProvider> 
   );
}
export default SubmissionsContainer;