import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import styles from './merchantStyles';

function MerchantUccField({ row }) {

    return (

        <Box sx={styles.uccFieldBox}>
            <TextField
                size="small"
                autoComplete='new-password'
                InputProps={{style: styles.uccTextField}}
                value={row.key}
            />

            <TextField
                size="small"
                autoComplete='new-password'
                InputProps={{style: styles.uccTextField }} 
                value={row.value}  
            />
        </Box>  
    );
};
export default MerchantUccField;