const styles = {

    // ============= MerchantDocumentsList.js ============ //
    container: {
        minHeight: '95vh',
        py: 4,
        boxShadow: '0 10px 15px rgba(0, 23, 81, 0.2)'
    },

    parentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        border: 'solid 1px #ccc',
        px: 1
    },

    arrowIcon: {
        fontSize: 40,
        cursor: 'pointer',
        '&:hover': { color: '#FF9900'}
    },

    title: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '2.5rem', 
        fontWeight: 'bold',
        textAlign: 'center',
        flexGrow: 1 
    },
    
    // ============= MerchantDocumentsItem.js ============ //

    box: {
        display: 'flex',
        justifyContent: 'space-between',
        borderLeft: 'solid 1px',
        borderRight: 'solid 1px',
        borderBottom: 'solid 1px',
        borderColor: '#ccc',
        px: 3,
        py: 1
    },
    
    innerBox: {
        display: 'flex'
    },

    fileName: {
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold'
    },

    redacted: {
        fontFamily: 'AmazonEmberRegular'
    },

    fileIcon: {
        fontSize: 30,
        color: '#0073bb',
        '&:hover': { color: '#004d99', cursor: 'pointer'}
    }
}
export default styles