const styles = { 

    dialog: {
        p: 4
    },

    header: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        textAlign: 'center'
    },

    buttonContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        mt: 3
    },

    overrideButton: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1rem',
        height: '45px',
        textTransform: 'none',
        boxShadow: 'none',
        mx: 2,
        color: '#c62828',
        border: 'solid 1px #c62828',
        '&:hover': { color: 'white', backgroundColor: '#c62828', border: 'solid 1px #c62828'}
    },

    AddInfoButton: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1rem',
        height: '45px',
        textTransform: 'none',
        boxShadow: 'none',
        mx: 2,
        '&:hover': { color: 'black', backgroundColor: '#FF9900', border: 'solid 1px #FF9900'}
    },

    dividerMargin: {
        mt: 4, 
        mb: 1
    },

    closeIcon: {
        alignSelf: 'flex-end', 
        mr: 0.7, 
        color: '#1976d2',
        '&:hover': {color: 'black', backgroundColor: '#5d9bd5', cursor: 'pointer'}
    },

    inputContainer: {
        display: 'flex'
    },

    inputLabel: {
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold',
        fontSize: '1rem' 
    },

    genButton: {
        fontFamily: 'AmazonEmberRegular',
        backgroundColor: '#5d9bd5',
        color: 'black',
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '0px',
        width: '300px',
        alignSelf: 'center',
        mt: 3,
        p: 1,
        '&:hover': {backgroundColor: '#4a89c3', boxShadow: 'none'}
    },

    error: {
        fontFamily: 'AmazonEmberRegular',
        color: '#c62828',
        textAlign: 'center',
        pt: 3
    }
}
export default styles