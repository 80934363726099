import React from "react";
import styles from "./styles";
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

function ColumnHeader({ col, handleClick }) {
    const colStyle = {
        ...styles.hdrCell, 
        borderLeft: col.name === "Law Firm" ? 'solid 1px #ccc' : null, 
        width: col.width
    }
    return (
        <TableCell sx={colStyle}>
            <Box sx={styles.hdrCellBox}>
                <Typography sx={styles.hdrLabel}>{col.name}</Typography>
                <MenuIcon
                    sx={styles.filterIcon} 
                    onClick={(e) => handleClick(e, col.column)}
                />
            </Box>
        </TableCell>
    );
};
export default ColumnHeader;