import React from 'react';
import styles from "./styles";
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

function LawyerPopover({anchorEl, handleClose, handleSearchChange, handleSortClick, search}) {

    const open = Boolean(anchorEl);
    
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            slotProps={{paper: {sx: {p: 1}}}}
        >
            <Typography sx={styles.sort} onClick={handleSortClick}>SORT</Typography>
            <TextField
                size="small"
                placeholder='Filter...'
                onChange={handleSearchChange}
                value={search}
                inputProps={{sx: styles.inputPropsPopover}}
                InputProps={{ 
                    sx: styles.InputPropsPopover,
                    startAdornment: <InputAdornment position="start"> <SearchIcon /> </InputAdornment>
                }}
            /> 
        </Popover>
    );
};
export default LawyerPopover;