import React, { useState, useEffect } from "react"

const AppContext = React.createContext() 

function MyProvider({ children }) {

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [signUpFormVisible, setSignUpFormVisible] = useState(false)
    const [merchants, setMerchants] = useState([])
    const [lawyers, setLawyers] = useState([])
    const [banks, setBanks] = useState([])
    const [court, setCourt] = useState('KINGS')

    useEffect(() => {
        setIsLoading(true)
        fetch('/me', {
            headers: {'X-Secret-Header': 'flatdragonswoopxzw337'}
        })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setUser(data)
                    setIsLoading(false)
                })      
            } else { setIsLoading(false) }
        })
    },[])

    useEffect(() => {
        fetch('/merchants', {
            headers: {'X-Secret-Header': 'flatdragonswoopxzw337'}
        })
        .then(response => response.json())
        .then(data => setMerchants(data))
    }, [])

    useEffect(() => {
        fetch('/lawyers', {
            headers: {'X-Secret-Header': 'flatdragonswoopxzw337'}
        })
        .then(response => response.json())
        .then(data => setLawyers(data))
    }, [])

    useEffect(() => {
        fetch('/banks', {
            headers: {'X-Secret-Header': 'flatdragonswoopxzw337'}
        })
        .then(response => response.json())
        .then(data => setBanks(data))
    }, [])
    // ======================== Centralized Lawyer Submission Function ======================== //
    // The `handleSubmitLawyer()` function has been placed in `AppContext.js`,
    // which is unconventional but necessary due to the specific needs of the app.
    // This application excepts lawyer submissions from three different locations:
    // 1) The main centralized lawyer page/grid
    // 2) The form on the merchants dashboard
    // 3) The form on the settlement override popup

    // Given these multiple submission points, it made sense to centralize 
    // the `handleSubmitLawyer()` function in a single location to avoid rewriting 
    // the same logic three times. 
    function handleSubmitLawyer(data, addToMerch, setGenDoc, setLawFormError) {
        fetch('/lawyers', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                ...data,
                law_firm_upcase: data.law_firm ? data.law_firm.toUpperCase() : ""
            })
        })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setLawyers([...lawyers, data.lawyer])
                    if (addToMerch) addLawyerToMerchant(data)
                    if (setGenDoc) setGenDoc(true)
                })
            } else {
                response.json().then(data => {
                    if (setLawFormError) setLawFormError(data.errors[0])
                })
            }
        })
    }
    // ========================================================================= //
    function editMerchant(data) {
        const updateMerchants = merchants.map(merchant => {
            if (merchant.id === data.id) {
                return data
            } else { return merchant }
        })
        setMerchants(updateMerchants)
    }

    function addLawyerToMerchant(data) {
        const associateLawyerWithMerchant = merchants.map(merchant => {
            if (merchant.id === data.merchant.id) {
                return { ...merchant, ...data.merchant, lawyer: data.lawyer };
            } else { return merchant }
        })
        setMerchants(associateLawyerWithMerchant)
    }

    function editSettlement(data) {
        const updateSettlement = merchants.map(merchant => {
            if (merchant.settlement.id === data.id) {
                return {...merchant, settlement: {...data}}
            } else { return merchant}
        }) 
        setMerchants(updateSettlement)
    }

    function postTurnover(data) {
        const postSettlementTuronover = merchants.map(merchant => {
            if (merchant.settlement.id === data.settlement_id) {
                return {
                    ...merchant, settlement: {...merchant.settlement, turnovers: [...merchant.settlement.turnovers, data]}
                }
            } else { return merchant }
        })
        setMerchants(postSettlementTuronover)
    }

    function editTurnover(data) {
        const editSettlementTurnover = merchants.map((merchant) => {
            if (merchant.settlement.id === data.settlement_id) {
                return {
                    ...merchant, 
                    settlement: {
                        ...merchant.settlement, 
                        turnovers: merchant.settlement.turnovers.map((turnover) => {
                            if (turnover.id === data.id) { return data } 
                            else { return turnover }
                        })
                    }
                }
            } else { return merchant }
        })
        setMerchants(editSettlementTurnover)
    }

    function deleteTurnover(data) {
        const removeSettlementTurnover = merchants.map(merchant => {
            if (merchant.settlement.id === data.settlement_id) {
                return {
                    ...merchant, 
                    settlement: {
                        ...merchant.settlement, 
                        turnovers: merchant.settlement.turnovers.filter(turnover => turnover.id !== data.id)
                    }
                }
            } else { return merchant }
        })
        setMerchants(removeSettlementTurnover)
    }

    function postPayment(data) {
        const postSettlementPayment = merchants.map((merchant) => {
            if (merchant.settlement.id === data.settlement_id) {
                return {...merchant, settlement: {...merchant.settlement, payments: [...merchant.settlement.payments, data]}}
            } else { return merchant }
        })
        setMerchants(postSettlementPayment)
    }

    function editPayment(data) {
        const editSettlementPayment = merchants.map((merchant) => {
            if (merchant.settlement.id === data.settlement_id) {
                return {
                    ...merchant, 
                    settlement: {
                        ...merchant.settlement, 
                        payments: merchant.settlement.payments.map((payment) => {
                            if (payment.id === data.id) {return data} 
                            else { return payment }
                        })
                    }
                }
            } else { return merchant }
        })
        setMerchants(editSettlementPayment)
    }

    function deletePayment(data) {
        const removeSettlementPayment = merchants.map((merchant) => {
            if (merchant.settlement.id === data.settlement_id) {
                return {
                    ...merchant, 
                    settlement: {
                        ...merchant.settlement, 
                        payments: merchant.settlement.payments.filter(payment => payment.id !== data.id)
                    }
                }
            } else { return merchant }
        })
        setMerchants(removeSettlementPayment)
    }

    function deleteLawyer(data) {
        setLawyers(lawyers.filter(lawyer => lawyer.id !== data.id))

        const removeLawyerfromMerchant = merchants.map((merchant) => {
            if (merchant.lawyer_id === data.id) {
                return {...merchant, lawyer: null}
            } else { 
                return merchant 
            }
        })
        setMerchants(removeLawyerfromMerchant)
    }

    function editLawyer(data) {
        const updatedLawyerArray = lawyers.map((lawyer) => {
            if (lawyer.id === data.id) {
                return data
            } else { return lawyer}
        })
        setLawyers(updatedLawyerArray)

        const updateLawyerInMerchants = merchants.map((merchant) => {
            if (merchant.lawyer_id === data.id) {
                return {...merchant, lawyer: data}
            } else {
                return merchant
            }
        })
        setMerchants(updateLawyerInMerchants)
    }

    function updateBanks(data) {
        const updatedBanks = banks.map(bank => {
            if (bank.id === data.id) {
                return data
            } else {
                return bank
            }
        })
        setBanks(updatedBanks)
    }

    return (

        <AppContext.Provider 
            value={{
                setUser, 
                user, 
                isLoading, 
                signUpFormVisible, 
                setSignUpFormVisible,
                merchants,
                setMerchants,
                editMerchant,
                deleteTurnover,
                editTurnover,
                postTurnover,
                postPayment,
                deletePayment,
                editPayment,
                editSettlement,
                lawyers,
                setLawyers,
                deleteLawyer,
                editLawyer,
                addLawyerToMerchant,
                handleSubmitLawyer,
                court,
                setCourt,
                banks,
                setBanks,
                updateBanks
            }}
        >
            {children}
        </AppContext.Provider>
    ) 
}
export { MyProvider, AppContext}