import React, { useState, useContext } from "react";
import styles from "./styles";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PdfLoadingModal from  "../PdfLoadingModal";
import { saveAs } from 'file-saver'
import { PDFDocument } from "pdf-lib"
import { AppContext } from "../AppContext"
import { loadFile, convertDocxToPdf, addDocToMerchantsDocuments, docxTemplateGenerate } from "../utils"

function GenerateButtons({merchant, inputNames, selectedNames, isSoleProp, docToBeGenerated, selectedDate, firstGuarName, secondGuarName}) {
    const [generationError, setGenerationError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { court, editMerchant } = useContext(AppContext)

    let exhibitPage = null

    const merchantsDocsSorted = merchant.document_info.sort((a, b) => {
        return a.filename.localeCompare(b.filename)
    })
    
    // Component functions...
    async function getExhibitPage(url) {
        await fetch('/find-exhibit-base-page', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({url: url})
        })
        .then(response => response.json())
        .then(data => exhibitPage = data.page_number)
    }

    async function addMerchantAttachments(generatedUrl) {
        await getExhibitPage(generatedUrl)

        const contractUrl =  merchantsDocsSorted[0].url
        const fundingConfUrl = merchantsDocsSorted[2].url
        const paymentHistUrl = merchantsDocsSorted[4].url

        const summonsComp = await fetch(generatedUrl).then((res) => res.arrayBuffer());

        const contract = await fetch(contractUrl).then((res) => res.arrayBuffer());
        const fundingConf = await fetch(fundingConfUrl).then((res) => res.arrayBuffer());
        const paymentHist = await fetch(paymentHistUrl).then((res) => res.arrayBuffer());

        const masterDocument = await PDFDocument.load(summonsComp);
        const readContract = await PDFDocument.load(contract);
        const readPaymentHist = await PDFDocument.load(paymentHist);

        const contractPages = readContract.getPages();
        const paymentHistPages = readPaymentHist.getPages();

        const contractLength = readContract.getPageCount();
        const paymentHistLength = readPaymentHist.getPageCount();

        const [existingPage] = masterDocument.getPages();
        const { width, height } = existingPage.getSize();

        let basePage = exhibitPage;

        for(let i = 0; i < contractLength; i++) {
            const embeddedPage = await masterDocument.embedPage(contractPages[i]);
            const contractPage = masterDocument.insertPage(basePage += 1, [width, height]);
            contractPage.drawPage(embeddedPage, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
        };

        basePage = exhibitPage;

        const embeddedPng = await masterDocument.embedPng(fundingConf);
        const pngDims = embeddedPng.scale(0.75);

        const imageInsertionIndex  = basePage + contractLength + 2;
        const imagePage = masterDocument.insertPage(imageInsertionIndex, [width, pngDims.height]);

        imagePage.drawImage(embeddedPng, {
            x: 0,
            y: 0,
            width: width,
            height: pngDims.height
        });

        let paymentInsertionIndex = imageInsertionIndex + 1;

        for(let i = 0; i < paymentHistLength; i++) {
            const embeddedPage = await masterDocument.embedPage(paymentHistPages[i]);
            const paymentPage = masterDocument.insertPage(paymentInsertionIndex += 1, [width, height]);
            paymentPage.drawPage(embeddedPage, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
        };

        paymentInsertionIndex = imageInsertionIndex + 1;

        const pdfBytes = await masterDocument.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        setIsLoading(false)
        saveAs(pdfBlob, `Summons & Complaint-${merchant.merchants_legal_name_title_case}.pdf`);
        addDocToMerchantsDocuments(editMerchant, pdfBlob, merchant, "Lawsuit")
    }
    
    function createDocument(docType, additionalData) {
        setGenerationError('')
        loadFile(
            docToBeGenerated,
            async (error, content) => {
                const out = docxTemplateGenerate(content, {...merchant, ...additionalData})
                if (docType === "word") {
                    saveAs(out, `Summons & Complaint-${merchant.merchants_legal_name_title_case}.docx`);
                }
                else if (docType === "pdf") {
                    setIsLoading(true)
                    const pdfUrl = await convertDocxToPdf(out)
                    addMerchantAttachments(pdfUrl)
                }
            }
        )
    }
    
    function generateDocument(docType) {
        const additionalData = {
            currentDate: selectedDate,
            court: court,
            entityShortName:     selectedNames.merchShortNm ? selectedNames.merchShortNm : inputNames.merchShortNm,
            firstGuarShortName:  selectedNames.guar1ShortNm ? selectedNames.guar1ShortNm : inputNames.guar1ShortNm,
            secondGuarShortName: selectedNames.guar2ShortNm ? selectedNames.guar2ShortNm : inputNames.guar2ShortNm
        }

        const allFieldsFilled = Object.values(additionalData).every(value => value)
        
        if (!firstGuarName) {
            setGenerationError('ERROR! Guarantor # 1 field must be filled to generate the document.')
        }
        else if (isSoleProp && !additionalData.entityShortName) {
            setGenerationError('Please fill in all required fields before generating the document.')
        } 
        else if (!isSoleProp && secondGuarName && !allFieldsFilled) {
            setGenerationError('Please fill in all required fields before generating the document.')
        }
        else if (!isSoleProp && (!additionalData.entityShortName || !additionalData.firstGuarShortName)) {
            setGenerationError('Please fill in all required fields before generating the document.')
        }
        else {
            createDocument(docType, additionalData)
        }
    }
    
    return (
        <>
        <Box sx={styles.buttonBox}>
            <PdfLoadingModal open={isLoading}/>
            <Button sx={styles.button} onClick={() => generateDocument("word")}>Generate Word Doc</Button>
            <Button sx={styles.button} onClick={() => generateDocument("pdf")}>Generate Lawsuit</Button>
        </Box>
        <Typography sx={styles.error}>{generationError}</Typography>
        </>
    );
};
export default GenerateButtons;