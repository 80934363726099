import React from "react";
import styles from './styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function SettlementDocuSign() {
    
    return (

        <Box sx={styles.containerBorder} p={1} ml={0.5}>
            <Typography sx={styles.sectionTitle}>DocuSign</Typography>
            <Box>
                <Button sx={styles.docuSignButton}>Send</Button>
                <Button sx={styles.docuSignButton}>Resend</Button>
                <Button sx={styles.docuSignButton}>Edit</Button>
            </Box>
            <Box sx={styles.flex}>
                <Button sx={styles.docuSignButton}>Void</Button>
                <Typography sx={styles.status}>Not Signed</Typography>
                <Button sx={styles.docuSignButton}>Blank</Button>
            </Box>
        </Box>
    );
};
export default SettlementDocuSign;