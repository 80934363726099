const styles = {
    
    container: {
        minHeight: '96vh',
        border: '1px solid #ccc', 
        borderRadius: '7px',
        overflowX: 'auto',
        mx: 2
    },

    title: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '3.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        mb: 1
    },

    bankForm: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: 'fit-content',
        margin: 'auto',
        p: 2,
        mb: 3
    },

    form: {
        display: 'flex'
    },

    InputProps: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        borderRadius: 0
    },

    inputProps: {
        height: '15px'
    },
    
    button: {
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: 0,
        height: '32px',
        lineHeight: '1'
    },

    errors: {
        display: 'flex',
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        color: '#c62828',
        padding: 0
    },

    error: {
        width: 'fit-content',
        py: 0,
        pl: 0
    },

    tblContainer: {
        width: 'fit-content',
        margin: "auto",
        border: 'solid 1px #ccc',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        height: '81.5vh'
    },

    hdrCell: {
        borderRight: 'solid 1px #ccc',
        textAlign: 'center',
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        py: 1.5
    },

    cellStyle: {
        padding: 0, 
        borderBottom: 'none' 
    },

    lstCellStyle: {
        padding: 0, 
        border: 'solid 1px #ccc',
        display: 'flex',
        justifyContent: 'center',
        height: '32px',
        alignItems: 'center',
    },

    trashIcon: {
        '&:hover': {color: '#c62828', cursor: 'pointer'}
    },

    noRows: {
        border: 'none',
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1rem',
        textAlign: 'center',
        pt: 30
    }
}
export default styles