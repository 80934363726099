import React from "react";
import styles from "./styles";
import Button from '@mui/material/Button';

const courts = ["KINGS", "NASSAU"];

function SelectButton({ opt, optToSelect, handleClick }) {

    const capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

    return (
        <Button
            variant={optToSelect === opt ? "contained" : "outlined"}
            sx={optToSelect === opt ? styles.selected : styles.select}
            onClick={handleClick}
        >
            {courts.includes(opt)? capitalizeWord(opt) : opt}
        </Button>
    );
};
export default SelectButton;