import React, { useEffect, useContext, useState } from 'react';
import SettlementCalculationField from './SettlementCalculationField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider'
import styles from "./styles";
import HandshakeIcon from '@mui/icons-material/Handshake';
import GavelIcon from '@mui/icons-material/Gavel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import TextField from '@mui/material/TextField';
import { AppContext  } from "../AppContext";
import { formatCurrency, todaysDate } from '../utils'

const monetaryAmounts = ["Balance", "NSF", "Default Fee", "Stacking"];

function SettlementCalculator({
   merchant,
   turnoverTotal, 
   netSettlement,
   payoffPerWeek,
   remainingPayoffPerWeek,
   settlementBalance,
   addLawyerInfo, 
   setAddLawyerInfo,
   mutualRelease,
   setMutualRelease,
   prevailingParty,
   setPrevailingParty,
   rightToCure,
   setRightToCure,
   noticeToCure,
   setNoticeToCure,
   setDocPreventionError
}) {
   
   const { editSettlement } = useContext(AppContext);
    
   const [data, setData] = useState({});

   useEffect(() => {
      if(merchant.settlement) {
         const settDate = merchant.settlement.settlement_date
         const settPercent = merchant.settlement.percent
         setData({
            balance:             merchant.settlement.balance,
            nsf:                 merchant.settlement.nsf, 
            default_fee:         merchant.settlement.default_fee,
            stacking:            merchant.settlement.stacking,
            settlement_amount:   merchant.settlement.settlement_amount,
            damages:             merchant.settlement.damages,
            legal:               merchant.settlement.legal,
            full:                merchant.settlement.full,
            settlement_date:     settDate ? settDate : todaysDate,
            payoff_weeks:        merchant.settlement.payoff_weeks,
            payment_weeks:       merchant.settlement.payment_weeks,
            percent:             settPercent ? settPercent : '25',
            right_to_cure_weeks: merchant.settlement.right_to_cure_weeks
         })
      }
   },[merchant]);
   
   function parseNum(str) {
      const cleanedStr = str.replace(/[^\d.-]/g, '')
      const parsedNumb = parseFloat(cleanedStr)
      return parsedNumb
   }
   
   function calculateDamagesLegalAndFull(data) {
      const balanceNum = parseNum(data.balance)
      const nsfNum = parseNum(data.nsf)
      const defaultFeeNum = parseNum(data.default_fee)
      const stackingNum = parseNum(data.stacking)
      const newDamages = balanceNum + nsfNum + defaultFeeNum + stackingNum + 165
      const newLegal = (newDamages / 100) * parseNum(data.percent)
      const newFull = newDamages + newLegal
      const newDamagesFormatted = formatCurrency(newDamages)
      const newLegalFormatted = formatCurrency(newLegal)
      const newFullFormatted = formatCurrency(newFull)
      const formattedBalance = formatCurrency(balanceNum)
      const formattedNsf = formatCurrency(nsfNum)
      const formattedDefaultFee = formatCurrency(defaultFeeNum)
      const formattedStacking = formatCurrency(stackingNum)
      return{
         ...data,
         damages:     newDamagesFormatted === "NaN" ? data.damages     : "$" + newDamagesFormatted,
         legal:       newLegalFormatted   === "NaN" ? data.legal       : "$" + newLegalFormatted,
         full:        newFullFormatted    === "NaN" ? data.full        : "$" + newFullFormatted,
         balance:     formattedBalance    === "NaN" ? data.balance     : "$" + formattedBalance,
         nsf:         formattedNsf        === "NaN" ? data.nsf         : "$" + formattedNsf,
         default_fee: formattedDefaultFee === "NaN" ? data.default_fee : "$" + formattedDefaultFee,
         stacking:    formattedStacking   === "NaN" ? data.stacking    : "$" + formattedStacking
      }
   };

   function calculateLegalAndFull(data) {
      const damagesNum = parseNum(data.damages)
      const newLegal = (damagesNum / 100) * parseNum(data.percent)
      const newFull = damagesNum + newLegal
      const formattedDamages = formatCurrency(damagesNum)
      const newLegalFormatted = formatCurrency(newLegal)
      const newFullFormatted = formatCurrency(newFull)
      return{
         ...data,
         damages: formattedDamages === "NaN" ? data.damages : "$" + formattedDamages,
         legal: newLegalFormatted === "NaN" ? data.legal : "$" + newLegalFormatted,
         full:  newFullFormatted  === "NaN" ? data.full  : "$" + newFullFormatted
      }
   };

   function calculateFull(data) {
      const damagesNum = parseNum(data.damages)
      const damagesNumPercent = (damagesNum / 100) * parseNum(data.percent)
      const newFull = damagesNum + damagesNumPercent
      const newLegalFormatted = formatCurrency(damagesNumPercent)
      const newFullFormatted =  formatCurrency(newFull)
      return{
         ...data,
         legal: newLegalFormatted  === "NaN" ? data.legal : "$" + newLegalFormatted,
         full:  newFullFormatted   === "NaN" ? data.full  : "$" + newFullFormatted
      }
   };

   function updateSettlementData(rowName, data) { 
      if(monetaryAmounts.includes(rowName)) {
         return calculateDamagesLegalAndFull(data)
      }
      if(rowName === "Damages") {
         return calculateLegalAndFull(data)
      }
      if(rowName === "Legal") {
         return calculateFull(data)
      }
      if(rowName === "Settlement Amount") {
         const settAmountNum = parseNum(data.settlement_amount)
         const formattedSettAmount = formatCurrency(settAmountNum)
         return{...data, settlement_amount: formattedSettAmount === "NaN" ? data.settlement_amount : "$" + formattedSettAmount}
      }
      if(rowName === "Full") {
         const fullNum = parseNum(data.full)
         const formattedFull = formatCurrency(fullNum)
         return{...data, full: formattedFull === "NaN" ? data.full : "$" + formattedFull}
      }
      return data
   }

   function handleBlur(rowName) {
      setData(data => {
         const updatedData = updateSettlementData(rowName, data)
         fetch(`/settlements/${merchant.settlement.id}`, {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(updatedData)
         })
         .then(response => response.json())
         .then(data => editSettlement(data))
         return updatedData
      })
   };

   function handleResetClick(type) {
      let body = null;

      if (type === 'financial') {
         body = {
            balance:     merchant.balance,                     
            nsf:         merchant.nsf,                  
            default_fee: merchant.default_fee,              
            stacking:    merchant.stacking,      
            damages:     merchant.damages,             
            legal:       merchant.legal,              
            full:        merchant.total,
            percent:     ""
         }
      }
      else if (type === 'settlement') {
         body = {
            settlement_date:   '',
            settlement_amount: '',
            payoff_weeks:      '',
            payment_weeks:     ''
         }  
      }
      fetch(`/settlements/${merchant.settlement.id}`, {
         method: "PATCH",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => editSettlement(data))      
   };

   function resetCheckboxes() {
      setMutualRelease(false)
      setPrevailingParty(false)
      setRightToCure(false)
      setNoticeToCure(false)
      if (merchant && merchant.suit_status) {

         if ( merchant.suit_status.toLowerCase() === 'filed' ) {
            setAddLawyerInfo(true)
         } 
         else if ( merchant.lawyer ) {
            setAddLawyerInfo(true)
         } 
         else {
            setAddLawyerInfo(false)
         }
      }
    }

   function handleSettChange(event) {
      setDocPreventionError('')
      setData({...data, [event.target.name]: event.target.value})
   };

   const leftArray = [
      {
         key: 'Balance',
         name: 'balance', 
         value: data.balance
      },
      {
         key: 'NSF',
         name: 'nsf',
         value: data.nsf
      },
      {
         key: 'Default Fee',
         name: 'default_fee',
         value: data.default_fee
      },
      {
         key: 'Stacking',
         name: 'stacking',
         value: data.stacking
      }, 
      {
         key: 'Damages',
         name: 'damages',
         value: data.damages
      },
      {
         key: 'Legal',
         name: 'legal',
         percent: data.percent,
         value: data.legal
      },
      {
         key: 'Full', 
         name: 'full',
         value: data.full
      }
   ];

   const rightArray = [
      {
         key: "Settlement Date",
         name: "settlement_date",
         value: data.settlement_date
      },
      {
         key: "Settlement Amount",
         name: "settlement_amount",
         value: data.settlement_amount
      },
      {
         key: "Turnover Amount",
         value: turnoverTotal
      },
      {
         key: "Net Settlement",
         value: netSettlement
      },
      {
         key: "Payoff Weeks",
         name: "payoff_weeks",
         payoffPerWeek: payoffPerWeek,
         value: data.payoff_weeks
      },
      {
         key: "Settlement Balance",
         value: settlementBalance
      },
      {
         key: 'Payment Weeks',
         name: 'payment_weeks',
         payoffPerWeek: remainingPayoffPerWeek,
         value: data.payment_weeks
      }
   ];

   const leftCalculationBox = leftArray.map((row, i) => (
      <SettlementCalculationField 
         key={i} 
         row={row}
         handleSettChange={handleSettChange}
         handleBlur={handleBlur}
      />
   ));
    
   const rightCalculationBox = rightArray.map((row, i) => (
      <SettlementCalculationField 
         key={i} 
         row={row}
         handleSettChange={handleSettChange}
         handleBlur={handleBlur} 
      />
   ));
    
   return (
      <Box sx={styles.calculatorContainer}>
         <Box sx={styles.calculatorBox}>
            {leftCalculationBox}
            <Button sx={styles.reset} onClick={() => handleResetClick('financial')}>Reset</Button>
         </Box>

         <Box sx={styles.calculatorBox}>
            {rightCalculationBox}
            <Button sx={styles.reset} onClick={() => handleResetClick('settlement')}>Reset</Button>
         </Box>

         <Box sx={styles.actionPanel}> 
            <Box>
               <Box sx={styles.optionItem}>

                  <Box sx={styles.centeredFlex}>
                     <HandshakeIcon sx={styles.iconMargin}/>
                     <Typography sx={styles.checkboxLabel}>Mutual Release</Typography>
                  </Box>

                  <Checkbox onChange={(e) => setMutualRelease(e.target.checked)} checked={mutualRelease} sx={styles.checkboxMargin}/>
               </Box>
               <Divider/>

               <Box sx={styles.optionItem}>

                  <Box sx={styles.centeredFlex}>
                     <GavelIcon sx={styles.iconMargin}/>
                     <Typography sx={styles.checkboxLabel}>Lawyer</Typography>
                  </Box>
                  
                  <Checkbox onChange={(e) => setAddLawyerInfo(e.target.checked)} checked={addLawyerInfo} sx={styles.checkboxMargin}/>
               </Box>
               <Divider/>

               <Box sx={styles.optionItem}>

                  <Box sx={styles.centeredFlex}>
                     <VerifiedUserIcon sx={styles.iconMargin}/>
                     <Typography sx={styles.checkboxLabel}>Right To Cure</Typography>
                  </Box>     
                  <Box sx={styles.optionItem}>
                     <TextField 
                        size="small"
                        inputProps={{sx: styles.inputRightToCureWeeks }}
                        InputProps={{sx: styles.InputRightToCureWeeks }}
                        onChange={handleSettChange}
                        onBlur={() => handleBlur('Right To Cure Weeks')}
                        name="right_to_cure_weeks"
                        value={data.right_to_cure_weeks ? data.right_to_cure_weeks : ''}
                     />
                     <Checkbox onChange={(e) => setRightToCure(e.target.checked)} checked={rightToCure} sx={styles.checkboxMargin}/>
                  </Box>
               </Box>
               <Divider/>

               <Box sx={styles.optionItem}>

                  <Box sx={styles.centeredFlex}>
                     <NotificationImportantIcon sx={styles.iconMargin}/>
                     <Typography sx={styles.checkboxLabel}>Notice To Cure</Typography>
                  </Box>

                  <Checkbox onChange={(e) => setNoticeToCure(e.target.checked)} checked={noticeToCure} sx={styles.checkboxMargin}/>
               </Box>
               <Divider/>

               <Box sx={styles.optionItem}>

                  <Box sx={styles.centeredFlex}>
                     <EmojiEventsIcon sx={styles.iconMargin}/>
                     <Typography sx={styles.checkboxLabel}>Prevailing Party</Typography>
                  </Box>

                  <Checkbox onChange={(e) => setPrevailingParty(e.target.checked)} checked={prevailingParty} sx={styles.checkboxMargin}/>
               </Box>
               <Divider/>

            </Box>
            <Button sx={styles.reset} onClick={resetCheckboxes}>Reset</Button>
         </Box>
      </Box>
   );
};
export default SettlementCalculator;