import React, { useState } from 'react';
import EmailSuccessModal from './modals/EmailSuccessModal'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './styles';

function SettlementCommunications({ merchant, frequencies, wireRowsArray }) {

    const [open, setOpen] = useState(false)
    const [successMessage, setSuccessMessage] = useState('Please Wait...')

    function handleClick(emailType) { 
        setOpen(true);
        fetch('/send-settlement-email', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                id: merchant.id,
                email_type: emailType,
                ach_rows: frequencies,
                wire_rows: wireRowsArray
            })
        })
        .then(response => response.json())
        .then(data => {
            setSuccessMessage(data.message)
            setTimeout(() => setOpen(false), 1000);
        })
        setSuccessMessage("Please Wait...")
    };

    return (

        <Box sx={styles.containerBorder} p={1} ml={0.5} mr={0.5}>
            <EmailSuccessModal open={open} successMessage={successMessage}/>
            <Typography sx={styles.sectionTitle}>Communications</Typography>
            <Box>
                <Button sx={styles.commButton} onClick={() => handleClick("Send Offer")}>Send Offer</Button>
                <Button sx={styles.commButton} onClick={() => handleClick("Send Follow-Up")}>Send Follow-Up</Button>
                <Button sx={styles.commButton} onClick={() => handleClick("Send Default Notice")}>Send Default Notice</Button>
            </Box>
            <Box>
                <Button sx={styles.commButton} onClick={() => handleClick("Client Update")}>Client Update</Button>
                <Button sx={styles.commButton} onClick={() => handleClick("Client Completion")}>Client Completion</Button>
                <Button sx={styles.commButton}>Client Status</Button>
            </Box>
        </Box>
    );
};
export default SettlementCommunications;