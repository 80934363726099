import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './merchantStyles';

const readOnly = ["Settled Amount", "Outstanding"];

function MerchantSettlementField({ row, handleSettChange, handleSettBlur }) {

    return (

        <Grid item xs={12} sm={6} md={6} lg={3} sx={styles.dashboardItem}>
            <Stack direction="column">
                
                <Typography sx={styles.dashboardKey}>{row.key}</Typography>
                <TextField
                    size="small"
                    autoComplete='new-password'
                    InputProps={{
                        style: styles.dashboardValue, 
                        readOnly: readOnly.includes(row.key) ? true : false
                    }}
                    inputProps={{style: styles.dashboardFieldHeights}}
                    onChange={handleSettChange}
                    onBlur={handleSettBlur} 
                    value={row.value ? row.value : ''}
                    name={row.name}
                />

            </Stack>
        </Grid>
    );
};
export default MerchantSettlementField;