import React from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { useLocation } from 'react-router-dom';

function Login() {

    const { pathname } = useLocation();
    
    return (

        <>
            {pathname !== '/' ? ( 

                <SignUp pathname={pathname}/>

                ):( 
                    
                <SignIn/>
            )}
        </>
    );
};
export default Login;