import React from 'react'
import styles from './styles';
import BankForm from './BankForm';
import BankTable from './BankTable';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function BanksContainer() {
    return (
        <Box sx={styles.container}>
            <Typography sx={styles.title}>Banks</Typography>
            <BankForm />
            <BankTable />
        </Box>
    )
}
export default BanksContainer