import React, { useState } from "react";
import LawsuitGenerator from "../lawsuitModal/LawsuitGenerator";
import AffidavitStatusChecker from "../affidavits/AffidavitStatusChecker";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styles from './merchantStyles';
import ExcelDataSheet from "../excel/ExcelDataSheet";
import ServicesContainer from "../servicesModal/ServicesGenerator";
import NoticeOfEntryGenerator from "../noeModal/NoticeOfEntryGenerator";
import DefJudgmentGenerator from "../defJudgmentModal/DefJudgmentGenerator";
import BankRestraintGenerator from "../bankRestrsModal/BankRestraintGenerator";
import JudgmentDebtor from "../noticeOfJugment/JudgmentDebtor";

function MerchantOperations({ merchant, selectedDate }) {
    const [actionsFlag, setActionsFlag] = useState(false)
    const [openServices, setOpenServices] = useState(false)
    const [openNOE, setOpenNOE] = useState(false)
    const [openRestrs, setOpenRestrs] = useState(false)
    const [openDefJudg, setOpenDefJudg] = useState(false)

    const suitBtn  = {...styles.btn}
    const commsBtn = {...styles.btn, ...styles.comms}
    const repBtn   = {...styles.btn, ...styles.rep}
    const uccBtn   = {...styles.btn, ...styles.ucc}

    return (
        <Container disableGutters maxWidth={false} sx={styles.opContainer}>  
            <Box sx={styles.section}>
                <Typography sx={styles.title}>Lawsuit</Typography>
                <Box sx={styles.sectionFlex}>
                    <Box sx={styles.buttonGroup}>
                        <Button sx={suitBtn} onClick={() => setActionsFlag(true)}>Lawsuit</Button>
                        <Button sx={suitBtn} onClick={() => setOpenServices(true)}>Service</Button>
                        <AffidavitStatusChecker merchant={merchant} selectedDate={selectedDate}/>
                        <Button sx={suitBtn} onClick={() => setOpenDefJudg(true)}>Default Judgment</Button>
                        <Button sx={suitBtn} onClick={() => setOpenNOE(true)}>Notice Of Entry</Button>
                        <Button sx={suitBtn} onClick={() => setOpenRestrs(true)}>Bank Restraints</Button>
                    </Box>
                    <Box sx={styles.buttonGroup}>
                        <JudgmentDebtor merchant={merchant} selectedDate={selectedDate}/>
                        <Button sx={suitBtn}>Discontinuance</Button>
                        <Link style={styles.link} to={`/deal-list/${merchant.id}/settlement`}>
                            <Button sx={suitBtn}>Settlement</Button>
                        </Link>
                        <Button sx={suitBtn}>Payoff Letter</Button>
                        <ExcelDataSheet merchant={merchant}/>
                        <Button sx={suitBtn}>Zero Balance Letter</Button>
                    </Box>
                    <Box sx={styles.buttonGroup}>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                    </Box>
                    <Box sx={styles.buttonGroup}>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                        <Button sx={suitBtn}>Disabled</Button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{...styles.section, ...styles.sectionFlex}}>
                <Box sx={styles.buttonGroup}>
                    <Typography sx={styles.title}>Communication</Typography>
                    <Button sx={commsBtn}>Initial Merchant Email</Button>
                    <Button sx={commsBtn}>Follow Up Merchant Email</Button>
                    <Button sx={commsBtn}>Email To Lawyer</Button>
                    <Button sx={commsBtn}>Disabled</Button>
                    <Button sx={commsBtn}>Disabled</Button>
                    <Button sx={commsBtn}>Disabled</Button>
                </Box>
                <Box sx={styles.buttonGroup}>
                    <Typography sx={styles.title}>Reporting</Typography>
                    <Button sx={repBtn}>Manually Record Payment</Button>
                    <Button sx={repBtn}>Payrun</Button>
                    <Button sx={repBtn}>Remittance Report</Button>
                    <Link style={styles.link} to={`/deal-list/${merchant.id}/documents`}>
                        <Button sx={repBtn}>Document List</Button>
                    </Link>
                    <Button sx={repBtn}>Notes</Button>
                    <Button sx={repBtn}>Disabled</Button>
                </Box>
                <Box sx={styles.innerSection}>
                    <Typography sx={styles.title}>UCC Lien Notices</Typography>
                    <Box sx={styles.sectionFlex}>
                        <Box sx={styles.buttonGroup2}>
                            <Button sx={uccBtn}>UCC Notices</Button>
                            <Button sx={uccBtn}>Email UCC Notices</Button>
                            <Button sx={uccBtn}>Mail UCC Notices</Button>
                            <Button sx={uccBtn}>UCC Withdrawals</Button>
                            <Button sx={uccBtn}>Email UCC Withdrawals</Button>
                            <Button sx={uccBtn}>Mail UCC Withdrawals</Button>
                        </Box>
                        <Box sx={styles.buttonGroup2}>
                            <Button sx={uccBtn}>Disabled</Button>
                            <Button sx={uccBtn}>Disabled</Button>
                            <Button sx={uccBtn}>Disabled</Button>
                            <Button sx={uccBtn}>Disabled</Button>
                            <Button sx={uccBtn}>Disabled</Button>
                            <Button sx={uccBtn}>Disabled</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {actionsFlag ? 
                <LawsuitGenerator merchant={merchant} setActionsFlag={setActionsFlag} selectedDate={selectedDate}/> 
                : 
                null
            }
            <ServicesContainer open={openServices} setOpen={setOpenServices} merchant={merchant}/>
            <NoticeOfEntryGenerator open={openNOE} setOpen={setOpenNOE} merchant={merchant} selectedDate={selectedDate}/>
            <BankRestraintGenerator open={openRestrs} setOpen={setOpenRestrs} merchant={merchant} selectedDate={selectedDate}/>
            <DefJudgmentGenerator open={openDefJudg} setOpen={setOpenDefJudg} merchant={merchant} selectedDate={selectedDate}/>
        </Container>
    );
};
export default MerchantOperations;