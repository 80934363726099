const styles = {

    select: {
        fontFamily: 'AmazonEmberRegular',
        width: '170px',
        height: '40px',
        textTransform: 'none',
        overflow: 'hidden',
        boxShadow: 'none',
        m: 1,
        lineHeight: 1.3,
       '&:hover': { boxShadow: 'none' }
    },

    selected: {
        fontFamily: 'AmazonEmberRegular',
        width: '170px',
        height: '40px',
        textTransform: 'none',
        overflow: 'hidden',
        boxShadow: 'none',
        backgroundColor: '#FF9900',
        m: 1,
        lineHeight: 1.3,
        '&:hover': { boxShadow: 'none', backgroundColor: '#FF9900' }
    },

    InputProps : {
        fontFamily: 'AmazonEmberRegular'
    }, 

    textField: {
        marginLeft: 'auto'
    },
    
    reginputProps: {
        height: '18px'
    },
    
    regInputProps: {
        width: '460px',
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem'
    },

    autoDashboardValue: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        borderRadius: 0
    },
    
    autoDashboardHeight: {
        height: '8px'
    },

    // =============== DropZone.js =============== //

    dropZoneInact: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'dashed 2.5px #ccc', 
        width: '350px', 
        height: '200px',
        color: "grey"
    },

    dropZoneAct: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'dashed 2.5px #FF9900', 
        width: '350px', 
        height: '200px',
        color: "grey"
    },

    dropSuccess: {
        display: 'flex', 
        alignItems: 'center',
        color: '#155724',
        backgroundColor: '#d4edda', 
        py: '1px', 
        px: 3,
        mt:  1
    },

    checkIcon: {
        fontSize: 45,
        color: 'green'
    },

    dropMessage: {
        fontFamily: 'AmazonEmberRegular'
    },

    closeIcon: {
        fontSize: 20,
        mt: 0.3,
        ml: 3,
        cursor: 'pointer',
        '&:hover': { color: 'black', backgroundColor: "white", borderRadius: '10px'}
    },
    
    downloadIcon: {
        fontSize: 45
    },

    instructions:  {
        fontWeight: 'bold',
        fontFamily: "AmazonEmberRegular"
    },

    type: {
        fontSize: '0.8rem', 
        fontFamily: "AmazonEmberRegular"
    },
}
export default styles