import React from 'react';
import styles from '../merchants/merchantStyles';
import Button from '@mui/material/Button';
import { utils, writeFileXLSX } from 'xlsx';
import { todaysDate } from '../utils';

function ExcelDataSheet({merchant}) {
    
    let shortName = null
    let percent = null

    if ( merchant && merchant.lender_legal_name ) {
        shortName = merchant.lender_legal_name_title_case.split(" ")[0]
        percent = merchant.purchased_percentage.split(".")[0]
    }

    const dataSheet1 = [
        ["[Notary Name]"],
        ["[Notary Number]" ],
        ["[Notary County]"],
        ["[Notary Expiration]"],
        ["[PLAINTIFF]", merchant.lender_legal_name],
        ["[Plaintiff Not All Caps]", merchant.lender_legal_name_title_case],
        ["[Plaintiff Short Name]", shortName],
        ["[Plaintiff Street Address]", "333 Pearsall Ave"],
        ["[Plaintiff City, State, Zip]", "Cedarhurst, NY 11516"],
        ["[Plaintiff Entity Type]", merchant.type_of_entity],
        ["[Plaintiff State]", "New York"],
        ["[Plaintiff Citizenship]", "New York"],
        ["[Open File Date]", todaysDate],
        ["[UCC Date1]"],
        ["[UCC Letter Date]"],
        ["[UCC Number]"],
        ["[UCC Filed With]"],
        ["[Court]", "Supreme Court of the State of New York"],
        ["[County]", " ", "Kings", "Nassau"],
        ["[Court Street Address]", " ", "360 Adams Street", "100 Supreme Ct Dr"],
        ["[Court City State]", " ", "Brooklyn, NY", "Mineola, NY"],
        ["[Court Zip]", " ", "11201", "11501"],
        ["[Complaint Date]", todaysDate],
        ["[DEFENDANT1]", merchant.first_guarantor],
        ["[DEFENDANT2]", merchant.second_guarantor],
        ["[Defendant1 Not All Caps]", merchant.first_guarantor_title_case],
        ["[Defendant2 Not All Caps]", merchant.second_guarantor_title_case],
        ["[Street Address1]", merchant.mailing_address],
        ["[City, State, Zip1]", `${merchant.city}, ${merchant.state} ${merchant.zip}`],
        ["[Street Address2]", merchant.physical_address],
        ["[City, State, Zip2]", `${merchant.physical_city}, ${merchant.physical_state} ${merchant.physical_zip}`],
        ["[Defendant1 Phone]", merchant.mobile],
        ["[Defendant2 Phone]", merchant.business_phone],
        ["[Defendant1 E-mail]", merchant.email_address],
        ["[Defendant2 E-mail]", merchant.email_address],
        ["[Defendant1 Short Name]"],
        ["[Defendant1 State]", merchant.state],
        ["[Entity Type1]", merchant.type_of_entity],
        ["[TIN1]", merchant.federal_tax_id],
        ["[Defendant2 Short Name]"],
        ["[Defendant2 State]"],
        ["[SSN2]"],
        ["[Contract1 Date]", merchant.agreement_date],
        ["[Purchased Amount1]", merchant.purchased_amount],
        ["[Purchase Price1]", merchant.purchase_price],
        ["[Percent1]", percent],
        ["[Estimated Payment Amount1]", merchant.remittance],
        ["[Estimated Payment Frequency1]", merchant.payment_frequency],
        ["[Funding Date1]", merchant.image_date],
        ["[Default Date1]", merchant.default_date],
        ["[Balance1]", merchant.balance],
        ["[UCC1]", "$165.00"],
        ["[Fee Per NSF1]", "$100.00"],
        ["[NSF1]", merchant.nsf],
        ["[Default Fee1]", merchant.default_fee],
        ["[Default Interest Percentage1]", '9'],
        ["[Damages1]", merchant.damages],
        ["[Legal1]", merchant.legal],
        ["[Total]", merchant.total],
        ["[Forum Selection Section1]", "4.5"],
        ["[Guarantee Forum Selection Section1]", "page 6"],
        ["[Acceleration Provision Section1]", "3.3"],
        ["[NSF Fee Section1]", "Appendix A at ¶ D"],
        ["[UCC Fee Section1]", "Appendix A at ¶ E"],
        ["[Default Fee Section1]", "Appendix A at ¶ F"],
        ["[Interest Rate Section1]", "XXXX"],
        ["[Legal Fees Section1]", "3.4"],
        ["[Guarantee Page Range1]", "page 6"],
        ["[Guarantee Legal Fees Section1]", "XXXX"],
        ["[Alternative Service Provision Section1]", "4.5"],
        ["[Guarantee Service Section1]", "page 6"],
        ["[Choice of Law Section1]", "4.5"],
        ["[Guarantee Choice of Law Section1]", "page 6"],
        ["[Reconciliation Section1]", "1.4"],
        ["[Agreement Term Section1]", "1.2"],
        ["[Bankruptcy Not Default Section1]", "page 1"],
        ["[Injunction Consent Section1]", "XXXX"],
        ["[Guarantee Injunction Consent Section1]", "XXXX"],
        ["[PLAINTIFF CONTRACT SHORT NAME]", "VCG"],
        ["[Plaintiff's Representative]", "Jacob Avigdor"],
        ["[Plaintiff's Representative's Position]", "Authorized Representative"],
        ["[TRO Restraint Amount]"],
        ["[Entity to Restrain1]"],
        ["[Entity to Restrain1 Street Address]"],
        ["[Entity to Restrain1 City, State, Zip]"],
        ["[Last 4 Digits of Account to Restrain1]"],
        ["[Entity to Restrain2]"],
        ["[Entity to Restrain2 Street Address]"],
        ["[Entity to Restrain2 City, State, Zip]"],
        ["[Last 4 Digits of Account to Restrain2]"],
        ["[Entity to Restrain3]"],
        ["[Entity to Restrain3 Street Address]"],
        ["[Entity to Restrain3 City, State, Zip]"],
        ["[Last 4 Digits of Account to Restrain3]"],
        ["[Entity to Restrain4]"],
        ["[Entity to Restrain4 Street Address]"],
        ["[Entity to Restrain4 City, State, Zip]"],
        ["[Last 4 Digits of Account to Restrain4]"],
        ["[Entity to Restrain5]"],
        ["[Entity to Restrain5 Street Address]"],
        ["[Entity to Restrain5 City, State, Zip]"],
        ["[Last 4 Digits of Account to Restrain5]"],
        ["[Settlement Date]"],
        ["[Defendant2 Position With Merchant]"],
        ["[Settlement Amount]"],
        ["[Lump Sum Settlement Payment Due Date]"],
        ["[Settlement Monthly Payment Amount]"],
        ["[Settlement Monthly Payment Day]"],
        ["[Settlement Monthly Payment Start Date]"],
        ["[INDEX]"],
        ["[Filing Date]"],
        ["[Judge]"],
        ["[Judge Last Name]"],
        ["[OSC NYSCEF Document Number]"],
        ["[Emergency OSC Return Date]"],
        ["[Send to Process Server Date]"],
        ["[S&C Service Date1]"],
        ["[S&C Service Method1]"],
        ["[S&C Service Date2]"],
        ["[S&C Service Method2]"],
        ["[S&C Tracking1]"],
        ["[S&C Tracking2]"],
        ["[S&C Tracking3]"],
        ["[S&C Tracking4]"],
        ["[S&C Service Filing Date1]"],
        ["[S&C Service Filing Date2]"],
        ["[Service Completed Date1]"],
        ["[Service Completed Date2]"],
        ["[Additional Mailing Date1]"],
        ["[Additional Mailing Date2]"],
        ["[Entity to Restrain1 Service Date]"],
        ["[Entity to Restrain1 Tracking]"],
        ["[Entity to Restrain2 Service Date]"],
        ["[Entity to Restrain2 Tracking]"],
        ["[Entity to Restrain3 Service Date]"],
        ["[Entity to Restrain3 Tracking]"],
        ["[Entity to Restrain4 Service Date]"],
        ["[Entity to Restrain4 Tracking]"],
        ["[Entity to Restrain5 Service Date]"],
        ["[Entity to Restrain5 Tracking]"],
        ["[Answer Rejection Date]"],
        ["[Proper Venue Date]"],
        ["[Change Venue Opposition Date]"],
        ["[Change Venue Return Date]"],
        ["[Change Venue Denial Entry Date]"],
        ["[Change Venue Denial NOE Date]"],
        ["[MDJ Date]"],
        ["[MDJ Return Date]"],
        ["[MDJ Service Date]"],
        ["[MDJ Non-Mil Search Date2]"],
        ["[Defendant's Attorney Name]"],
        ["[Defendant's Attorney Firm]"],
        ["[Defendant's Attorney Street Address]"],
        ["[Defendant's Attorney City, State, Zip]"],
        ["[Defendant's Attorney Phone]"],
        ["[Defendant's Attorney Fax]"],
        ["[Defendant's Attorney E-mail]"],
        ["[MSJ Date]"],
        ["[MSJ Return Date]"],
        ["[Answer Date]"],
        ["[Order Date]"],
        ["[Order NOE Date]"],
        ["[Order NOE Service Tracking1]"],
        ["[Order NOE Service Tracking2]"],
        ["[Order NOE Service Tracking3]"],
        ["[Order NOE Service Tracking4]"],
        ["[NOI Date]"],
        ["[Date Issue Joined]"],
        ["[NOI Service Tracking1]"],
        ["[NOI Service Tracking2]"],
        ["[NOI Service Tracking3]"],
        ["[NOI Service Tracking4]"],
        ["[Judgment Application Date]", "October 29, 2023"],
        ["[Notice of Settlement Return Date]"],
        ["[Costs]", "$200.00"],
        ["[Process Server Fees]", "$50.00"],
        ["[Motion Fees]", "$45.00"],
        ["[Cross-Motion Fees]"],
        ["[Postage Expenses]"],
        ["[Disbursements]", "$355.00"],
        ["[Costs & Disbursements]", "$555.00"],
        ["[Damages Awarded1]", "$58,203.54"],
        ["[Interest Rate Awarded1]"],
        ["[Judgement Total]", "$100,000.00"],
        ["[Interest Date1]", "April 18, 2024"],
        ["[Interest Awarded]", "$10,000.00"],
        ["[Legal Awarded1]", "$0.00"],
        ["[Inquest Date]"],
        ["[Judgment Judge]"],
        ["[Judgment Non-Mil Search Date2]", "October 15, 2023"],
        ["[Judgment Date]"],
        ["[Judgment Amount]"],
        ["[Judgment NOE Date]"],
        ["[Notice to Judgment Debtor Date]"],
        ["[Bank Name]"],
        ["[Bank Attention]"],
        ["[Bank Street Address]"],
        ["[Bank City, State, Zip]"],
        ["[Bank Short Name]"],
        ["[Bank Restraint Date]"],
        ["[Bank Restraint Tracking]"],
        ["[Major Banks Restraint Tracking1]"],
        ["[Major Banks Restraint Tracking2]"],
        ["[Major Banks Restraint Tracking3]"],
        ["[Major Banks Restraint Tracking4]"],
        ["[Major Banks Restraint Tracking5]"],
        ["[Major Banks Restraint Tracking6]"],
        ["[Major Banks Restraint Tracking7]"],
        ["[Major Banks Restraint Tracking8]"],
        ["[Major Banks Restraint Tracking9]"],
        ["[Major Banks Restraint Tracking10]"],
        ["[Turnover Petition Date]"],
        ["[Turnover Petition Return Date]"],
        ["[Garnishee Bank]"],
        ["[Garnishee Bank Not All Caps]"],
        ["[Garnishee Bank Entity Type]"],
        ["[Garnishee Bank Street Address]"],
        ["[Garnishee Bank City, State, Zip]"],
        ["[JOINT ACCOUNT HOLDER1]"],
        ["[Joint Account Holder1 Not All Caps]"],
        ["[Joint Account Holder1 Street Address]"],
        ["[Joint Account Holder1 City, State, Zip]"],
        ["[Joint Account Holder1 Short Name]"],
        ["[Joint Account Holder1 State]"],
        ["[JOINT ACCOUNT HOLDER2]"],
        ["[Joint Account Holder2 Not All Caps]"],
        ["[Joint Account Holder2 Street Address]"],
        ["[Joint Account Holder2 City, State, Zip]"],
        ["[Joint Account Holder2 Short Name]"],
        ["[Joint Account Holder2 State]"],
        ["[Turnover Index]"],
        ["[Turnover Judge]"],
        ["[Turnover Defendant1 Service Date]"],
        ["[Turnover Defendant2 Service Date]"],
        ["[Turnover Tracking1]"],
        ["[Turnover Tracking2]"],
        ["[Turnover Tracking3]"],
        ["[Turnover Tracking4]"],
        ["[Turnover Garnishee Bank Service Date]"],
        ["[Turnover Joint Account Holder1 Service Date]"],
        ["[Turnover Joint Account Holder2 Service Date]"],
        ["[Turnover Order Date]"],
        ["[Turnover Order NOE Date]"],
        ["[Turnover Order NOE Service1 Tracking]"],
        ["[Turnover Order NOE Service2 Tracking]"],
        ["[Turnover Order NOE Service3 Tracking]"],
        ["[Turnover Order NOE Service4 Tracking]"],
        ["[Turnover Order NOE Joint Account Holder1 Tracking]"],
        ["[Turnover Order NOE Joint Account Holder2 Tracking]"],
        ["[Turnover Order NOE Garnishee Bank Tracking]"],
        ["[Defendant1 Bankruptcy Date]"],
        ["[Defendant1 Bankruptcy Court]"],
        ["[Defendant1 Bankruptcy Case Number]"],
        ["[Defendant1 Bankruptcy Suggestion Date]"],
        ["[Defendant2 Bankruptcy Date]"],
        ["[Defendant2 Bankruptcy Court]"],
        ["[Defendant2 Bankruptcy Case Number]"],
        ["[Defendant2 Bankruptcy Suggestion Date]"],
        ["[Discontinuance]"],
        ["[PIF Letter Date]"]
    ];

    const dataSheet2 = [
        {
            "[UCC Letter Recipient]": "",
            "[UCC Letter Street Address]": "",
            "[Plaintiff Phone]": "",
            "[Plaintiff E-mail]": "",
            "[UCC Letter Withdrawal Date]": ""
        }
    ];

    function exportFile() {
        const wb = utils.book_new();
        const ws1 = utils.aoa_to_sheet(dataSheet1);
        ws1['!cols'] = [
            { wch: 50 },
            { wch: 50 },
            { wch: 30 },
            { wch: 50 } 
        ];
        utils.book_append_sheet(wb, ws1, "Sheet1")

        const ws2 = utils.json_to_sheet(dataSheet2);
        ws2['!cols'] = [
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 }
        ];
        utils.book_append_sheet(wb, ws2, "Sheet2")

        writeFileXLSX(wb, `${merchant.merchants_legal_name_title_case} excel spreadsheet.xlsx`)
    };

    return (
        <Button sx={styles.btn} onClick={exportFile}>Datasheet</Button>
    );
};
export default ExcelDataSheet;