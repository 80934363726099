import React, { useState, useContext } from "react";
import styles from "./styles";
import LawyerField from "./LawyerField";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppContext } from "../AppContext";

function LawyerRow({ lawyer }) {
    const { deleteLawyer, editLawyer } = useContext(AppContext);
    const [data, setData] = useState({
        law_firm:       lawyer.law_firm,
        lawyer:         lawyer.lawyer,
        lawyer_address: lawyer.lawyer_address,
        city_state_zip: lawyer.city_state_zip,
        lawyer_email:   lawyer.lawyer_email,    
        lawyer_phone:   lawyer.lawyer_phone,
        lawyer_fax:     lawyer.lawyer_fax,
    });

    function handleBlur() {
        fetch(`/lawyers/${lawyer.id}`, {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                ...data,
                law_firm_upcase: data.law_firm.toUpperCase()
            })
        })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => editLawyer(data))
            }
        })
    }
    
    function handleDelete() {
        fetch(`/lawyers/${lawyer.id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(data => deleteLawyer(data))
    }

    function handleChange(event) {
        setData({...data, [event.target.name]: event.target.value})
    }

    const fields = [
        {name: "law_firm",       value: data.law_firm,       width: "350px"},
        {name: "lawyer",         value: data.lawyer,         width: "225px"},
        {name: "lawyer_address", value: data.lawyer_address, width: "275px"},  
        {name: "city_state_zip", value: data.city_state_zip, width: "250px"},
        {name: "lawyer_email",   value: data.lawyer_email,   width: "220px"},
        {name: "lawyer_phone",   value: data.lawyer_phone,   width: "130px"},
        {name: "lawyer_fax",     value: data.lawyer_fax,     width: "130px"},
    ]
    const renderedFields = fields.map(field => (
        <TableCell key={field.name} sx={styles.cellStyle}>
            <LawyerField 
                name={field.name} 
                value={field.value} 
                width={field.width}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
        </TableCell>
    ))
    
    return (
        <TableRow>
            {renderedFields}
            <TableCell sx={styles.lstCellStyle}>
                <DeleteIcon sx={styles.trashIcon} onClick={handleDelete}/>
            </TableCell>
        </TableRow>
    ); 
};
export default LawyerRow;