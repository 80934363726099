import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import styles from "./merchantDocumentStyles";

function MerchantsDocumentsItem({ docObj, merchantsName }) {

    const filenameWithoutExt = docObj.filename.split(".")[0].replace("(redacted)", "");
    const redactedDocs = ["Contract (redacted).pdf", "Funding Confirmation (redacted).png"];

    const extensionIndex = docObj.filename.lastIndexOf('.');
    let docObjExtension = docObj.filename.slice(extensionIndex);
    let docObjName = docObj.filename.slice(0, extensionIndex);

    let fileName = null;

    if (redactedDocs.includes(docObj.filename)) {
        fileName = filenameWithoutExt.trim() +  " - " + merchantsName + " (redacted)" + docObjExtension
    } else {
        fileName = docObjName + " - " + merchantsName + docObjExtension
    };

    function handleDownloadClick() {
        fetch(docObj.url)
        .then(response => response.blob())
        .then(blob => saveAs(blob, fileName))
    };

    return (

        <Box sx={styles.box}>
            <Box sx={styles.innerBox}>
                <Typography sx={styles.fileName}>{filenameWithoutExt}</Typography>
                &nbsp;
                <Typography sx={styles.redacted}>{docObj.filename.includes("redacted") ? "(redacted)" : ""}</Typography>
            </Box>
            <DownloadIcon sx={styles.fileIcon} onClick={handleDownloadClick}/>
        </Box>
    );
};
export default MerchantsDocumentsItem;