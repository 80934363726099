import React from "react";
import styles from "./styles";
import LawyerForm from "./LawyerForm";
import LawyerTable from "./LawyerTable";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function LawyersContainer() {
   return (
      <Box sx={styles.container}>
         <Typography sx={styles.title}>Lawyers</Typography>
         <LawyerForm/>
         <LawyerTable/>
      </Box>
   )
}
export default LawyersContainer