import React, { useState, useEffect, useContext } from "react";
import MerchantDashboardField from "./MerchantDashboardField";
import MerchantSettlementField from "./MerchantSettlementField";
import LawyerField from "./LawyerField";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styles from './merchantStyles';
import { AppContext } from "../AppContext";
import { formatCurrency } from '../utils'

function MerchantsDashboard({settlementData, handleSettChange, handleSettBlur, data, setData, handleChange, handleBlur, merchant})  {

    const { handleSubmitLawyer } = useContext(AppContext);
    
    const {settlement, lawyer} = merchant

    const [lawyerData, setLawyerData] = useState({})
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        if (lawyer) {
            setLawyerData({  
                law_firm:       lawyer.law_firm,
                lawyer_address: lawyer.lawyer_address,
                city_state_zip: lawyer.city_state_zip,
                lawyer_email:   lawyer.lawyer_email,    
                lawyer_phone:   lawyer.lawyer_phone,
                lawyer_fax:     lawyer.lawyer_fax
            })
            setInputValue(lawyer.lawyer)
        } 
    },[lawyer])

    function handleSubmit() { 
        handleSubmitLawyer({...lawyerData, lawyer: inputValue, merchant_id: merchant.id}, true) 
    }

    function handleRemoveLawyer() {
        setData({...data, lawyer_id: null})
        handleBlur()
        setLawyerData({
            law_firm: '',
            lawyer_address: '',
            city_state_zip: '',
            lawyer_email: '',
            lawyer_phone: '',
            lawyer_fax: ''
        }) 
        setInputValue('')   
    }
    
    function handleLawyerChange(event) {
        setLawyerData({...lawyerData, [event.target.name]: event.target.value})
    };
    const handleInputChange = (_, newValue) => setInputValue(newValue)

    let settlementAmountNum = 0
    let paidToDateNum = 0
    let rawOutstanding = 0
    let outstanding = 0

    if (settlement) {
        settlementAmountNum = parseFloat(settlement.settlement_amount.replace(/[^\d.-]/g, ''))
        paidToDateNum = parseFloat(settlement.paid_to_date.replace(/[^\d.-]/g, ''))
        rawOutstanding = formatCurrency(settlementAmountNum - paidToDateNum)
        outstanding = !isNaN(rawOutstanding) ? "$" + rawOutstanding : settlement.settlement_amount
    };

    const merchantArray = [
        {
            key: 'Suit Status',
            name: 'suit_status',          
            value: data.suit_status
        },
        {
            key: 'Date Filed', 
            name: 'service',
            value: data.service
        },
        {
            key: 'Index #',
            name: 'index_number',
            value: data.index_number
        },
        {
            key: 'Date Served',
            name: 'date_served',
            value: data.date_served
        },
        {
            key: 'Affidavit Of Service', 
            name: 'aos',
            value: data.aos
        },
        {
            key: 'Default Judgment Date',
            name: 'default_judgment',
            value: data.default_judgment
        },
        {
            key: 'Answer Date', 
            name: 'litigation_date',         
            value: data.litigation_date
        },
        {
            key: 'Response Due Date',
            name: 'response_date',           
            value: data.response_date
        },
        {
            key: 'Response Status',
            name: 'response_status',
            value: data.response_status
        },
        {
            key: 'Notes',
            name: 'notes',
            value: data.notes
        },
    ];

    const lawyerArray = [
        {
            key: 'Law Firm',
            name: 'law_firm',
            value: lawyerData.law_firm
        },
        { 
            key: 'Lawyer'
        },
        {
            key: 'Lawyer Address',
            name: 'lawyer_address',
            value: lawyerData.lawyer_address
        },
        {
            key: 'Lawyer Email', 
            name: 'lawyer_email',           
            value: lawyerData.lawyer_email
        },
        {
            key: 'Lawyers Tel',
            name: 'lawyer_phone',
            value: lawyerData.lawyer_phone
        },
        {
            key: 'Lawyer Fax',
            name: 'lawyer_fax',
            value: lawyerData.lawyer_fax
        },
        {
            key: 'City State Zip',
            name: 'city_state_zip',
            value: lawyerData.city_state_zip
        },
    ];
    
    const settlementArray = settlement ? [
        {
            key: 'Settled Amount',
            value: settlement.settlement_amount
        },
        {
            key: 'Settled Status',
            name: 'settlement_status',
            value: settlementData.settlement_status
        },
        {
            key: 'Paid To Date',
            name: 'paid_to_date',
            value: settlementData.paid_to_date
        },
        {
            key: 'Outstanding',
            value: outstanding
        }
    ] : [];

    const tempArray = [
        {
            key: 'Lawsuit Cost',
            value: ''
        },
        {
            key: 'Default Judgment Cost',
            value: ''
        },
        {
            key: 'Contested Litigation Cost',
            value: ''
        },
        {
            key: 'Total',
            value: ''
        }
    ];

    const merchantDashboard = merchantArray.map(row => (
        <MerchantDashboardField
            key={row.key} 
            row={row} 
            handleChange={handleChange} 
            handleBlur={handleBlur}  
        />
    ));
    const settlementDashboard = settlementArray.map(row => (
        <MerchantSettlementField
            key={row.key} 
            row={row} 
            handleSettChange={handleSettChange}
            handleSettBlur={handleSettBlur}
        />
    ));
    const emptyDashboard = tempArray.map(row => (
        <MerchantSettlementField 
            key={row.key} 
            row={row} 
            // handleSettChange={handleSettChange}
            // handleSettBlur={handleSettBlur}
        />
    ));
    const lawyerDashboard = lawyerArray.map(row => (
        <LawyerField
            key={row.key} 
            row={row}
            handleLawyerChange={handleLawyerChange} 
            merchant={merchant}
            handleInputChange={handleInputChange}
            inputValue={inputValue}
        />
    ));

    return (
        <Box sx={styles.dashboardArea}>
            <Box sx={styles.dashboardBox} p={1.5}>
                <Grid container spacing={1}>{merchantDashboard}</Grid>
            </Box>

            <Box sx={styles.dashboardBox} p={1.5}>
                <Grid container spacing={1}>{settlementDashboard}</Grid>
            </Box>
       
            <Box sx={styles.dashboardBox} p={1.5}>
                <Grid container spacing={1}>{emptyDashboard}</Grid>
            </Box>

            <Box sx={styles.dashboardBox} p={1.5}>
                <Grid container spacing={1}>
                    {lawyerDashboard}
                    <Box sx={styles.lawyerAddRemoveBox}>
                        <Button sx={styles.lawRmvButton} onClick={handleRemoveLawyer}>
                            Remove
                        </Button>
                        <Button sx={styles.lawAddButton} onClick={handleSubmit}>
                            Add
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </Box>
    );
};
export default MerchantsDashboard;