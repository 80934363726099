import React, { useState, useContext } from "react";
import styles from "./styles";
import BankField from "./BankField";
import Box from '@mui/material/Box';
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Button from '@mui/material/Button';
import { AppContext } from "../AppContext";

function BankForm() {
    const { banks, setBanks } = useContext(AppContext);
    const [formData, setFormData] = useState({
        name:      '',
        attention: '',
        address1:  '',
        address2:  '',
        city:      '',
        state:     '',
        zip:       '',
        track_no:  ''
    })
    const [errors, setErrors] = useState([])

    function handleSubmit(e) {
        e.preventDefault()
        fetch('/banks', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(formData)
        })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setBanks([...banks, data])
                    setFormData({name:'',attention:'',address1:'',address2:'',city:'',state:'',zip:'',track_no:''})
                })
            } else {
                response.json().then(data => setErrors(data.errors))
            }
        })
    }

    function handleChange(event) {
        setErrors([])
        setFormData({...formData, [event.target.name]: event.target.value})
    }
    
    const fields = [
        {name: "name",      value: formData.name,      placeholder: "Name",       width: "250px"},
        {name: "attention", value: formData.attention, placeholder: "Attention",  width: "325px"},
        {name: "address1",  value: formData.address1,  placeholder: "Address1",   width: "220px"},  
        {name: "address2",  value: formData.address2,  placeholder: "Address2",   width: "375px"},
        {name: "city",      value: formData.city,      placeholder: "City"                      },
        {name: "state",     value: formData.state,     placeholder: "State",      width: "75px" },
        {name: "zip",       value: formData.zip,       placeholder: "Zip"                       },
        {name: "track_no",  value: formData.track_no,  placeholder: "Tracking #", width: "220px"},
    ]

    const renderedFields = fields.map(field => (
        <BankField 
            key={field.name}
            name={field.name} 
            value={field.value} 
            placeholder={field.placeholder}
            width={field.width}
            handleChange={handleChange}
        />
    ))
    const errorsList = errors.map(error => <ListItem key={error} sx={styles.error}>{error}</ListItem>)

    return (
        <Box sx={styles.bankForm}>
            <Box sx={styles.form} component="form" onSubmit={handleSubmit}>
                {renderedFields}
                <Button variant="contained" type="submit" disableRipple sx={styles.button}>Add Bank</Button>
            </Box>
            <List sx={styles.errors}>{errorsList}</List>
        </Box>
    )
}
export default BankForm