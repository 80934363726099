import React, { useMemo, useEffect, useContext, useState } from "react";
import styles from "./styles";
import SettlementDashboard from "./SettlementDashboard";
import SettlementCalculator from "./SettlementCalculator";
import SettlementTurnover from "./SettlementTurnover";
import SettlementPaymentPlan from "./SettlementPaymentPlan";
import SettlementCommunications from "./SettlementCommunications";
import SettlementGenerations from "./SettlementGenerations";
import SettlementDocuSign from "./SettlementDocuSign";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useHistory } from 'react-router-dom';
import Big from 'big.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppContext  } from "../AppContext";
import { formatCurrency, options } from '../utils'

const theme = createTheme({
    components: {MuiButton: { defaultProps: { disableRipple: true, variant: 'contained' } }}
})

function SettlementContainer() {

    const { merchants, court } = useContext(AppContext);

    const params = useParams();
    const history = useHistory();

    const [addLawyerInfo, setAddLawyerInfo] = useState(false);
    const [mutualRelease, setMutualRelease] = useState(false);

    const [prevailingParty, setPrevailingParty] = useState(false);
    const [rightToCure, setRightToCure] = useState(false);
    const [noticeToCure, setNoticeToCure] = useState(false);

    const [docPreventionError, setDocPreventionError] = useState('');

    const merchant = useMemo(
        () => merchants.find(merchant => merchant.id === parseInt(params.id)) || {},
        [merchants, params.id]
    );

    let payments = null;
    let turnoverTotal = null;
    let netSettlement = null;
    let paymentPlanTotal = '';
    let nextStartDate = '';
    let nextStartDateFormatted = null;
    let year  = null;
    let month = null;
    let day = null;
    let payoffPerWeek = null;
    let payoffPerWeekFormatted = '';
    let remainingPayoffPerWeek = '';
    let remainingPayoffPerWeekFormatted = '';
    let settlementBalance = null
    let formattedDocKeys = {}
    let frequencies = ''
    let wireParagraph = ''
    let wireRowsArray = []

    useEffect(() => {
        if ( merchant && merchant.suit_status ) {

            if ( merchant.suit_status.toLowerCase() === 'filed' ) {
                setAddLawyerInfo(true)
            } 
            else if ( merchant.lawyer ) {
                setAddLawyerInfo(true)
            } 
            else {
                setAddLawyerInfo(false)
            }
        }
    }, [merchant]);
   
    if ( merchant.settlement ) {
        // There is a lot that must be done here and sent down to various children
        // Calculation Details:
        const { lawyer, settlement, settlement: { turnovers } } = merchant

        payments = merchant.settlement.payments
        const payoffWeeks = merchant.settlement.payoff_weeks
        const paymentWeeks = merchant.settlement.payment_weeks

        const formattedSettlementAmt = merchant.settlement.settlement_amount.replace(/[^\d.-]/g, '')

        const rawPaymentPlanTotal = payments.reduce(
            (accum, payment) => {
                return accum + parseFloat(payment.total.replace(/[^\d.-]/g, ''))
            }, 
            0
        )

        const rawTurnoverTotal = turnovers.reduce(
            (acc, turnover) => {
                const parsedAmount = parseFloat(turnover.amount.replace(/[^\d.-]/g, ''))
                if (!isNaN(parsedAmount)) {
                    return acc + parsedAmount;
                } else { return acc }  
            }, 
            0
        )

        const rawNetSettlement = (
            !isNaN(parseFloat(formattedSettlementAmt)) ? parseFloat(formattedSettlementAmt) - rawTurnoverTotal : 0
        )

        if (rawNetSettlement) {
            const dividend = new Big(rawNetSettlement - rawPaymentPlanTotal)
            const rawSettBalance = dividend.toNumber()
            settlementBalance = "$" + formatCurrency(rawSettBalance)
        };
    
        if ( rawNetSettlement && payoffWeeks ) {
            try {
                const divisor = new Big(payoffWeeks) 
                const dividend = new Big(rawNetSettlement)
                const result = dividend.div(divisor) 
                const rawPayoffPerWeek = result.toNumber() 
                payoffPerWeek = "$" + formatCurrency(rawPayoffPerWeek)
                payoffPerWeekFormatted = formatCurrency(rawPayoffPerWeek)
            } catch {
                payoffPerWeek = ""
                payoffPerWeekFormatted = ""
            }
        };

        if ( rawNetSettlement && paymentWeeks ) {
            try {
                const divisor = new Big(paymentWeeks)
                const dividend = new Big(rawNetSettlement - rawPaymentPlanTotal)
                const result = dividend.div(divisor) 
                const rawPaymentPerWeek = result.toNumber()
                remainingPayoffPerWeek = "$" + formatCurrency(rawPaymentPerWeek)
                remainingPayoffPerWeekFormatted = formatCurrency(rawPaymentPerWeek)
            } catch {
                remainingPayoffPerWeek = ""
                remainingPayoffPerWeekFormatted = ""
            }
        };
    
        if (payments.length > 0) {
            const lastDateStr = payments[payments.length -1].start_date
            const lastFrequency = payments[payments.length -1].frequency
            const lastDate = new Date(lastDateStr)
            lastDate.setDate(lastDate.getDate() + 1 + lastFrequency * 7)

            year  = lastDate.toLocaleString('en-US', { year: "numeric"  })
            month = lastDate.toLocaleString('en-US', { month: "2-digit" })
            day   = lastDate.toLocaleString('en-US', { day: "2-digit"   })
        } else {
            const today = new Date();
            const currentDay = today.getDay();
            const daysUntilFriday = currentDay === 5 ? 0 : (5 - currentDay + 7) % 7;
            const nextFridayDate = new Date(today);
            nextFridayDate.setDate(today.getDate() + daysUntilFriday);

            year  = nextFridayDate.toLocaleString('en-US', { year: "numeric"  })
            month = nextFridayDate.toLocaleString('en-US', { month: "2-digit" })
            day   = nextFridayDate.toLocaleString('en-US', { day: "2-digit"   })
        };

        nextStartDate = year + "-" + month + "-" + day;

        const startDate = new Date(nextStartDate);
        startDate.setDate(startDate.getDate() + 1);
        nextStartDateFormatted = payments.length > 0 ? startDate.toLocaleString('en-US', options) : null

        turnoverTotal = "$" + formatCurrency(rawTurnoverTotal);
        netSettlement = "$" + formatCurrency(rawNetSettlement);
        paymentPlanTotal = formatCurrency(rawPaymentPlanTotal);

        //Template Format Details:
        const merchantName  = merchant.merchants_legal_name_title_case
        const firstGuarName = merchant.first_guarantor_title_case
        const entityType = merchant.type_of_entity.toLowerCase()
        const solProp = merchantName === firstGuarName || entityType === "sole proprietorship";
        const authMsg = solProp ? "Defendant authorizes and directs " : "Defendants authorize and direct " 

        const paragraphs = turnovers.map((turnover) => {
            const date = new Date(turnover.ucc_letter_date).toLocaleString('en-US', options)

            return authMsg + turnover.ucc_recipient + " to turn over $" + turnover.amount + 
            " to Plaintiff’s attorneys from funds that " + turnover.ucc_recipient + " is holding pursuant to a notice pursuant to " +
            "UCC § 9-406 issued by Plaintiff dated " + date + ". Payment must be made pursuant to written " +
            "wire instructions provided by Plaintiff’s attorneys. All amounts Plaintiff receives from " + turnover.ucc_recipient + 
            " pursuant to this agreement will be credited towards payment of the Settlement Amount.\n" +
            "Upon turning over all required funds to Plaintiff, the UCC lien notice will be deemed withdrawn and " +
            turnover.ucc_recipient + " may release all remaining held funds to " + merchant.merchants_legal_name_title_case + "."
        }); 

        const achRows = payments.filter(payment => payment.pay === "ACH Pull")
        const wireRows = payments.filter(payment => payment.pay === "Wire")

        const paymentAmounts = achRows.map(payment => "$" + payment.amount).join("/")

        frequencies = achRows.map((payment, index) => {
            let date = new Date(payment.start_date);
            date.setDate(date.getDate() + 1);
            date = date.toLocaleString('en-US', options);

            let frequencyString;
            if (payment.frequency > 1) {
                frequencyString = `$${payment.amount} every ${payment.day_of_week} for ${payment.frequency} weeks starting ${date}`
            } else {
                frequencyString = `$${payment.amount}, ${payment.day_of_week}, ${date}`
            }
            
            if (index < achRows.length - 1) {
                frequencyString += ","
            } else {
                frequencyString += "."
            }

            return frequencyString
        })

        wireRowsArray = wireRows.map((payment, index) => {
            let date = new Date(payment.start_date);
            date.setDate(date.getDate() + 1);
            date = date.toLocaleString('en-US', options);
            
            const isLastPayment = index === wireRows.length - 1; 
        
            if (isLastPayment && payment.frequency > 1) {
                return '$' + payment.amount + " (or the remaining balance if less) must be received on or before every " + 
                payment.day_of_week + " for " + payment.frequency + " consecutive weeks starting on " + date + "."
            }
            else if (payment.frequency > 1) {
                return '$' + payment.amount + " must be received on or before every " + payment.day_of_week + " for " + 
                payment.frequency + " consecutive weeks starting on " + date + "."
            } else {
                return '$' + payment.amount + " must be received on or before " + payment.day_of_week + ", " + date + "."
            }
        })

        wireParagraph = wireRowsArray.join(" ")
        
        let noticeAndOrRightToCure = ''
        const defText = solProp ? " the Defendant " : " Defendants "

        if (lawyer && lawyer.lawyer && lawyer.lawyer_email) {
            if (noticeToCure && rightToCure) {
                noticeAndOrRightToCure = "then" + defText + "will be sent a written notice of the default by email to " + 
                lawyer.lawyer + " at " + lawyer.lawyer_email + ", and if the default is not cured within " + 
                settlement.right_to_cure_weeks + " days after the notice is sent, then "
            } else if (noticeToCure) {
                noticeAndOrRightToCure = "then" + defText + "will be sent a written notice of the default by email to " +
                lawyer.lawyer + " at " + lawyer.lawyer_email + ", and "
            } else if (rightToCure) {
                noticeAndOrRightToCure = "and if the default is not cured within " + settlement.right_to_cure_weeks +
                " days after the notice is sent, then "
            } else {
                noticeAndOrRightToCure = "then "
            }
        }

        formattedDocKeys = { 
            addLawyerInfo: addLawyerInfo,
            court: court,
            paragraphs: paragraphs,
            frequencies: frequencies,
            wireParagraph: wireParagraph,
            achRows:  achRows.length  > 0 ? true : false,
            wireRows: wireRows.length > 0 ? true : false,
            mutualRelease: mutualRelease,
            prevailingParty: prevailingParty,
            noticeAndOrRightToCure: noticeAndOrRightToCure,
            rightToCure: rightToCure,
            noticeToCure: noticeToCure,
            paymentAmounts: paymentAmounts
        };  
    };

    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters sx={styles.container}>

                <Box sx={styles.headingBox}>
                    <ArrowBackIcon sx={styles.arrowIcon} onClick={() => history.goBack()}/>
                    <Typography sx={styles.settlement}>Settlement</Typography>
                </Box>

                <Typography sx={styles.title}>{merchant.merchants_legal_name}</Typography>
                 
                <SettlementDashboard merchant={merchant} />
                <SettlementCalculator 
                    merchant={merchant}
                    turnoverTotal={turnoverTotal} 
                    netSettlement={netSettlement}
                    payoffPerWeek={payoffPerWeek}
                    remainingPayoffPerWeek={remainingPayoffPerWeek}
                    settlementBalance={settlementBalance}
                    addLawyerInfo={addLawyerInfo} 
                    setAddLawyerInfo={setAddLawyerInfo}
                    mutualRelease={mutualRelease}
                    setMutualRelease={setMutualRelease}
                    prevailingParty={prevailingParty} 
                    setPrevailingParty={setPrevailingParty}
                    rightToCure={rightToCure}
                    setRightToCure={setRightToCure}
                    noticeToCure={noticeToCure} 
                    setNoticeToCure={setNoticeToCure}
                    setDocPreventionError={setDocPreventionError}
                />

                <Box sx={styles.midSection}> 
                    <SettlementTurnover merchant={merchant}/>
                    <SettlementPaymentPlan 
                        merchant={merchant}
                        payments={payments}
                        paymentPlanTotal={paymentPlanTotal}
                        payoffPerWeekFormatted={payoffPerWeekFormatted}
                        remainingPayoffPerWeekFormatted={remainingPayoffPerWeekFormatted }
                        nextStartDate={nextStartDate}
                    />
                </Box>
        
                <Box sx={styles.bottomSection}> 
                    <SettlementGenerations 
                        merchant={merchant} 
                        addLawyerInfo={addLawyerInfo} 
                        mutualRelease={mutualRelease}
                        prevailingParty={prevailingParty} 
                        rightToCure={rightToCure}
                        noticeToCure={noticeToCure} 
                        setDocPreventionError={setDocPreventionError}
                        nextStartDateFormatted={nextStartDateFormatted}
                        formattedDocKeys={formattedDocKeys}
                    />
                    <SettlementCommunications 
                        merchant={merchant}
                        frequencies={frequencies}
                        wireRowsArray={wireRowsArray}
                    />
                    <SettlementDocuSign />
                </Box>

                <Typography sx={styles.generationError}>{docPreventionError}</Typography>
            </Container>
        </ThemeProvider>
    );
};
export default SettlementContainer;