const styles = {

    container: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between',
        height: '430px',
        px: 5,
        py: 1,
    },

    title: {
        fontSize: '2.5rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        textAlign: 'center'
    },

    box: {
        display: 'flex',
        justifyContent: 'center'
    },

    warning: {
        fontSize: '2.5rem',
        color: '#d32f2f'
    },

    instructions: {
        fontFamily: 'AmazonEmberRegular', 
        fontWeight: 'bold', 
        fontSize: '1.1rem', 
        pt: 1.5
    },

    formRow: {
        display: 'flex',
        alignItems: 'center'
    },

    rowTitle: {
        fontSize: '1.1rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        width: '300px',
        pt: 2
    },

    formField: {
        m: 1
    },

    label: {
        fontFamily: "AmazonEmberRegular", 
        fontWeight: 'bold'
    },

    InputProps: {
        fontFamily: 'AmazonEmberRegular',
        borderRadius: 0,
        fontSize: "0.9rem",
    },

    button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none', 
        borderRadius: '0px',
        width: '350px',
        alignSelf: 'center',
        p: 1,
        mt: 2
    },
    
    error: {
        fontFamily: "AmazonEmberRegular",
        textAlign: 'center',
        color: '#d32f2f',
        p: '1rem' 
    }
}
export default styles