const styles = {

    container: {
        minHeight: '96vh',
        border: '1px solid #ccc', 
        borderRadius: '7px',
        overflowX: 'auto',
        mx: 2
    },

    title: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '3.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        mb: 1
    },

    lawyerForm: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: 'fit-content',
        margin: 'auto',
        p: 2,
        mb: 3
    },

    form : {
        display: 'flex'
    },

    InputProps: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        borderRadius: 0
    },

    inputProps: {
        height: '15px'
    },

    button: {
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: 0,
        height: '32px',
        lineHeight: '1'
    },

    error: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        color: '#c62828'
    },

    tblContainer: {
        width: 'fit-content',
        margin: "auto",
        border: 'solid 1px #ccc',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        height: '81.5vh'
    },

    hdrCell: {
        borderRight: 'solid 1px #ccc',
        backgroundColor: '#f5f5f5',
        p: 0
    },

    lastHdrCell: {  
        borderRight: 'solid 1px #ccc',
        textAlign: 'center',
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        py: 1.5
    },

    hdrCellBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', 
        py: 1.5,
        px: 1
    },
    
    hdrLabel: {
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        flexGrow: 1,
        textAlign: 'center'
    },

    filterIcon: {
        cursor: 'pointer',
        '&:hover': { color: '#FFA500'}
    },

    cellStyle: {
        padding: 0, 
        borderBottom: 'none' 
    },

    lstCellStyle: {
        padding: 0, 
        border: 'solid 1px #ccc',
        display: 'flex',
        justifyContent: 'center',
        height: '32px',
        alignItems: 'center',
    },

    trashIcon: {
        '&:hover': {color: '#c62828', cursor: 'pointer'}
    },
    
    noRows: {
        border: 'none',
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1rem',
        textAlign: 'center',
        pt: 30
    },
    // ================ LawyerGridPopover.js ================ //
    sort: {
        cursor: 'pointer',
        pb: 0.4,
        '&:hover': { color: '#FFA500', fontWeight: 'bold'}
    },

    inputPropsPopover: {
        height: '15px'
    },

    InputPropsPopover: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        paddingLeft: 0.5
    }
}
export default styles