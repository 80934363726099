import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './merchantStyles';
import AutoComplete from '../shared/AutoComplete';
import LawFormField from "../shared/LawFormField";

const smallerBoxes = ['Lawyers Tel', 'Lawyer Fax', 'City State Zip'];

function LawyerField({ row, merchant, handleLawyerChange, handleInputChange, inputValue}) {
    
    return (
        <Grid item xs={12} sm={6} md={6} lg={smallerBoxes.includes(row.key) ? 2 : 4} sx={styles.dashboardItem}>
            <Stack direction="column">
                <Typography sx={styles.dashboardKey}>{row.key}</Typography>

                {row.key === "Lawyer" ? 
                    <AutoComplete
                        merchantId={merchant.id}
                        inputProps="autoDashboardHeight"
                        InputProps="autoDashboardValue"
                        handleInputChange={handleInputChange}
                        inputValue={inputValue}
                    />
                    :
                    <LawFormField
                        lawyer={merchant.lawyer} 
                        name={row.name}
                        value={row.value}
                        handleChange={handleLawyerChange}
                        inputProps="autoDashboardHeight"
                        InputProps="autoDashboardValue"
                    />  
                }
            </Stack>
        </Grid>
    );
};
export default LawyerField;