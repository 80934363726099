const styles = {
    
    dialog: {
        px: 4,
        pt: 4,
        pb: 3,
        marginTop: '8%',
    },

    title: {
        fontSize: '2.7rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        textAlign: 'center',
        mb: 4
    },

    box: {
        display: 'flex', 
        alignItems: 'center'
    },

    boxTitle: {
        fontSize: '1.2rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        color: 'black', 
        width: '250px'
    },
    
    divider: {
        my: 1.5
    },

    button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none', 
        borderRadius: '0px',
        width: '300px',
        alignSelf: 'center',
        p: 1,
        mt: 1
    },

    error: {
        fontFamily: "AmazonEmberRegular",
        color: '#d32f2f',
        alignSelf: 'center',
        pt: '1rem' 
    },

    // ======== SettEmailSuccess.js ======== // (will be refactored soon):

    checkIcon: {
        color: 'green', 
        fontSize: 30, 
        mr: 0.5
    },
    
    successMessage: {
        fontFamily: 'AmazonEmberRegular', 
        fontSize: '1.3rem', 
        fontWeight: 'bold'
    }
}
export default styles