import React, { useState } from "react";
import styles from "./styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';

function DropZone({ droppedFile, setDroppedFile, fileLabel, setError }) {

    const [isHovered, setIsHovered] = useState(false);
    
    function handleDrop(event) {
        event.preventDefault();
        setIsHovered(false);
        setError("");
        setDroppedFile(event.dataTransfer.files[0]); 
    };
    // Highlight the dashed border of the box in ORANGE while it is active
    const handleDragOver    = (e) => {e.preventDefault(); setIsHovered(true)}
    const handleDragLeave   = ()  => setIsHovered(false)
    const handleRemoveClick = ()  => setDroppedFile(null)

    return (   
        <Box 
            sx={isHovered === false ? styles.dropZoneInact : styles.dropZoneAct} 
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >   
            {droppedFile ?
                <>
                <CheckCircleOutlinedIcon sx={styles.checkIcon}/>
                <Box sx={styles.dropSuccess}>
                    <Typography sx={styles.dropMessage}>File uploaded successfully</Typography>
                    <CloseIcon sx={styles.closeIcon} onClick={handleRemoveClick}/>
                </Box>
                </>
                :
                <>
                <FileDownloadOutlinedIcon sx={styles.downloadIcon}/>
                <Typography sx={styles.instructions}>{`Drop ${fileLabel} Here`}</Typography>
                <Typography sx={styles.type}>Allowed file type .pdf</Typography>
                </> 
            }
        </Box>
    );
};
export default DropZone;