import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MerchantUccField from './MerchantUccField';
import styles from './merchantStyles';

function MerchantsUccSummary() {
    
    const uccArray = [ {id: 1, key: '', value: ''} ];
    
    const uccSummary = uccArray.map(row => <MerchantUccField key={row.id} row={row} /> );

    return (

        <Box sx={styles.dashboardBox} p={1.5} m={1}>
            <Box sx={styles.uccHeaderBox}>
                <Typography sx={styles.uccHeader}>UCC Recipient</Typography>
                <Typography sx={styles.uccHeader}>Notice Status</Typography>
            </Box>

            {uccSummary}
        </Box>
    );
};
export default MerchantsUccSummary;