import React, { useState, useEffect } from 'react';
import styles from './payoffModalStyles';
import SelectButton from "../../shared/SelectButton";
import SelectField from "../../shared/SelectField";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme({ 
    palette: { primary: { main: '#5d9bd5' }, secondary: { main: '#FF9900' } },
    components: {MuiButton: { defaultProps: { disableRipple: true, variant: 'contained' } }}
})
function PayoffModal({ open, setOpen, merchant, nextStartDateFormatted, generateDocument }) {
    const [selectedVals, setSelectedVals] = useState({
        balance: "",
        endDate: "",
    });
    const [inputVals, setInputVals] = useState({
        balance: "",
        endDate: "",
    });
    const [error, setError] = useState("");

    let settlementAmount = null;

    if (merchant.settlement) {
        settlementAmount = merchant.settlement.settlement_amount
    }
    const noModal = !settlementAmount || !nextStartDateFormatted

    useEffect(() => {
        if (merchant.settlement) {
            setSelectedVals({
                balance: merchant.settlement.settlement_amount,
                endDate: nextStartDateFormatted,
            })
        }
    }, [merchant.settlement, nextStartDateFormatted]);

    function handleGenerateClick() {
        const templateData = {
            remainingBalance:   selectedVals.balance ? selectedVals.balance : inputVals.balance,
            paymentPlanEndDate: selectedVals.endDate ? selectedVals.endDate : inputVals.endDate,
        }
        const allFieldsFilled = Object.values(templateData).every(value => value)

        if (!allFieldsFilled) {
            setError("Please fill in all required fields before generating the document.")
            return;
        }
        generateDocument('Payoff Letter', templateData)
        setInputVals({balance: "", endDate: ""})
    }

    function handleSelect(name, option) {
        setError("")
        setSelectedVals({...selectedVals, [name]: option})
        setInputVals({...inputVals, [name]: ""})
    };

    function handleChange(event) {
        setError("")
        setInputVals({...inputVals, [event.target.name]: event.target.value})
        setSelectedVals({...selectedVals, [event.target.name]: ""})
    };
    
    const handelClose = () => setOpen(false);

    return (
        <Dialog open={open} onClose={handelClose} maxWidth="xl" PaperProps={{sx: styles.dialog}}>
        <ThemeProvider theme={defaultTheme}>
            <Typography sx={styles.title}>Payoff Letter</Typography>
            {noModal ? (
                <Typography sx={styles.error}>
                    Please fill out the settlement amount and payment plan end date to continue.
                </Typography>
                ):(
                <>
                <Box sx={styles.box}>
                    <Typography sx={styles.boxTitle}>Remaining Balance</Typography>
                    <SelectButton 
                        opt={settlementAmount} 
                        optToSelect={selectedVals.balance} 
                        handleClick={() => handleSelect('balance', settlementAmount)}
                    />
                    <SelectField 
                        handleChange={handleChange} 
                        name="balance" 
                        value={inputVals.balance}
                    />
                </Box>
                <Divider sx={styles.divider}/>
                <Box sx={styles.box}>
                    <Typography sx={styles.boxTitle}>Payment Plan End Date</Typography>
                    <SelectButton 
                        opt={nextStartDateFormatted} 
                        optToSelect={selectedVals.endDate} 
                        handleClick={() => handleSelect('endDate', nextStartDateFormatted)}
                    />
                    <SelectField 
                        handleChange={handleChange} 
                        name="endDate" 
                        value={inputVals.endDate}
                    />
                </Box>
                <Divider sx={styles.divider}/>
                <Button sx={styles.button} onClick={handleGenerateClick}>Generate Payoff Letter</Button>
                <Typography sx={styles.error}>{error}</Typography>
                </>
            )}
        </ThemeProvider>
        </Dialog>
    );
};
export default PayoffModal;