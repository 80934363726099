import React, { useState, useContext, useMemo, useEffect } from 'react';
import styles from './merchantStyles';
import MerchantsData from './MerchantsData';
import MerchantsUccSummary from './MerchantsUccSummary';
import MerchantsDashboard from './MerchantsDashboard';
import MerchantOperations from './MerchantOperations';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppContext } from "../AppContext";
import { useParams } from 'react-router-dom';
import { options, todaysDate } from '../utils';
import Big from 'big.js';

const theme = createTheme({ 
    breakpoints: { 
        values: { xs: 0, sm: 1200, md: 1500, lg: 1850 } 
    },
    components:  { 
        MuiButton: { defaultProps: { disableRipple: true, variant: 'contained' } } 
    },
    palette: { primary: { main: '#5d9bd5' }, secondary: { main: '#FF9900' } }
})
const transactionHeaders = ['Agreement Date', 'Purchased Amount', 'Remittance', 'Payment Frequency'];
const financialMetrics = ['Balance', 'NSF', 'Default Fee', 'Stacking'];
// Date Regex Pattern.
const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/
// Date formatting options.
const shortOptions = {month: '2-digit', day: '2-digit', year: '2-digit'}

function MerchantsPage() {
    // Context.
    const { merchants, editMerchant, editSettlement } = useContext(AppContext)
    // Screen Sizes.
    const isSmScr = useMediaQuery('(max-width: 2320px)')
    
    const params = useParams()

    const merchant = useMemo(
        () => merchants.find(merchant => merchant.id === parseInt(params.id)) || {},
        [merchants, params.id]
    )
    const settlement = merchant.settlement

    // States.
    const [data, setData] = useState({})
    const [settlementData, setSettlementData] = useState({})
    const [selectedDate, setSelectedDate] = useState(todaysDate)
  
    useEffect(() => {
        if (settlement) {
            setSettlementData({
                settlement_status: settlement.settlement_status,
                paid_to_date: settlement.paid_to_date
            })
        }
    },[settlement])

    useEffect(() => {
        if (merchant) {
            setData({
                agreement_date:                  merchant.agreement_date,
                aos:                             merchant.aos,
                balance:                         merchant.balance,
                bank_name:                       merchant.bank_name,
                business_phone:                  merchant.business_phone,
                city:                            merchant.city,
                created:                         merchant.created,
                d_b_a_title_case:                merchant.d_b_a_title_case,
                date_served:                     merchant.date_served,
                default_date:                    merchant.default_date,
                default_fee:                     merchant.default_fee,
                email_address:                   merchant.email_address,
                federal_tax_id:                  merchant.federal_tax_id,
                first_guarantor_title_case:      merchant.first_guarantor_title_case,
                image_date:                      merchant.image_date,
                index_number:                    merchant.index_number,
                lender_legal_name_title_case:    merchant.lender_legal_name_title_case,
                litigation_date:                 merchant.litigation_date,
                mailing_address:                 merchant.mailing_address,
                merchants_legal_name_title_case: merchant.merchants_legal_name_title_case,
                mobile:                          merchant.mobile,
                notes:                           merchant.notes,
                nsf:                             merchant.nsf,
                payment_frequency:               merchant.payment_frequency,
                physical_address:                merchant.physical_address,
                physical_city:                   merchant.physical_city,
                physical_state:                  merchant.physical_state,
                physical_zip:                    merchant.physical_zip,
                purchase_price:                  merchant.purchase_price,
                purchased_amount:                merchant.purchased_amount,
                purchased_percentage:            merchant.purchased_percentage,
                remittance:                      merchant.remittance,
                response_date:                   merchant.response_date,
                response_status:                 merchant.response_status,
                second_guarantor_title_case:     merchant.second_guarantor_title_case,
                service:                         merchant.service,
                stacking:                        merchant.stacking,
                state:                           merchant.state,
                state_of_incorporation:          merchant.state_of_incorporation,
                suit_status:                     merchant.suit_status,
                type_of_entity:                  merchant.type_of_entity,
                zip:                             merchant.zip,
                merch_track_no:                  merchant.merch_track_no,
                guar1_track_no:                  merchant.guar1_track_no,
                guar2_track_no:                  merchant.guar2_track_no,
                total_pb_amount:                 merchant.total_pb_amount,
                total_pb_amount_twenty:          merchant.total_pb_amount_twenty,
                default_judgment:                merchant.default_judgment,
                contract_payoff_date:            merchant.contract_payoff_date,
                damages:                         merchant.damages,
                legal:                           merchant.legal,
                total:                           merchant.total,
                ssn_first_guar:                  merchant.ssn_first_guar,
                ssn_second_guar:                 merchant.ssn_second_guar,
                judgment_date:                   merchant.judgment_date, 
                judgment_amount:                 merchant.judgment_amount,
                lawyer_id:                       merchant.lawyer_id
            })
        }
    },[merchant])
    // ============================= Functions ============================= //
    // Calculate Payback Amounts...
    function calculatePaybackAmount(data) {
        const divisorTwentySix = new Big('26')
        const divisorTwenty = new Big('20')
        const cleanedAmount = data.total.replace(/[^\d.-]/g, '')
        try {
            const dividend = new Big(cleanedAmount)
            const resultTwentySix = dividend.div(divisorTwentySix)
            const resultTwenty = dividend.div(divisorTwenty)
            const numberTwentySix = resultTwentySix.toNumber() 
            const numberTwenty = resultTwenty.toNumber() 
            const formatTwentySix = numberTwentySix.toLocaleString('en-US', { maximumFractionDigits: 2 })
            const formatTwenty = numberTwenty.toLocaleString('en-US', { maximumFractionDigits: 2 }) 
            return {
                ...data,
                total_pb_amount: "$" + formatTwentySix,
                total_pb_amount_twenty: "$" + formatTwenty
            }
        } 
        catch { return data }  
    }
    // Calculate the Default Judgment date ( 35 days after 'date served' )...
    function calculateDefaultJudgment(data) {
        if (data.date_served.match(dateRegex)) {
            const servedDate = new Date(data.date_served)
            servedDate.setDate(servedDate.getDate() + 35)
            const defaultJudgmentDate = servedDate.toLocaleDateString('en-US', shortOptions)
            return {...data, default_judgment: defaultJudgmentDate}
        }  
        else { return data }
    }
    // Calculate the Contract Payoff Date...
    function calculatePayoffDate(data) {
        const agreementDate = new Date(data.agreement_date)
        if (agreementDate === "Invalid Date") {
            return data
        } 
        else {
            const cleanedPurchasedAmount = data.purchased_amount.replace(/[^\d.-]/g, '')
            const cleanedRemittance = data.remittance.replace(/[^\d.-]/g, '')
    
            const purchasedAmtNum = parseInt(cleanedPurchasedAmount)
            const remittanceNum = parseInt(cleanedRemittance)
            const ratio = purchasedAmtNum / remittanceNum
    
            const wholeNumber = Math.round(ratio)
            let payoffDate;
    
            if(data.payment_frequency.toLowerCase() === "weekly") {
                agreementDate.setDate(agreementDate.getDate() +  wholeNumber * 7)
                payoffDate = agreementDate.toLocaleDateString('en-US', options)
            }
            else if (data.payment_frequency.toLowerCase() === "daily") {
                agreementDate.setDate(agreementDate.getDate() +  wholeNumber)
                payoffDate = agreementDate.toLocaleDateString('en-US', options)
            }
            else { 
                return data 
            }
            return { ...data, contract_payoff_date: payoffDate };
        }
    }

    function calculateDamagesLegalAndFull(data) {
        const cleanedBalance = data.balance.replace(/[^\d.-]/g, '')
        const cleanedNsf = data.nsf.replace(/[^\d.-]/g, '')
        const cleanedDefaultfee = data.default_fee.replace(/[^\d.-]/g, '')
        const cleanedStacking = data.stacking.replace(/[^\d.-]/g, '')

        const balanceNum = parseFloat(cleanedBalance)
        const nsfNum = parseFloat(cleanedNsf)
        const defaultFeeNum = parseFloat(cleanedDefaultfee)
        const stackingNum = parseFloat(cleanedStacking)

        const newDamages = balanceNum + nsfNum + defaultFeeNum + stackingNum + 165
        const newLegal = newDamages * 0.25
        const newFull = newDamages + newLegal

        const newDamagesFormatted = newDamages.toLocaleString('en-US', { maximumFractionDigits: 2 })
        const newLegalFormatted = Number(newLegal.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 })
        const newFullFormatted = Number(newFull.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 })

        const updatedData =  {
            ...data,
            damages: newDamagesFormatted === "NaN" ? data.damages  : "$" + newDamagesFormatted,
            legal:   newLegalFormatted   === "NaN" ? data.legal    : "$" + newLegalFormatted,
            total:   newFullFormatted    === "NaN" ? data.total    : "$" + newFullFormatted
        }
        return calculatePaybackAmount(updatedData)
    }

    function calculateLegalAndFull(data) {
        const cleanedDamages = data.damages.replace(/[^\d.-]/g, '')

        const damagesNum = parseFloat(cleanedDamages)

        const newLegal = damagesNum * 0.25
        const newFull = damagesNum + newLegal

        const newLegalFormatted = Number(newLegal.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 })
        const newFullFormatted = Number(newFull.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 })

        const updatedData = {
            ...data, 
            legal: newLegalFormatted === "NaN" ? data.legal : "$" + newLegalFormatted, 
            total: newFullFormatted  === "NaN" ? data.total : "$" + newFullFormatted
        }
        return calculatePaybackAmount(updatedData)
    }

    function calculateFull(data) {
        const cleanedLegal   = data.legal.replace(/[^\d.-]/g, '')
        const cleanedDamages = data.damages.replace(/[^\d.-]/g, '')

        const legalNum = parseFloat(cleanedLegal)
        const damagesNum = parseFloat(cleanedDamages)
        
        const newFull = damagesNum + legalNum

        const newFullFormatted = Number(newFull.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 })

        const updatedData =  {
            ...data,
            total: newFullFormatted === "NaN" ? data.total : "$" + newFullFormatted
        }
        return calculatePaybackAmount(updatedData)
    }
    // Upon 'Blur' update specific fields based on other fields...
    function updateMerchantData(rowKey, data) {
        if (rowKey === 'Full') {
            return calculatePaybackAmount(data)
        }
        if (rowKey === 'Date Served') {
            return calculateDefaultJudgment(data)
        }
        if (transactionHeaders.includes(rowKey)) {
            return calculatePayoffDate(data)
        }
        if (financialMetrics.includes(rowKey)) {
            return calculateDamagesLegalAndFull(data)
        }
        if(rowKey === 'Damages') {
            return calculateLegalAndFull(data)
        }
        if (rowKey === 'Legal') {
            return calculateFull(data)
        }
        return data
    }
    // Send a PATCH request to the backend...
    function handleBlur(rowKey) {
        setData(data => {
            const updatedData = updateMerchantData(rowKey, data);
            fetch(`/merchants/${merchant.id}`, {
                method: 'PATCH',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    ...updatedData,
                    merchants_legal_name:    data.merchants_legal_name_title_case.toUpperCase(),
                    d_b_a:                   data.d_b_a_title_case.toUpperCase(),
                    lender_legal_name:       data.lender_legal_name_title_case.toUpperCase(),
                    first_guarantor:         data.first_guarantor_title_case.toUpperCase(),
                    second_guarantor:        data.second_guarantor_title_case.toUpperCase(),
                })      
            })
            .then(response => response.json())
            .then(data => editMerchant(data))
            return updatedData
        })
    }
    
    function handleSettBlur() {
        fetch(`/settlements/${settlement.id}`, {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(settlementData)
        })
        .then(response => response.json())
        .then(data => editSettlement(data))
    };
    // Update fields dynamically.
    const handleCurrentDateChange = (event) => { setSelectedDate(event.target.value) };

    const handleChange = (event) => { setData({...data, [event.target.name]: event.target.value}) };

    const handleSettChange = (event) => {setSettlementData({...settlementData, [event.target.name]: event.target.value})};

    return (
        
        <ThemeProvider theme={theme}>
            <Box sx={styles.container}>
                <Typography 
                    sx={merchant.merchants_legal_name && merchant.merchants_legal_name.toLowerCase() === "missing!" ? 
                        styles.pageNameError
                        : 
                        styles.pageName
                    }
                >
                    {merchant.merchants_legal_name}
                </Typography>
        
                <Box sx={styles.topSectionContainer}>
                    <MerchantsData 
                        data={data} 
                        handleChange={handleChange} 
                        handleBlur={handleBlur}
                        selectedDate={selectedDate}
                        handleCurrentDateChange={handleCurrentDateChange}
                    />

                    { isSmScr ? null : <MerchantsUccSummary /> }

                    <MerchantsDashboard
                        settlementData={settlementData}
                        handleSettChange={handleSettChange}
                        handleSettBlur={handleSettBlur}
                        data={data} 
                        setData={setData}
                        handleChange={handleChange} 
                        handleBlur={handleBlur}
                        merchant={merchant}
                    />
                </Box>

                <Divider sx={styles.divider}/>

                <MerchantOperations merchant={merchant} selectedDate={selectedDate}/>
            </Box>
        </ThemeProvider>
    )
}
export default MerchantsPage