import React, { useState, useContext } from 'react'
import styles from './styles'
import PdfLoadingModal from  "../PdfLoadingModal"
import DropZone from "../shared/DropZone"
import noticeOfEntry from '../../templates/Notice of Entry.docx'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { PDFDocument } from "pdf-lib"
import { saveAs } from 'file-saver'
import { AppContext } from "../AppContext"
import { convertDocxToPdf, docxTemplateGenerate, loadFile } from '../utils'

function NoticeOfEntryGenerator({ open, setOpen, merchant, selectedDate }) {

    const [droppedFile, setDroppedFile] = useState(null);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { court } = useContext(AppContext);
    let solProp;
    if (merchant && merchant.type_of_entity) {
        const merchantName  = merchant.merchants_legal_name_title_case
        const firstGuarName = merchant.first_guarantor_title_case
        const entityType = merchant.type_of_entity.toLowerCase()
        solProp = merchantName === firstGuarName || entityType === "sole proprietorship";
    }

    async function mergeNOEWithJudgment(pdfUrl) {
        try {
            const pdfResponse = await fetch(pdfUrl).then((res) => res.arrayBuffer());
            const droppedBuf = await droppedFile.arrayBuffer();
            const pdfDocument = await PDFDocument.load(pdfResponse);
            const droppedPdf = await PDFDocument.load(droppedBuf);

            const droppedPdfPages  = droppedPdf.getPages();
            const droppedPdfLength = droppedPdf.getPageCount();
            const [existingPage]   = pdfDocument.getPages();
            const {width, height}  = existingPage.getSize();
            
            for(let i = 0; i < droppedPdfLength; i++) {
                const embeddedPage = await pdfDocument.embedPage(droppedPdfPages[i]);
                const insertedPage = pdfDocument.insertPage(2 + i, [width, height]);
                insertedPage.drawPage(embeddedPage, {
                    x: 0,
                    y: 0,
                    width: width,
                    height: height
                });
            };
            const pdfBytes = await pdfDocument.save();
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(pdfBlob, `Notice Of Entry - ${merchant.merchants_legal_name_title_case}`);
            setIsLoading(false);
            setDroppedFile(null);
            setOpen(false);
        }
        catch {
            setError("An error occurred during generation");
        } 
    };

    function createDocument() {
        const data = {
            ...merchant, 
            solProp: solProp, date: selectedDate, court: court, dateCaps: selectedDate.toUpperCase()
        }
        setIsLoading(true);
        loadFile(
            noticeOfEntry,
            async (error, content) => {
                const out = docxTemplateGenerate(content, data);
                const pdfUrl = await convertDocxToPdf(out);
                mergeNOEWithJudgment(pdfUrl);
            }
        )
    };

    function generateDocument() {
        if(!droppedFile) {
            setError("Please drop judgment doc into dropzone to proceed");
            return;
        }
        createDocument();
    };

    const handleClose = () => setOpen(false);

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{sx: styles.container}} maxWidth="xl">
            <PdfLoadingModal open={isLoading}/>
            <Typography sx={styles.title}>Notice Of Entry</Typography>
            <DropZone
                droppedFile={droppedFile} 
                setDroppedFile={setDroppedFile}
                fileLabel="Judgment Document"
                setError={setError}
            />
            <Button sx={styles.button} onClick={generateDocument}>Attach Judgment</Button>
            <Typography sx={styles.error}>{error}</Typography>
        </Dialog>
    );
};
export default NoticeOfEntryGenerator;