const style = {

    container: {
        alignItems: 'center',
        width: '500px',
        py: 3
    },

    title: {
        fontSize: '2.5rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        mb: 1
    },

    warningIcon: {
        color: '#d32f2f',
        fontSize: '2rem'
    },

    warning: {
        fontFamily: 'AmazonEmberRegular',
        textAlign: 'center',
        color: '#d32f2f',
        px: 2,
        pb: 2
    },

    back: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: "0.95rem",
        color: "#0099cc",
        cursor: 'pointer',
        pb: 1,
        '&:hover': {color: "#e06d33"}
    },

    button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none', 
        borderRadius: '0px',
        width: '350px',
        p: 1,
        mt: 2
    },
    
    error: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        textAlign: 'center',
        color: '#d32f2f',
        pt: 2,
        px: 2
    }
}
export default style