import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import { AppContext } from "../AppContext";
import styles from './styles';
import OverrideModal from './modals/OverrideModal'
import PdfLoadingModal from  ".././PdfLoadingModal";
import PayoffModal from './modals/PayoffModal'
import settlementPreLawsuit from '../../templates/Settlement - Pre Lawsuit.docx'
import settlementPostLawsuit from '../../templates/Settlement - Post Lawsuit.docx'
import payoffLetter from '../../templates/Payoff Letter.docx'
import {loadFile, convertDocxToPdf, addDocToMerchantsDocuments, todaysDate, docxTemplateGenerate} from '../utils';

function SettlementGenerations({ merchant, addLawyerInfo, formattedDocKeys, setDocPreventionError, nextStartDateFormatted }) {
    const [overrideOpen, setOverrideOpen] = useState(false);
    const [payoffOpen, setPayoffOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [documentType, setDocumentType] = useState(null);

    const { editMerchant } = useContext(AppContext)

    let docToBeGenerated = null;
    let litigationPhase = null;
    let isSolProp = null;
    const lawyer = merchant.lawyer
    const settlement = merchant.settlement

    if (merchant && merchant.suit_status) {
        docToBeGenerated = merchant.suit_status.toLowerCase() === "filed" ? settlementPostLawsuit : settlementPreLawsuit
        litigationPhase  = merchant.suit_status.toLowerCase() === "filed" ?  'Post Litigation' : 'Pre Litigation'
        const merchantName  = merchant.merchants_legal_name_title_case
        const firstGuarName = merchant.first_guarantor_title_case
        const entityType = merchant.type_of_entity.toLowerCase()
        isSolProp = merchantName === firstGuarName || entityType === "sole proprietorship";
    }

    function generateDocument(docType, templateData) {
        const data = {
            ...merchant,
            ...lawyer,
            ...settlement,
            ...formattedDocKeys,
            ...templateData, 
            solProp: isSolProp,
            defendant: isSolProp ? "Defendant" : "Defendants",
            todaysDate: todaysDate
        }
        loadFile(
            docType === "Payoff Letter" ? payoffLetter : docToBeGenerated,
            async (error, content) => {
                const out = docxTemplateGenerate(content, data)
                if (docType === "word") {
                    saveAs(out, `Settlement-${litigationPhase}-${merchant.merchants_legal_name_title_case}.docx`);
                    return setOverrideOpen(false);
                }
                setIsLoading(true)
                const pdfUrl = await convertDocxToPdf(out)
                if (docType === "pdf") {
                    saveAs(pdfUrl, `Settlement-${litigationPhase}-${merchant.merchants_legal_name_title_case}.pdf`)
                } else if (docType === "Payoff Letter") {
                    saveAs(pdfUrl, `Payoff Letter-${merchant.merchants_legal_name_title_case}.pdf`)
                }
                addDocToMerchantsDocuments(editMerchant, pdfUrl, merchant, docType, litigationPhase)
                setOverrideOpen(false);
                setPayoffOpen(false)
                setIsLoading(false)   
            }
        ) 
    }

    function handleGenerateClick(docType) {
        setDocumentType(docType)
        if (!settlement.settlement_amount) { 
            return setDocPreventionError("Please enter a settlement amount before generating documents.");
        }
        if (!merchant.lawyer && addLawyerInfo) {
            return setOverrideOpen(true)
        } 
        generateDocument(docType) 
    };

    return (

        <Box sx={styles.containerBorder} p={1} mr={0.5}>
            <PdfLoadingModal open={isLoading}/>

            <OverrideModal 
                merchant={merchant}
                open={overrideOpen} 
                setOpen={setOverrideOpen}
                generateDocument={generateDocument}
                documentType={documentType}
            /> 
            <PayoffModal
                merchant={merchant}
                open={payoffOpen}
                setOpen={setPayoffOpen}
                generateDocument={generateDocument}
                nextStartDateFormatted={nextStartDateFormatted}
            />
            <Typography sx={styles.sectionTitle}>Generate</Typography>
            <Box>
                <Button sx={styles.genButton} onClick={() => handleGenerateClick("word")}>Word</Button>
                <Button sx={styles.genButton} onClick={() => handleGenerateClick("pdf")}>PDF</Button>
            </Box>
            <Box>
                <Button sx={styles.genButton} onClick={() => setPayoffOpen(true)}>Payoff Letter</Button>
                <Button sx={styles.genButton}>UCC Releases</Button>
            </Box>
        </Box>
    );
};
export default SettlementGenerations;