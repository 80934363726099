import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './merchantStyles';

function MerchantDashboardField({ row, handleChange, handleBlur }) {
    
    return (

        <Grid 
            item 
            xs={12} sm={6} md={6} lg={row.key === "Notes" ? 12 : 4} 
            sx={row.key === "Notes" ? styles.dashboardItemNotes : styles.dashboardItem}
        >
            <Stack direction="column">
                
                <Typography sx={styles.dashboardKey}>{row.key}</Typography>
                <TextField
                    size="small"
                    autoComplete='new-password'
                    multiline={row.key === "Notes" ? true : false}
                    rows={row.key === "Notes" ? 2 : 1}
                    InputProps={{style: row.key === "Notes" ? styles.dashboardValueNotes : styles.dashboardValue}}
                    inputProps={{style: styles.dashboardFieldHeights}}
                    onChange={handleChange}
                    onBlur={() => handleBlur(row.key)} 
                    value={row.value ? row.value : ''}
                    name={row.name}
                />

            </Stack>
        </Grid>
    );
};
export default MerchantDashboardField;