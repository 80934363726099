import React from "react";
import SettlementDashboardItem from "./SettlementDashboardItem";
import Box from '@mui/material/Box';
import styles from "./styles";

function SettlementDashboard({ merchant }) {

    const lawyer = merchant.lawyer;

    const leftDashboard = [
        {
            key: 'Guarantor # 1',
            value: merchant.first_guarantor_title_case
        },
        {
            key: 'Guarantor # 2',   
            value: merchant.second_guarantor_title_case
        },
        {
            key: 'Phone',
            value: merchant.mobile
        },
        {
            key: 'Phone',
            value: merchant.business_phone
        },
        {
            key: 'Email',  
            value: merchant.email_address
        }
    ];

    const middleDashboard = [
        {
            key: 'Business State',
            value: merchant.physical_state
        },
        {
            key: 'Contact State',
            value: merchant.state
        },
        {
            key: 'Agreement Date',
            value: merchant.agreement_date
        },
        {
            key: 'Default Date',
            value: merchant.default_date
        }, 
        {
            key: 'Contract Payoff Date',
            value: merchant.contract_payoff_date
        },    
    ];

    const rightDashboard = [
        {
            key: 'Remittance',
            value: merchant.remittance
        },
        {
            key: 'Payment Frequency',
            value: merchant.payment_frequency
        },
        {
            key: 'Suit Status',      
            value: merchant.suit_status
        },
        { 
            key: 'Lawyer',
            value: lawyer ? lawyer.lawyer : ''
        }
    ];
   
    const leftSettlementDashboard   = leftDashboard.map((row, i) => <SettlementDashboardItem key={i} row={row} />);
    const middleSettlementDashboard = middleDashboard.map((row, i) => <SettlementDashboardItem key={i} row={row} />);
    const rightSettlementDashboard  = rightDashboard.map((row, i) => <SettlementDashboardItem key={i} row={row} />);

    return (

        <Box sx={styles.dashboardContainer}>
            <Box>{leftSettlementDashboard}</Box>
            <Box>{middleSettlementDashboard}</Box>
            <Box>{rightSettlementDashboard}</Box>
        </Box>
    );
};
export default SettlementDashboard;