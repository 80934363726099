import React from "react";
import styles from "./styles";
import TextField from '@mui/material/TextField';

function SelectField({handleChange, name, value}) {

    return (
        <TextField 
            size="small"
            autoComplete="off"
            placeholder="Enter custom..."
            color="secondary"
            InputProps={{sx: styles.InputProps}}
            sx={styles.textField}
            onChange={handleChange}
            name={name}
            value={value}   
        />
    );
};
export default SelectField;