import PizZipUtils from 'pizzip/utils/index.js'
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'

const options = {month: 'long', day: 'numeric', year: 'numeric'};
const todaysDate = new Date().toLocaleString('en-US', options);

const loadFile = (url, callback) => PizZipUtils.getBinaryContent(url, callback)

function docxTemplateGenerate(content, data) {
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip);
    doc.setData(data);
    doc.render();
    return doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });
};

async function convertDocxToPdf(generatedDocx) {
    const formData = new FormData()
    const docxFile = new File(
        [generatedDocx],
        'generated.docx', 
        {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
    )
    formData.append('File', docxFile)
    const response = await fetch("https://v2.convertapi.com/convert/docx/to/pdf?Secret=SvK6GnLScAtXBJ1W&StoreFile=true", {
        method: "POST",
        body: formData
    })
    const data = await response.json()
    return data.Files[0].Url
}
// ============================================ //
function formatCurrency(amount) {
    return Number(amount).toLocaleString(
        'en-US', 
        { 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    )
};

// CODE TO SAVE DOCS TO S3 -> this will be used over and over in many places //
// ========================================================================= //
function addDocToMerchantsDocuments(editMerchant, doc, merchant, doctype, litigationPhase) {
    let filename = null;

    if ( doctype ===  "pdf" ) {
        filename = `Settlement - ${litigationPhase} - ${todaysDate}.pdf`
    } 
    else if ( doctype === "Payoff Letter" ) {
        filename = "Payoff Letter - " + todaysDate + ".pdf"
    }
    
    if ( doctype === "Lawsuit" ) { 
        const formData = createFormData(doc, merchant, "Summons & Complaint - " + todaysDate + ".pdf");
        sendFormData(formData, editMerchant);
    } 
    else {
        fetch(doc)
        .then(response => response.blob())
        .then(blob => {
            const formData = createFormData(blob, merchant, filename);
            sendFormData(formData, editMerchant);
        });
    }
}
    
function createFormData(blob, merchant, filename) {
    const formData = new FormData();
    formData.append('id', merchant.id);
    formData.append('merchants_legal_name', merchant.merchants_legal_name_title_case);
    formData.append('doc', blob, filename);
    return formData;
}

function sendFormData(formData, editMerchant) {
    fetch('/add-doc-to-merchants-docs', {
        method: "POST",
        body: formData,
    })
    .then(response => response.json())
    .then(data => editMerchant(data));
}
// ========================================================================= //
export { 
    loadFile, 
    convertDocxToPdf, 
    formatCurrency, 
    addDocToMerchantsDocuments, 
    todaysDate, 
    options, 
    docxTemplateGenerate
}