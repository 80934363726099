import React, { useMemo, useContext } from "react";
import MerchantsDocumentsItem from "./MerchantsDocumentsItem";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useHistory } from 'react-router-dom';
import { AppContext } from "../AppContext";
import styles from "./merchantDocumentStyles";

function MerchantsDocumentsList() {
    
    const { merchants } = useContext(AppContext);

    const params = useParams();
    const history = useHistory();

    const merchant = useMemo(
        () => merchants.find(merchant => merchant.id === parseInt(params.id)) || {},
        [merchants, params.id]
    );

    let docList = [];

    if ( merchant.document_info ) {

        const documentInfoSorted = merchant.document_info.sort((a, b) => a.filename.localeCompare(b.filename));

        docList = documentInfoSorted.map(docObj => (
            <MerchantsDocumentsItem 
                key={docObj.id} 
                docObj={docObj} 
                merchantsName={merchant.merchants_legal_name_title_case}
            />
        ));
    };
    
    return (   
        <Container sx={styles.container}>
            <Box sx={styles.parentBox}>
                <ArrowBackIcon 
                    sx={styles.arrowIcon} 
                    onClick={() => history.goBack()}
                />
                <Typography sx={styles.title}>Documents</Typography>
            </Box>
            {docList}
        </Container>
    );
};
export default MerchantsDocumentsList;