import React from 'react'
import styles from './styles'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';

function GuarantorForm({title, fstNameVal, mdlNameVal, lstNameVal, fstName, mdlName, lstName, handleChange}) {

    return (
        <Box sx={styles.formRow}>
            <Typography sx={styles.rowTitle}>{title}</Typography>
            <Box sx={styles.formField}>
                <Typography sx={styles.label}>First Name</Typography>
                <TextField
                    size="small"
                    color="secondary"
                    InputProps={{sx: styles.InputProps}}
                    onChange={handleChange}
                    name={fstName}
                    value={fstNameVal}
                />
            </Box>

            <Box sx={styles.formField}>
                <Typography sx={styles.label}>Middle Name</Typography>
                <TextField
                    size="small"
                    color="secondary"
                    InputProps={{sx: styles.InputProps}}
                    sx={{width: "250px"}}
                    onChange={handleChange}
                    name={mdlName}
                    value={mdlNameVal}
                />
            </Box>

            <Box sx={styles.formField}>
                <Typography sx={styles.label}>Last Name</Typography>
                <TextField
                    size="small"
                    color="secondary"
                    InputProps={{sx: styles.InputProps}}
                    onChange={handleChange}
                    name={lstName}
                    value={lstNameVal}
                />
            </Box>
        </Box>
    )
}
export default GuarantorForm