import React, { useState, useContext } from "react";
import styles from "./styles";
import LawyerField from "./LawyerField";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AppContext } from "../AppContext";

function LawyerForm() {
    const { handleSubmitLawyer } = useContext(AppContext);
    const [data, setData] = useState({
       law_firm:       '',
       lawyer:         '',
       lawyer_address: '',
       city_state_zip: '',
       lawyer_email:   '',    
       lawyer_phone:   '',
       lawyer_fax:     ''
    });
    const [error, setError] = useState('');
    
    function handleSubmit(event) {
        event.preventDefault(); 
        handleSubmitLawyer(data, false, false, setError)
        setData({
            law_firm:       '',
            lawyer:         '',
            lawyer_address: '',
            city_state_zip: '',
            lawyer_email:   '',    
            lawyer_phone:   '',
            lawyer_fax:     ''
        })
    }

    function handleChange(event) {
        setError('')
        setData({...data, [event.target.name]: event.target.value})
    };

    const fields = [
        {name: "law_firm",       value: data.law_firm,       placeholder: "Law firm",       width: "350px"},
        {name: "lawyer",         value: data.lawyer,         placeholder: "Lawyer",         width: "225px"},
        {name: "lawyer_address", value: data.lawyer_address, placeholder: "Lawyer address", width: "275px"},  
        {name: "city_state_zip", value: data.city_state_zip, placeholder: "City State Zip", width: "250px"},
        {name: "lawyer_email",   value: data.lawyer_email,   placeholder: "Lawyer email",   width: "220px"},
        {name: "lawyer_phone",   value: data.lawyer_phone,   placeholder: "Lawyer tel",     width: "130px"},
        {name: "lawyer_fax",     value: data.lawyer_fax,     placeholder: "Lawyer fax",     width: "130px"},
    ]
    const renderedFields = fields.map(field => (
        <LawyerField 
            key={field.name}
            name={field.name} 
            value={field.value} 
            placeholder={field.placeholder}
            width={field.width}
            handleChange={handleChange}
        />
    ))
    
    return ( 
        <Box sx={styles.lawyerForm}>
            <Box component="form" onSubmit={handleSubmit} sx={styles.form}>
                {renderedFields}
                <Button variant="contained" type="submit" disableRipple sx={styles.button}>
                    Add Lawyer
                </Button>  
            </Box>
            <Typography sx={styles.error}>{error}</Typography>
        </Box>
    );
};
export default LawyerForm;