import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from "./styles";

function SettlementDashboardItem({ row }) {
    
    return (

        <Box sx={styles.dashboardBox}>
            <Typography sx={styles.dashboardKey}>{row.key}:</Typography>
            <Typography sx={styles.dashboardValue}>{row.value}</Typography>
        </Box>
    );
};
export default SettlementDashboardItem;