function getParticipantsData(merchant, guarantorTwo) {
    const merchantName  = merchant.merchants_legal_name_title_case
    const firstGuarName = merchant.first_guarantor_title_case
    const entityType = merchant.type_of_entity.toLowerCase()
    const isNotSoleProp = merchantName !== firstGuarName && entityType !== "sole proprietorship";

    const participantsData = [
        {
            name: merchant.first_guarantor_title_case,
            type: "guar1"   
        } 
    ]
    
    if (isNotSoleProp) {
        participantsData.push({
            name: merchant.merchants_legal_name_title_case,
            type: 'merchant'
        })
    }

    if (guarantorTwo && isNotSoleProp) {
        participantsData.push({
            name: merchant.second_guarantor_title_case,
            type: 'guar2'
        })
    }
    return participantsData;
}
export { getParticipantsData };