import React, { useContext } from 'react'
import Container from '@mui/material/Container'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavBar from './navigation/NavBar'
import BanksContainer from './banks/BanksContainer'
import Login from './login/Login'
import SubmissionsContainer from './submissions/SubmissionsContainer'
import DealList from './dealList//DealList'
import LawyersContainer from './lawyers/LawyersContainer'
import MerchantsPage from './merchants/MerchantsPage'
import MerchantsDocumentsList from './merchants/MerchantsDocumentsList'
import SettlementContainer from './settlement/SettlementContainer'
import PageNotFound from './notFound/PageNotFound'
import { Route, Switch } from 'react-router-dom'
import { AppContext } from "./AppContext"

function App() {

  const {user, isLoading} = useContext(AppContext);

  const isLgScr = useMediaQuery('(min-width: 1850px)');

  const contSm = {
    maxWidth: '100%', 
    minHeight: '100vh'
  };
  const contLg = {
    maxWidth: '2700px', 
    minHeight: '100vh',
    boxShadow: '0 10px 15px rgba(0, 23, 81, 0.2)'
  };

  if (isLoading) return <div></div>
  if (!user) return <Login />

  return (
    <Container disableGutters style={isLgScr ? contLg : contSm}>
      <NavBar />
      <Switch>
        <Route exact path='/'>
          <SubmissionsContainer />
        </Route>
        <Route exact path='/deal-list'>
          {user.boss ? <DealList /> : <PageNotFound />}
        </Route>
        <Route exact path='/lawyers-page'>
          {user.boss ? <LawyersContainer/> : <PageNotFound/>}
        </Route>
        <Route exact path='/banks-page'>
          {user.boss ? <BanksContainer/> : <PageNotFound/>}
        </Route>
        <Route exact path='/deal-list/:id'>
          {user.boss ? <MerchantsPage /> : <PageNotFound />}
        </Route>
        <Route exact path='/deal-list/:id/documents'>
          {user.boss ? <MerchantsDocumentsList /> : <PageNotFound />}
        </Route>
        <Route exact path='/deal-list/:id/settlement'>
          {user.boss ? <SettlementContainer /> : <PageNotFound />}
        </Route>
        <Route path='*'>
          <PageNotFound />
        </Route>
      </Switch> 
    </Container>
  )
}
export default App