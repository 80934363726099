const styles = {
    
    paperProps: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between',
        px: 7,
        py: 5,
        height: '550px',
        minWidth: '1000px'
    },

    title: {
        fontSize: '2.7rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        textAlign: 'center',
        mb: 4
    },

    box: {
        display: 'flex', 
        alignItems: 'center'
    },

    boxTitle: {
        fontSize: '1.1rem', 
        fontWeight: 'bold', 
        fontFamily: 'AmazonEmberRegular', 
        color: 'black', 
        width: '300px'
    },

    buttonBox: {
        display: 'flex', 
        justifyContent: 'center'
    },

    button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none', 
        borderRadius: '0px',
        width: '300px',
        p: 1,
        m: 1
    },

    error: {
        fontFamily: "AmazonEmberRegular",
        color: '#d32f2f',
        textAlign: 'center',
        paddingTop: '0.7rem' 
    }  
}
export default styles