import React, { useContext } from 'react'
import styles from './styles';
import BankRow from './BankRow';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { AppContext } from "../AppContext";

function BankTable() {
    const { banks } = useContext(AppContext);

    const bankList = banks.map(bank => <BankRow key={bank.id} bank={bank}/>)

    const col1 = {...styles.hdrCell, width: "250px", borderLeft: 'solid 1px #ccc'}
    const col2 = {...styles.hdrCell, width: "325px"}
    const col3 = {...styles.hdrCell, width: "220px"}
    const col4 = {...styles.hdrCell, width: "375px"}
    const col5 = {...styles.hdrCell, width: "157px"}
    const col6 = {...styles.hdrCell, width: "75px" }
    const col7 = {...styles.hdrCell, width: "157px"}
    const col8 = {...styles.hdrCell, width: "220px"}
    const col9 = {...styles.hdrCell}
    return (
        <TableContainer sx={styles.tblContainer}> 
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={col1}>Name</TableCell>
                        <TableCell sx={col2}>Attention</TableCell>
                        <TableCell sx={col3}>Address1</TableCell>
                        <TableCell sx={col4}>Address2</TableCell>
                        <TableCell sx={col5}>City</TableCell>
                        <TableCell sx={col6}>State</TableCell>
                        <TableCell sx={col7}>Zip</TableCell>
                        <TableCell sx={col8}>Tracking #</TableCell>
                        <TableCell sx={col9}>Trash</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bankList.length > 0 ?
                        bankList
                        :
                        <TableRow>
                            <TableCell sx={styles.noRows} colSpan={8}>
                                No Rows To Show
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default BankTable