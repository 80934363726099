function getDocumentData(merchant, guarantorTwo, formData, isNotSolProp) {
    const documentData = [
        {
            filename: `Default Judgment - ${merchant.merchants_legal_name_title_case}.pdf`,
            defJudgment: true,
            data: { solProp: isNotSolProp ? false : true }
        },
        {
            filename: `Non Military Affirmation - ${merchant.first_guarantor_title_case}.pdf`,
            ssn: merchant.ssn_first_guar,
            fstName: formData.guar1FstName,
            mdlName: formData.guar1MdlName,
            lstName: formData.guar1LstName,
            data: { 
                solProp: isNotSolProp ? false : true,
                guarantor: merchant.first_guarantor_title_case
            }
        }
    ]
    if (guarantorTwo && isNotSolProp) {
        documentData.push(
            {
                filename: `Non Military Affirmation - ${merchant.second_guarantor_title_case}.pdf`,
                ssn: merchant.ssn_second_guar,
                fstName: formData.guar2FstName,
                mdlName: formData.guar2MdlName,
                lstName: formData.guar2LstName,
                data: { guarantor: merchant.second_guarantor_title_case }
            }
        )
    }
    return documentData;
}
export { getDocumentData };