const styles = {

    navBox: {
        justifyContent: 'space-between',
        boxSizing: 'border-box', 
        width: 300
    },

    menuIcon: {
        fontSize: "40px",
        cursor: 'pointer',        
        ml: 2
    },

    boxMargin: {
        margin: 2
    },
    
    link: {
        textDecoration: 'none', 
        color: '#000000', 
        fontFamily: "AmazonEmberRegular",
        marginLeft: '0.5rem'
    },

    linkMgBm: {
        marginBottom: '1.5rem'
    },

    userBox: {
        display: 'flex', 
        alignItems: 'center', 
        border: 'solid 1px #DDDDDD', 
        padding: '7px 0 7px 5px',
        borderRadius: '7px', 
        marginBottom: '0.5rem'
    },

    userIcon: {
        fontSize: '1.5rem', 
        color: 'gray'
    },

    userName: {
        marginLeft: '0.2rem', 
        marginTop: '0.1rem',  
        fontFamily: "AmazonEmberRegular",
        display: 'block',
        overflow: "hidden",
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis",
    },
 
    logout: {
        border: 'solid 1px #DDDDDD', 
        padding: '7px 0 7px 10px',
        borderRadius: '7px', 
        fontFamily: "AmazonEmberRegular",
        cursor: 'pointer' 
    }
}
export default styles