import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import styles from './merchantStyles';

function MerchantDataField({row, handleChange, handleBlur, handleCurrentDateChange}) {

    return (

        <Box sx={styles.dataFieldBox}>
            <Typography sx={styles.datakey}>{row.key}</Typography>
            <TextField
                size="small"
                autoComplete="new-password"
                inputProps={{style: styles.fieldHeight}}
                InputProps={{style: row.value && row.value.toLowerCase() === "missing!" ? 
                    styles.dataErrorValue 
                    : 
                    styles.dataValue
                }}
                placeholder={row.key === 'Payment Frequency' ? "Available options: Weekly, Daily" : ''}
                onChange={row.key === 'Current date' ? handleCurrentDateChange : handleChange}
                onBlur={() => handleBlur(row.key)} 
                value={row.value ? 
                    row.value.toLowerCase() === "missing!" ? "Missing!" : row.value
                    : 
                    ''
                }
                name={row.name}
            />
        </Box> 
    );
};
export default MerchantDataField;