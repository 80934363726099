import React, { useState, useEffect, useContext } from 'react';
import SettlementPaymentPlanCard from './SettlementPaymentPlanCard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import styles from "./styles";
import { AppContext  } from "../AppContext";
import { formatCurrency } from '../utils'

function SettlementPaymentPlan({
    merchant, 
    paymentPlanTotal, 
    nextStartDate, 
    payoffPerWeekFormatted, 
    remainingPayoffPerWeekFormatted,
    payments
}) {
    
    const { postPayment } = useContext(AppContext);

    let paymentCards = [];
    let total = null;
    let amountFrequencySum = null;

    if (merchant.settlement) {
        paymentCards = payments.map(payment => <SettlementPaymentPlanCard key={payment.id} payment={payment} />)
    };

    const [paymentData, setPaymentData] = useState({
        settlement_id: '',
        amount: '',
        frequency: '',
        payment_type: 'Weekly',
        day_of_week: 'Friday',
        pay: 'ACH Pull',
        start_date: ''
    });

    useEffect(() => {
        if(merchant.settlement) {
            const payments = merchant.settlement.payments
            const payoffWeeks = merchant.settlement.payoff_weeks
            const paymentWeeks =  merchant.settlement.payment_weeks
            setPaymentData({
                settlement_id: merchant.settlement.id,
                amount:    payments.length > 0 ? remainingPayoffPerWeekFormatted : payoffPerWeekFormatted,
                frequency: payments.length > 0 ? paymentWeeks   : payoffWeeks,
                payment_type: 'Weekly',
                day_of_week: 'Friday',
                pay: 'ACH Pull',
                start_date: nextStartDate ? nextStartDate : ''
            });
        };
    }, [merchant, nextStartDate, remainingPayoffPerWeekFormatted, payoffPerWeekFormatted]);
    
    const [errors, setErrors] = useState([]);
    const [totalError, setTotalError] = useState("");
  
    const errorMessages = errors.map(error => <li key={error} style={styles.errors}>{error}</li>);

    function calculatePaymentTotal(amount, frequency) {
        const cleanedAmount = amount.replace(/[^\d.-]/g, '')
        const cleanedFrequency = frequency.replace(/[^\d.-]/g, '')

        amountFrequencySum = parseFloat(cleanedAmount) * parseFloat(cleanedFrequency)

        total = formatCurrency(amountFrequencySum)
    };

    function formatAmountBlur() {
        const cleanedAmount = paymentData.amount.replace(/[^\d.-]/g, '');
        const cleanedAmountNum = formatCurrency(parseFloat(cleanedAmount)) ;
        setPaymentData({...paymentData, amount: cleanedAmountNum === "NaN" ? "" : cleanedAmountNum});
    };

    function handleSubmit(event) {
        event.preventDefault()
        calculatePaymentTotal(paymentData.amount, paymentData.frequency)
        if (!isNaN(amountFrequencySum)) {
            fetch('/payments', {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    settlement_id: paymentData.settlement_id,
                    frequency: paymentData.frequency,
                    payment_type: paymentData.payment_type,
                    day_of_week: paymentData.day_of_week,
                    pay: paymentData.pay,
                    start_date: paymentData.start_date,
                    amount: paymentData.amount,
                    total: total    
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => postPayment(data))
                } else {
                    response.json().then(data => setErrors(data.errors))
                }
            })
        } else { setTotalError("Please ensure amount and frequency are numbers") } 
    };
  
    function paymentChange(event) {
        setErrors([]);
        setTotalError("");
        setPaymentData({...paymentData, [event.target.name]: event.target.value});
    };

    return (

        <Box sx={styles.containerBorder} p={1} ml={0.5} component="form" onSubmit={handleSubmit}>
            
            <Typography sx={styles.sectionTitle}>Payment Plan</Typography>
            
            <Box sx={styles.fieldBox}>
                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder='Amount'
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{
                        style: styles.paymentFieldAmount, 
                        startAdornment:<Typography sx={styles.inputAdornment}>$</Typography>
                    }}
                    onBlur={formatAmountBlur}
                    onChange={paymentChange}
                    name='amount'
                    value={paymentData.amount}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder='Frequency'
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.paymentFieldGeneral}}
                    onChange={paymentChange}
                    name='frequency'
                    value={paymentData.frequency}
                />

                <Select sx={styles.select} onChange={paymentChange} name='payment_type' value={paymentData.payment_type}>
                    <MenuItem sx={styles.menuItem} value='Daily'>Daily</MenuItem>
                    <MenuItem sx={styles.menuItem} value='Weekly'>Weekly</MenuItem>
                    <MenuItem sx={styles.menuItem} value='Monthly'>Monthly</MenuItem>
                </Select>

                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder='Day of Week'
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.paymentFieldDay}}
                    onChange={paymentChange}
                    name='day_of_week'
                    value={paymentData.day_of_week}
                />

                <Select sx={styles.select} onChange={paymentChange} name='pay' value={paymentData.pay}>
                    <MenuItem sx={styles.menuItem} value='Wire'>Wire</MenuItem>
                    <MenuItem sx={styles.menuItem} value='ACH Pull'>ACH Pull</MenuItem>
                </Select>
                
                <TextField
                    type="date"
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.paymentFieldStartDate}}
                    onChange={paymentChange}
                    name='start_date'
                    value={paymentData.start_date}
                />
    
                <Typography sx={styles.total}>${paymentPlanTotal}</Typography>
                
                <Button type="submit" sx={styles.submitButton}>Add Row</Button>
            </Box>
            
            {errorMessages}
            {totalError ? <li style={styles.errors}>{totalError}</li> : ""}

            <Divider sx={styles.divider}/>

            {paymentCards}
        </Box> 
    );
};
export default SettlementPaymentPlan;