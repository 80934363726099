import React, { useState, useContext } from 'react';
import PdfLoadingModal from  "../PdfLoadingModal";
import styles from './styles';
import Button from '@mui/material/Button';
import { getAffidavitData, getAddMailData } from './affidavitData';
import { loadFile , convertDocxToPdf, docxTemplateGenerate } from "../utils";
import { saveAs } from 'file-saver';
import { PDFDocument } from "pdf-lib";
import { AppContext } from "../AppContext";
import affidavitOfService from '../../templates/Affidavit of Service.docx';
import additionalMailing from '../../templates/Additional Mailing.docx';

function AffidavitGenerator({ merchant, selectedDate }) {
    const [isLoading, setIsLoading] = useState(false);

    const { court } = useContext(AppContext);

    const guarantorTwo = merchant.second_guarantor_title_case;
    const options = {month: 'long', day: 'numeric', year: 'numeric'}

    async function mergePdfWithDoc(doc, pdfUrl, filename) {
        try {
            const pdfFile  = await fetch(pdfUrl).then((res) => res.arrayBuffer());
            const mainDoc  = await PDFDocument.load(pdfFile);
            const accptDoc = await PDFDocument.load(doc);

            const accptDocPages  = accptDoc.getPages();
            const [existingPage] = mainDoc.getPages();
            const { width, height } = existingPage.getSize();

            const embeddedPage = await mainDoc.embedPage(accptDocPages[0]);
            const mainPage = mainDoc.insertPage(1, [width, height]);
            mainPage.drawPage(embeddedPage, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
            const pdfBytes = await mainDoc.save();
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(pdfBlob, filename);
            setIsLoading(false)
        } 
        catch {
            console.log("An error occurred during generation");
        }
    };
    
    async function getAcceptanceInfo(data) {
        const response = await fetch('/get-mail-tracking-status', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({ pic: data.trackingNo })
        });
        const  resData = await response.json();
        return resData.Data[0];
    }
    
    async function createDocument(filename, data, aosAm) {
        const accData = await getAcceptanceInfo(data)
        const accDate = new Date(accData.AcceptedDate).toLocaleString('en-US', options)

        loadFile(affidavitOfService, async (error, content) => {
            const tempData = {...merchant, ...data, court: court, date: accDate, currentDate: selectedDate}
            const out = docxTemplateGenerate(content, tempData)
            const aosPdfUrl = await convertDocxToPdf(out)
            mergePdfWithDoc(accData.AcceptanceDoc, aosPdfUrl, filename)
        })

        if(aosAm) {
            const amInfo = getAddMailData(merchant)[aosAm]
            const amData = amInfo.data
            const tempData = {...merchant, ...amData, court: court, date: accDate, currentDate: selectedDate}
            loadFile(additionalMailing, async (error, content) => {
                const out = docxTemplateGenerate(content, tempData)
                const amPdfUrl = await convertDocxToPdf(out)
                saveAs(amPdfUrl, amInfo.filename);  
            })
        }    
    };

    function generateDocuments() {
        if (merchant.status) {
            setIsLoading(true)
            const documents = getAffidavitData(merchant, guarantorTwo)
            documents.forEach(doc => createDocument(doc.filename, doc.data, doc.aosAm))
        }
    };

    return (
        <>
            <PdfLoadingModal open={isLoading}/>
            <Button sx={styles.button} onClick={generateDocuments}>AOS</Button>
        </>
    );
};
export default AffidavitGenerator;