import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import styles from "./styles";
import { AppContext } from "../AppContext";
import { formatCurrency } from '../utils'

function SettlementPaymentPlanCard({ payment }) {

    const { deletePayment, editPayment } = useContext(AppContext);

    const [data, setData] = useState({
        settlement_id: payment.settlement_id,
        amount: payment.amount,
        frequency: payment.frequency,
        day_of_week: payment.day_of_week,
        start_date: payment.start_date
    });

    let amountFrequencySum = null;
    let total = null;
    let pay = payment.pay;
    let paymentType = payment.payment_type;
    let amount = data.amount;

    const [errors, setErrors] = useState([]);
    const [totalError, setTotalError] = useState("");

    const errorMessages = errors.map(error => <li key={error} style={styles.errors}>{error}</li>);

    function updatePaymentDetails() {
        const cleanedAmount = data.amount.replace(/[^\d.-]/g, '')
        const cleanedFrequency  = data.frequency.replace(/[^\d.-]/g, '')

        const amountNum = parseFloat(cleanedAmount)
        const frequencyNum = parseFloat(cleanedFrequency)
        
        amountFrequencySum = amountNum * frequencyNum
        total = formatCurrency(amountFrequencySum)

        const formattedAmount = formatCurrency(amountNum)
        amount = formattedAmount === "NaN" ? amount : formattedAmount
        setData({...data, amount: formattedAmount === "NaN" ? amount : formattedAmount})
    };

    function handleClick() {
        fetch(`/payments/${payment.id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(data => deletePayment(data))
    };
    
    function handleBlur() {
        updatePaymentDetails()
        if (!isNaN(amountFrequencySum)) {
            fetch(`/payments/${payment.id}`, {
                method: "PATCH",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    settlement_id: data.settlement_id,
                    frequency: data.frequency,
                    day_of_week: data.day_of_week,
                    start_date: data.start_date,
                    amount: amount,
                    payment_type: paymentType,
                    pay: pay,
                    total: total
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => editPayment(data))
                } else {
                    response.json().then(data => setErrors(data.errors))
                }  
            })
        } else { setTotalError("Please ensure amount and frequency are numbers") }
    };

    function handleChange(event) {
        setErrors([])
        setTotalError("")
        setData({...data, [event.target.name]: event.target.value})
    };

    function handleSelectChange(event, type) {
        setErrors([]);
        setTotalError("");

        if (type === "PaymentType") {
            paymentType = event.target.value;
        } else if (type === "Pay") {
            pay = event.target.value; 
        }

        handleBlur();
    };
    
    return (

        <>
            <Box sx={styles.fieldBox}>
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{
                        style: styles.paymentFieldAmount, 
                        startAdornment:<Typography sx={styles.inputAdornment}>$</Typography>
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="amount"
                    value={data.amount}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.paymentFieldGeneral}}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="frequency"
                    value={data.frequency}
                />

                <Select sx={styles.select} onChange={(e) => handleSelectChange(e, "PaymentType")} value={paymentType}>
                    <MenuItem sx={styles.menuItem} value='Daily'> Daily</MenuItem>
                    <MenuItem sx={styles.menuItem} value='Weekly'>Weekly</MenuItem>
                    <MenuItem sx={styles.menuItem} value='Monthly'>Monthly</MenuItem>
                </Select>

                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.paymentFieldDay}}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="day_of_week"
                    value={data.day_of_week}
                />

                <Select sx={styles.select} onChange={(e) => handleSelectChange(e, "Pay")} value={pay}>
                    <MenuItem sx={styles.menuItem} value='Wire'>Wire</MenuItem>
                    <MenuItem sx={styles.menuItem} value='ACH Pull'>ACH Pull</MenuItem>
                </Select>

                <TextField
                    type="date"
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.paymentFieldStartDate}}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="start_date"
                    value={data.start_date}
                />

                <Typography sx={styles.total}>${payment.total}</Typography>
                
                <DeleteForeverOutlinedIcon sx={styles.trashIcon} onClick={handleClick}/>
            </Box>

            {errorMessages}
            {totalError ? <li style={styles.errors}>{totalError}</li> : ""}
        </>
    );
};
export default SettlementPaymentPlanCard;