import React from 'react'
import styles from './styles'
import TextField from '@mui/material/TextField'

function BankField({name, value, width, handleChange, handleBlur, placeholder}) {
    // autoComplete="new-password": Prevents browser from autofilling contact information (e.g., name, address).
    return (
        <TextField
            size="small"
            autoComplete="new-password"
            placeholder={placeholder}
            InputProps={{sx: styles.InputProps}}
            inputProps={{sx: styles.inputProps}}
            sx={{width: width}}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name}
            value={value}
        />
    )
}
export default BankField