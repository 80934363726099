const styles = {

    // =============== MerchantsPage.js =============== //
    container: {
        px: 3
    },

    pageName: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem', lg: '3rem'},
        fontWeight: 'bold', 
        textAlign: 'center',
        mb: 2,
        py: {xs: 4, sm: 0}
    },

    pageNameError: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem', lg: '3rem'},
        fontWeight: 'bold', 
        textAlign: 'center',
        mb: 2,
        color: '#c62828',
        py: {xs: 4, sm: 0}
    },

    topSectionContainer: {
        display: 'flex',
        mb: 4
    },

    divider: {
        mb: 3
    },

    // ============= MerchantOperations.js ============= //
    opContainer: {
        display: 'flex'
    },

    section: {
        width: '50%'
    },

    innerSection: {
        width: '50%'
    },

    sectionFlex: {
        display: 'flex'
    },
    
    buttonGroup: {
        width: '25%',
        mx: '0.2rem'
    },

    buttonGroup2: {
        width: '50%',
        mx: '0.2rem'
    },

    title: {
        fontFamily: 'AmazonEmberRegular', 
        fontWeight: 'bold', 
        fontStyle: 'italic',
        textAlign: 'center', 
        p: 1
    },

    btn: {
        fontFamily: 'AmazonEmberRegular', 
        fontWeight: 'bold',
        color: 'black', 
        textTransform: 'none',
        borderRadius: '0px',
        width: '100%',
        display: 'block',
        overflow: "hidden",
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis",
        mt: 1
    },
    
    comms: {
        backgroundColor: '#f29773',  
        '&:hover': { backgroundColor: '#d9845b'} 
    },

    rep: {
        backgroundColor: '#ffc14d',
        '&:hover': { backgroundColor: '#e0a83a'}
    },

    ucc: {
        backgroundColor: '#7cb464', 
        '&:hover': { backgroundColor: '#689d50'}
    },

    link: { 
        textDecoration: 'none'
    },
    // ================== MerchantsData.js ================= //

    dataBox: {
        display: 'flex',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        p: 1,
        m: 1
    },

    innerData: {
        p: 1
    },

    // =============== MerchantDataField.js ============== //

    dataFieldBox: {
        display: 'flex',
    },

    fieldHeight: {
        height: '8px'
    },

    datakey: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '180px',
        backgroundColor: '#A7C7E7',
        borderBottom: 'solid 1px',
        paddingLeft: '10px'
    },

    dataValue: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '250px',
        borderRadius: '0px'
    },

 
    dataErrorValue: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '250px',
        borderRadius: '0px',
        color: 'red'
    },
    
    // ================ MerchantsUccSummary.js ============= //

    uccHeaderBox: {
        display: 'flex'
    },

    uccHeader: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        textAlign: 'center',
        width: '50%'
    },

    // ================ MerchantUccField.js ============== //

    uccFieldBox: {
        display: 'flex'
    },

    uccTextField: {
        fontSize: '0.8rem',
        width: '300px',
        height: '25px',
        borderRadius: '0px'
    },

    // =============== MerchantsDashboard.js ============== //

    dashboardArea: {
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-between',
        my: 1,
        mx: 1
    },

    dashboardBox: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    
    lawyerAddRemoveBox: {
        display: 'flex', 
        flexDirection: 'column',
        ml: 1
    },
    
    lawRmvButton: {
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none',
        boxShadow: 'none',
        height: '20px',
        color: '#a50e0e',
        backgroundColor: "#fce8e6",
        border: 'solid 1px #a50e0e',
        mt: 1,
        '&:hover': { backgroundColor: '#f7b9b3', boxShadow: 'none'}
    },
    
    lawAddButton: {
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none',
        boxShadow: 'none',
        height: '20px',
        color: 'black',
        backgroundColor: '#5d9bd5',
        border: 'solid 1px black',
        mt: 1,
        '&:hover': { backgroundColor: '#4c88be', boxShadow: 'none'}
    },

    // ============= MerchantDashboardField.js ============= //

    dashboardItem: {
        height: { xs: '70px', sm: '70px', md: '70px', lg: '58px'}
    },

    dashboardItemNotes: {
        height: { xs: '70px', sm: '70px', md: '70px', lg: '82px'}
    },

    dashboardKey: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: { xs: '0.75rem', sm: '0.9rem', md: '1rem', lg: '0.9rem'},
        fontWeight: 'bold',
        backgroundColor: '#BCCFCF',
        border: 'solid 1px #bbb',
        pl: 1.5
    },
    
    dashboardValue: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        borderRadius: 0
    },

    dashboardValueNotes: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        borderRadius: 0
    },

    dashboardFieldHeights: {
        height: '8px'
    }
}
export default styles