import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import styles from "./styles";

function SettlementCalculationField({ row, handleSettChange, handleBlur }) {

    const amounts = ["Turnover Amount", "Net Settlement", "Settlement Balance"];

    if (row.key === "Payoff Weeks" || row.key === "Payment Weeks") {
        return (
            <Box sx={styles.calculationFieldBox}>
                <Typography sx={styles.calculationFieldKey}>{row.key}</Typography>
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.calculationFieldHeight}}
                    InputProps={{style: styles.calculationFieldPayoff}}
                    onChange={handleSettChange}
                    onBlur={() => handleBlur(row.key)}
                    name={row.name}
                    value={row.value ? row.value : ''}
                />
                <Typography sx={styles.payoffAmount}>{row.payoffPerWeek}</Typography>
            </Box>  
        );
    };

    if (row.key === "Legal") {
        return (
            <Box sx={styles.calculationFieldBox}>
                <Typography sx={styles.calculationFieldKey}>{row.key}</Typography>
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.calculationFieldHeight}}
                    InputProps={{style: styles.calculationFieldLegal}}
                    onChange={handleSettChange}
                    onBlur={() => handleBlur(row.key)}
                    name={row.name}
                    value={row.value ? row.value : ''}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    placeholder="%"
                    inputProps={{style: styles.calculationPercentageInput}}
                    InputProps={{style: styles.calculationFieldLegalPercentage}}
                    onChange={handleSettChange}
                    onBlur={() => handleBlur(row.key)}
                    name="percent"
                    value={row.percent? row.percent : ''}
                />
            </Box>
        );
    };
    
    return (
        
        <Box sx={styles.calculationFieldBox}>
            <Typography sx={styles.calculationFieldKey}>{row.key}</Typography>
            <TextField
                size="small"
                autoComplete="new-password"
                inputProps={{style: styles.calculationFieldHeight}}
                InputProps={{
                    style: styles.calculationFieldValue, 
                    readOnly: amounts.includes(row.key) ? true : false
                }}
                onChange={handleSettChange}
                onBlur={() => handleBlur(row.key)}
                name={row.name}
                value={row.value ? row.value : ''}
            />
        </Box>
    );
};
export default SettlementCalculationField;