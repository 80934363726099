import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import styles from "./styles";
import { AppContext } from "../AppContext"
import { formatCurrency } from '../utils'

function SettlementTurnoverCard({ turnover }) {

    const { deleteTurnover, editTurnover } = useContext(AppContext);

    const [data, setData] = useState({
        settlement_id: turnover.settlement_id,
        ucc_recipient: turnover.ucc_recipient,
        amount: turnover.amount,
        ucc_letter_date: turnover.ucc_letter_date
    });

    const [errors, setErrors] = useState([]);

    const errorMessages = errors.map(error => <li key={error} style={styles.errors}>{error}</li>);

    let amount = data.amount

    function handleDelete() {
        fetch(`/turnovers/${turnover.id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(data => deleteTurnover(data))
    };

    function formatTurnoverAmount() {
        const cleanedAmount = amount.replace(/[^\d.-]/g, '')
        const amountNum = parseFloat(cleanedAmount)
        const formattedAmount = formatCurrency(amountNum)
        amount = formattedAmount === "NaN" ? amount : formattedAmount
        setData({...data, amount: formattedAmount === "NaN" ? amount : formattedAmount})
    };
    
    function handleBlur() {
        formatTurnoverAmount()
        fetch(`/turnovers/${turnover.id}`, {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                settlement_id: data.settlement_id,
                ucc_recipient: data.ucc_recipient,
                ucc_letter_date: data.ucc_letter_date,
                amount: amount
            })
        })
        .then((response) => {
            if(response.ok) {
                response.json().then(data => editTurnover(data))
            } else {
                response.json().then(data => setErrors(data.errors))
            }
        })
    };

    function handleChange(event) {
        setErrors([]);
        setData({...data, [event.target.name]: event.target.value});
    };

    return (

        <>
            <Box sx={styles.fieldBox}>
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.turnoverFieldRecipient}}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="ucc_recipient"
                    value={data.ucc_recipient}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{
                        style: styles.turnoverFieldAmount, 
                        startAdornment:<Typography sx={styles.inputAdornment}>$</Typography>
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="amount"
                    value={data.amount}
                />
                <TextField
                    size="small"
                    autoComplete="new-password"
                    inputProps={{style: styles.fieldHeight}}
                    InputProps={{style: styles.turnoverFieldDate}} 
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="ucc_letter_date"
                    value={data.ucc_letter_date}
                />

                <DeleteForeverOutlinedIcon sx={styles.trashIcon} onClick={handleDelete}/>
            </Box>

            {errorMessages}
        </>
    );
};
export default SettlementTurnoverCard;