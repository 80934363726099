const styles = {

    container: {
        textAlign: 'center',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '0 0 2rem 0'
    },
    
    instructions: {
        fontSize: '2rem',
        fontWeight: 'bold',
        fontFamily: "AmazonEmberRegular",
        marginBottom: '2rem'
    } 
}
export default styles