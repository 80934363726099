import React from 'react';
import styles from "./styles";
import TextField from '@mui/material/TextField';

function LawFormField({lawyer, inputProps, InputProps, handleChange, name, value}) {

    return (
        <TextField 
            size="small" 
            autoComplete='new-password'
            inputProps={{sx: styles[inputProps]}} 
            InputProps={{
                readOnly: lawyer ? true : false,
                sx: styles[InputProps]
            }}
            onChange={handleChange}
            value={value ? value : ""}
            name={name}
        />  
    )
}
export default LawFormField