import React, { useState, useContext } from 'react';
import styles from './styles';
import BankField from './BankField';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppContext } from "../AppContext";

function BankRow({ bank }) {
    const { banks, setBanks, updateBanks } = useContext(AppContext);
    const [data, setData] = useState({
        name:      bank.name,
        attention: bank.attention,
        address1:  bank.address1,
        address2:  bank.address2,
        city:      bank.city,
        state:     bank.state,
        zip:       bank.zip,
        track_no:  bank.track_no,
    })

    function handleBlur() {
        fetch(`/banks/${bank.id}`, {
            method: "PATCH",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => updateBanks(data))
    }

    function handleDelete() {
        fetch(`/banks/${bank.id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(data => setBanks(banks.filter(bank => bank.id !== data.id)))
    }

    function handleChange(event) {
        setData({...data, [event.target.name]: event.target.value})
    }

    const fields = [
        {name: "name",      value: data.name,      width: "250px"},
        {name: "attention", value: data.attention, width: "325px"},
        {name: "address1",  value: data.address1,  width: "220px"},  
        {name: "address2",  value: data.address2,  width: "375px"},
        {name: "city",      value: data.city,      width: "157px"},
        {name: "state",     value: data.state,     width: "75px" },
        {name: "zip",       value: data.zip,       width: "157px"},
        {name: "track_no",  value: data.track_no,  width: "220px"},
    ]
    const renderedFields = fields.map(field => (
        <TableCell key={field.name} sx={styles.cellStyle}>
            <BankField 
                name={field.name} 
                value={field.value} 
                width={field.width}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
        </TableCell>
    ))
    
    return (
        <TableRow>
            {renderedFields}
            <TableCell sx={styles.lstCellStyle}>
                <DeleteIcon sx={styles.trashIcon} onClick={handleDelete}/>
            </TableCell>
        </TableRow>
    )
}
export default BankRow