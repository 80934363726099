import React, { useState, useContext, useEffect} from "react";
import { AppContext } from "../../AppContext";
import styles from "./overrideModalStyles";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import AutoComplete from "../../shared/AutoComplete";
import LawFormField from "../../shared/LawFormField";

function OverrideModal({ open, setOpen, generateDocument, documentType, merchant }) {

    const { handleSubmitLawyer } = useContext(AppContext)

    const [isExpanded, setIsExapanded] = useState(false);
    const [genDoc, setGenDoc] = useState(false);
    const [error, setError] = useState('');
    const [lawyerData, setLawyerData] = useState({})
    const [inputValue, setInputValue] = useState('')

    const lawyer = merchant.lawyer;
    useEffect(() => {
        if (lawyer) {
            setLawyerData({  
                law_firm:       lawyer.law_firm,
                lawyer_address: lawyer.lawyer_address,
                city_state_zip: lawyer.city_state_zip,
                lawyer_email:   lawyer.lawyer_email,    
                lawyer_phone:   lawyer.lawyer_phone,
                lawyer_fax:     lawyer.lawyer_fax
            })
            setInputValue(lawyer.lawyer)
        } 
    },[lawyer])

    useEffect(() => {
        if (genDoc) {
            generateDocument(documentType);
            setOpen(false);
            setGenDoc(false);
        }
    }, [genDoc, documentType, generateDocument, setOpen]);

    function handleSubmit() {
        handleSubmitLawyer(
            {...lawyerData, lawyer: inputValue, merchant_id: merchant.id}, 
            true, setGenDoc, setError
        )
    }
    const handleGenerate = () => generateDocument(documentType)

    function handleLawyerChange(event) {
        setError('')
        setLawyerData({...lawyerData, [event.target.name]: event.target.value})
    };

    const handleInputChange = (_, newVal) => {setInputValue(newVal); setError('');}

    const handleClose = () => setOpen(false);
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{sx: styles.dialog}}>
            
            <Typography sx={styles.header}>Lawyer Fields Empty</Typography>

            <Box sx={styles.buttonContainer}>
                <Button 
                    variant="outlined" 
                    startIcon={<WarningIcon />} 
                    onClick={() => generateDocument(documentType)}
                    sx={styles.overrideButton}
                >
                    Override
                </Button>

                <Button 
                    variant="outlined" 
                    startIcon={<PersonAddIcon />}
                    onClick={() => setIsExapanded(true)}
                    sx={styles.AddInfoButton}
                >
                    Add Lawyer Information
                </Button>
            </Box>

            {isExpanded ? (
                <>
                <Divider sx={styles.dividerMargin}/>

                <CloseIcon sx={styles.closeIcon} onClick={() => setIsExapanded(false)}/>

                <Box sx={styles.inputContainer}>
                    <Box mx={1}>
                        <Typography sx={styles.inputLabel}>Law Firm*</Typography>
                        <LawFormField
                            lawyer={merchant.lawyer} 
                            name="law_firm" 
                            value={lawyerData.law_firm}
                            handleChange={handleLawyerChange}
                            inputProps="reginputProps"
                            InputProps="regInputProps"
                        />
                    </Box>
                    <Box mx={1}>
                        <Typography sx={styles.inputLabel}>Lawyer*</Typography>
                        <AutoComplete 
                            handleInputChange={handleInputChange}
                            inputValue={inputValue}
                            merchantId={merchant.id}
                            inputProps="reginputProps"
                            InputProps="regInputProps"
                        />
                    </Box>
                </Box>

                <Box sx={styles.inputContainer}>
                    <Box mx={1} mt={1.5}>
                        <Typography sx={styles.inputLabel}>Lawyer Address*</Typography>
                        <LawFormField
                            lawyer={merchant.lawyer} 
                            name="lawyer_address"
                            value={lawyerData.lawyer_address}
                            handleChange={handleLawyerChange}
                            inputProps="reginputProps"
                            InputProps="regInputProps"
                        />
                    </Box>
                    <Box mx={1} mt={1.5}>
                        <Typography sx={styles.inputLabel}>City State Zip*</Typography>
                        <LawFormField
                            lawyer={merchant.lawyer} 
                            name="city_state_zip"
                            value={lawyerData.city_state_zip}
                            handleChange={handleLawyerChange}
                            inputProps="reginputProps"
                            InputProps="regInputProps"
                        />
                    </Box>
                </Box>

                <Box sx={styles.inputContainer}>
                    <Box mx={1} mt={1.5}>
                        <Typography sx={styles.inputLabel}>Lawyer Tel*</Typography>
                        <LawFormField
                            lawyer={merchant.lawyer} 
                            name="lawyer_phone"
                            value={lawyerData.lawyer_phone}
                            handleChange={handleLawyerChange}
                            inputProps="reginputProps"
                            InputProps="regInputProps"
                        />
                    </Box>
                    <Box mx={1} mt={1.5}>
                        <Typography sx={styles.inputLabel}>Lawyer Fax*</Typography>
                        <LawFormField
                            lawyer={merchant.lawyer} 
                            name="lawyer_fax"
                            value={lawyerData.lawyer_fax}
                            handleChange={handleLawyerChange}
                            inputProps="reginputProps"
                            InputProps="regInputProps"
                        />
                    </Box>
                </Box>
                
                <Box mx={1} mt={1.5}>
                    <Typography sx={styles.inputLabel}>Lawyer Email*</Typography>
                    <LawFormField
                        lawyer={merchant.lawyer} 
                        name="lawyer_email"
                        value={lawyerData.lawyer_email}
                        handleChange={handleLawyerChange}
                        inputProps="reginputProps"
                        InputProps="regInputProps"
                    />
                </Box>
                <Button sx={styles.genButton} onClick={merchant.lawyer ? handleGenerate : handleSubmit}>
                    Generate
                </Button>
                <Typography sx={styles.error}>{error}</Typography>
                </>
                ):(
                null
            )}
        </Dialog>
    );
};
export default OverrideModal;