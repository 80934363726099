import React from "react"
import MerchantDataField from "./MerchantDataField"
import Box from '@mui/material/Box'
import styles from './merchantStyles'

function MerchantsData({data, handleChange, handleCurrentDateChange, handleBlur, selectedDate}) {

    const leftDataArray = [
        {
            key: 'Date Submitted',
            name: 'created',
            value: data.created
        },
        {
            key: 'Funder',
            name: 'lender_legal_name_title_case',
            value: data.lender_legal_name_title_case
        },
        {
            key: 'Agreement Date',
            name: 'agreement_date',
            value: data.agreement_date
        },
        {
            key: 'Contract Payoff Date',
            name: 'contract_payoff_date', 
            value: data.contract_payoff_date
        },
        {
            key: 'Merchants Legal Name',
            name: 'merchants_legal_name_title_case',
            value: data.merchants_legal_name_title_case
        },
        {
            key: 'D.B.A',
            name: 'd_b_a_title_case',
            value: data.d_b_a_title_case
        },
        {
            key: 'State of Incorporation',
            name: 'state_of_incorporation',
            value: data.state_of_incorporation
        },
        {
            key: 'Type of Entity',
            name: 'type_of_entity',
            value: data.type_of_entity
        },
        {
            key: 'Federal Tax ID',
            name: 'federal_tax_id',
            value: data.federal_tax_id
        },
        {
            key: 'Phone # 1',
            name: 'mobile',
            value: data.mobile
        },
        {
            key: 'Phone # 2',
            name: 'business_phone',
            value: data.business_phone
        },
        {
            key: 'Email',  
            name: 'email_address',  
            value: data.email_address
        },
        {
            key: 'Business Address',
            name: 'physical_address',
            value: data.physical_address
        },
        {
            key: 'Business City',
            name: 'physical_city',
            value: data.physical_city
        },
        {
            key: 'Business State',
            name: 'physical_state',
            value: data.physical_state
        },
        {
            key: 'Business Zip',
            name: 'physical_zip',
            value: data.physical_zip
        },
        {
            key: 'Contact Address',
            name: 'mailing_address',
            value: data.mailing_address
        },
        {
            key: 'Contact City',
            name: 'city',
            value: data.city
        },
        {
            key: 'Contact State',
            name: 'state',
            value: data.state
        },
        {
            key: 'Contact Zip',
            name: 'zip',
            value: data.zip
        },
        {
            key: 'Guarantor # 1 SSN',
            name: "ssn_first_guar",
            value: data.ssn_first_guar
        },
        {
            key: 'Judgment Date',
            name: 'judgment_date',
            value: data.judgment_date
        }
    ];

    const rightDataArray = [
        {
            key: 'Purchase Price',
            name: 'purchase_price',
            value: data.purchase_price
        },
        {
            key: 'Purchased Amount',
            name: 'purchased_amount',
            value: data.purchased_amount
        },
        {
            key: 'Purchased Percentage',
            name: 'purchased_percentage',
            value: data.purchased_percentage
        },
        {
            key: 'Remittance',
            name: 'remittance',
            value: data.remittance
        },
        {
            key: 'Payment Frequency',
            name: 'payment_frequency',
            value: data.payment_frequency
        },
        {
            key: 'Guarantor # 1',
            name: 'first_guarantor_title_case',      
            value: data.first_guarantor_title_case
        },
        {
            key: 'Guarantor # 2',
            name: 'second_guarantor_title_case',       
            value: data.second_guarantor_title_case
        },
        {
            key: 'Bank Name',
            name: 'bank_name',
            value: data.bank_name
        },  
        {
            key: 'Funding date',
            name: 'image_date',  
            value: data.image_date
        },
        {
            key: 'Default Date',
            name: 'default_date',
            value: data.default_date
        }, 
        {
            key: 'Balance', 
            name: 'balance',
            value: data.balance
        },
        {
            key: 'NSF',
            name: 'nsf',
            value: data.nsf
        },  
        {
            key: 'Default Fee',
            name: 'default_fee',
            value: data.default_fee
        },
        {
            key: 'Stacking',
            name: 'stacking',
            value: data.stacking
        }, 
        {
            key: 'Damages',
            name: 'damages',
            value: data.damages
        },
        {
            key: 'Legal',
            name: 'legal',
            value: data.legal
        },
        {
            key: 'Full', 
            name: 'total',
            value: data.total
        },
        {
            key: '20 Week Payoff',
            name: 'total_pb_amount_twenty',
            value: data.total_pb_amount_twenty
        },
        {
            key: '26 Week Payoff',
            name: 'total_pb_amount',
            value: data.total_pb_amount
        },
        {
            key: 'Current date',
            value: selectedDate
        },
        {
            key: 'Guarantor # 2 SSN',
            name: 'ssn_second_guar',
            value: data.ssn_second_guar
        },
        {
            key: 'Judgment Amount',
            name: 'judgment_amount',
            value: data.judgment_amount
        }
    ];

    const merchantDataLeft = leftDataArray.map(row => (
        <MerchantDataField
            key={row.key} 
            row={row} 
            handleChange={handleChange} 
            handleCurrentDateChange={handleCurrentDateChange}
            handleBlur={handleBlur}  
        />
    ));

    const merchantDataRight = rightDataArray.map(row => (
        <MerchantDataField
            key={row.key} 
            row={row} 
            handleChange={handleChange} 
            handleCurrentDateChange={handleCurrentDateChange}
            handleBlur={handleBlur}
        />
    ));

    return (
    
        <Box sx={styles.dataBox}>
            <Box sx={styles.innerData}>{merchantDataLeft}</Box>
            <Box sx={styles.innerData}>{merchantDataRight}</Box>
        </Box> 
    );
};
export default MerchantsData;