import React, { useState, useContext } from 'react';
import { AppContext } from "../AppContext";
import { useHistory, Link } from "react-router-dom";
import styles from './styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

function NavBar() {
    
    const {
        setUser, 
        user, 
        // setSignUpFormVisible
    } = useContext(AppContext);
    
    const [open, setOpen] = useState(false);

    const handleDrawerToggle = () => setOpen(!open);
    
    const history = useHistory();
    
    function handleClick() {
        fetch('/logout', { method: 'DELETE' } )
        .then(() => { 
            setUser(null); 
            history.push('/') 
        })
    };
    
    return (

        <Box>
            <CssBaseline />
            <MenuIcon onClick={handleDrawerToggle} sx={styles.menuIcon}/>
    
            <Drawer open={open} onClick={handleDrawerToggle} sx={{'& .MuiDrawer-paper': styles.navBox}}>
            
                <Box sx={styles.boxMargin}>
                    {user.boss ?
                        <>
                        <ListItem>
                            <HomeOutlinedIcon />
                            <Link to="/" style={styles.link}>Home</Link>
                        </ListItem>

                        <ListItem>
                            <FormatListBulletedOutlinedIcon />
                            <Link to="/deal-list" style={styles.link}>Deal list</Link>
                        </ListItem>
        
                        {/* <ListItem>
                            <EmailOutlinedIcon />
                            <Link to="/" style={styles.link} onClick={() => setSignUpFormVisible(true)}>Send sign up link</Link>
                        </ListItem> */}

                        <ListItem>
                            <GavelIcon />
                            <Link to="/lawyers-page" style={styles.link}>Lawyers</Link>
                        </ListItem>

                        <ListItem sx={styles.linkMgBm}>
                            <AccountBalanceOutlinedIcon />
                            <Link to="/banks-page" style={styles.link}>Banks</Link>
                        </ListItem>
                            
                        <Divider/> 
                        </>
                        :
                        null
                    }
                </Box>

                <Box sx={styles.boxMargin}>
                    <Box sx={styles.userBox}>
                        <AccountCircleIcon  sx={styles.userIcon}/>
                        <Typography sx={styles.userName}>{user.name}</Typography>
                    </Box>

                    <Typography onClick={handleClick} sx={styles.logout}>Log out</Typography>
                </Box>
        
            </Drawer>
        </Box>
    );
};
export default NavBar;