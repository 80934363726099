import React, { useState, useContext } from 'react'
import styles from './styles'
import PdfLoadingModal from  "../PdfLoadingModal"
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import WarningIcon from '@mui/icons-material/Warning';
import { AppContext } from "../AppContext"
import { saveAs } from 'file-saver'
import { PDFDocument } from "pdf-lib";
import JSZip from 'jszip';
import bankRestrainingNotice from '../../templates/Bank Restraining Notice.docx'
import bankRestraintAos from '../../templates/Bank Restraint Aos.docx'
import { convertDocxToPdf, docxTemplateGenerate } from '../utils'
import PizZipUtils from 'pizzip/utils/index.js'

function BankRestraintGenerator({ open, setOpen, merchant, selectedDate }) {
    const [showWarning, setShowWarning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState("");
    const { banks, court } = useContext(AppContext);

    let solProp;
    if (merchant && merchant.type_of_entity) {
        const merchantName  = merchant.merchants_legal_name_title_case
        const firstGuarName = merchant.first_guarantor_title_case
        const entityType = merchant.type_of_entity.toLowerCase()
        solProp = merchantName === firstGuarName || entityType === "sole proprietorship";
    }
    const data = {...merchant, court: court, date: selectedDate, dateCaps: selectedDate.toUpperCase(), solProp: solProp}

    async function mergeRestraintWithLabel(label, pdfUrl) {
        try {
            const pdfResponse = await fetch(pdfUrl).then((res) => res.arrayBuffer());
            const pdfDocument = await PDFDocument.load(pdfResponse);
            const mailingLbl = await PDFDocument.load(label);

            const mailingLblPages = mailingLbl.getPages();
            const [existingPage]  = pdfDocument.getPages();
            const {width, height} = existingPage.getSize();
            
            const embeddedPage = await pdfDocument.embedPage(mailingLblPages[0]);
            const mainPage = pdfDocument.insertPage(0, [width, height]);
            mainPage.drawPage(embeddedPage, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
            const pdfBytes = await pdfDocument.save();
            return new Blob([pdfBytes], { type: 'application/pdf' });
        } 
        catch {
            setError("An error occurred during generation");
        }
    }

    async function getMailingLabel(bank) {
        const response = await fetch('/get-certified-mail-labels', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                to_name: bank.name,
                attention: bank.attention,
                mailing_address: bank.address1,
                address2: bank.address2,
                city: bank.city,
                state: bank.state,
                zip: bank.zip.trim()
            })
        });
        if (response.ok) {
            return await response.json();
        }
        else {
            const data = await response.json();
            setError(`${data.error} for one or more bank restraints`);
            return data;
        }
    }

    function loadFile(url) {
        return new Promise((resolve) => {
            PizZipUtils.getBinaryContent(url, (error, content) => {
                resolve(content);
            });
        });
    }

    async function generateDocumentLabels(bank, zipNtcs, zipAfms){
        const rstFname = `Bank Restraining Notice - ${merchant.merchants_legal_name_title_case} - ${bank.name}.pdf`
        const aosFname = `Bank Restraint AOS - ${merchant.merchants_legal_name_title_case} - ${bank.name}.pdf`
        const bankData = {
            name: bank.name,
            attention: bank.attention,
            address1: bank.address1,
            address2: bank.address2,
            bankCity: bank.city, 
            bankState: bank.state, 
            bankZip: bank.zip
        }
        const labelResult = await getMailingLabel(bank)
        const rstContent = await loadFile(bankRestrainingNotice)
        const rstDoc = docxTemplateGenerate(rstContent, {...data, ...bankData});
        const rstPdfUrl = await convertDocxToPdf(rstDoc);
        if (labelResult.Data.LabelImage) {
            const mergedPdf = await mergeRestraintWithLabel(labelResult.Data.LabelImage, rstPdfUrl)
            zipNtcs.file(rstFname, mergedPdf);
        } else {
            const rstResponse = await fetch(rstPdfUrl);
            const rstBlob = await rstResponse.blob();
            zipNtcs.file(rstFname, rstBlob);
        }
        const aosContent = await loadFile(bankRestraintAos)
        const aosDoc = docxTemplateGenerate(aosContent, {...data, ...bankData, tracking_number: labelResult.Data.PIC});
        const aosPdfUrl = await convertDocxToPdf(aosDoc);
        const aosResponse = await fetch(aosPdfUrl);
        const aosBlob = await aosResponse.blob();
        zipAfms.file(aosFname, aosBlob)
    }

    async function generateDocumentBox(bank, zipNtcs, zipAfms){
        const rstFname = `Bank Restraining Notice - ${merchant.merchants_legal_name_title_case} - ${bank.name}.pdf`
        const aosFname = `Bank Restraint AOS - ${merchant.merchants_legal_name_title_case} - ${bank.name}.pdf`
        const bankData = {
            name: bank.name,
            attention: bank.attention,
            address1: bank.address1,
            address2: bank.address2,
            bankCity: bank.city, 
            bankState: bank.state, 
            bankZip: bank.zip,
            tracking_number: bank.track_no
        }

        const rstContent = await loadFile(bankRestrainingNotice)
        const aosContent = await loadFile(bankRestraintAos)

        const rstDoc = docxTemplateGenerate(rstContent, {...data, ...bankData});
        const aosDoc = docxTemplateGenerate(aosContent, {...data, ...bankData});

        const rstPdfUrl = await convertDocxToPdf(rstDoc);
        const aosPdfUrl = await convertDocxToPdf(aosDoc);

        const rstResponse = await fetch(rstPdfUrl);
        const aosResponse = await fetch(aosPdfUrl);

        const rstBlob = await rstResponse.blob();
        const aosBlob = await aosResponse.blob();

        zipNtcs.file(rstFname, rstBlob);
        zipAfms.file(aosFname, aosBlob)
    }

    async function handleClick(type) {
        const allTrackNos = banks.every(bank => bank.track_no)
        if (banks.length < 1) {
            setError("No banks available for generating restraints.")
            return;
        }
        if (type === "box" && !allTrackNos) {
            setError("All bank rows must have tracking numbers to proceed");
            return;
        }
        if (type === "labels") {
            setDisable(true)
        }
        setIsLoading(true)
        const zipNtcs = new JSZip();
        const zipAfms = new JSZip();
        if (type === "box") {
            for (const bank of banks) {
                await generateDocumentBox(bank, zipNtcs, zipAfms)
            }
        }
        if (type === "labels") {
            for (const bank of banks) {
                await generateDocumentLabels(bank, zipNtcs, zipAfms)
            }
        }
        zipNtcs.generateAsync({ type: 'blob' }).then((zipBlob) => {
            saveAs(zipBlob, `Bank Restraining Notices.zip`);
        });
        zipAfms.generateAsync({ type: 'blob' }).then((zipBlob) => {
            saveAs(zipBlob, `Bank Restraining Affirmations.zip`);  
        });
        setIsLoading(false);
    }

    function handleClose() {
        setOpen(false); 
        setDisable(false);
        setShowWarning(false);
        setError("");
    };
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{sx: styles.container}}>
            <PdfLoadingModal open={isLoading}/>
            <Typography sx={styles.title}>Bank Restraints</Typography>
            {showWarning ? (
                <>
                <WarningIcon sx={styles.warningIcon}/>
                <Typography sx={styles.warning}>
                    You are about to generate bank restraints with mailing labels. 
                    Proceeding may incur significant costs, depending on the number 
                    of items. Are you sure you want to continue?
                </Typography>
                <Typography sx={styles.back} onClick={() => setShowWarning(false)}>
                    Go back
                </Typography>
                <Button 
                    sx={styles.button}
                    disabled={disable? true : false}
                    onClick={disable ? null : () => handleClick("labels")}
                >
                    Generate Restraints
                </Button>
                <Typography sx={styles.error}>{error}</Typography>
                </>
                ):(
                <>
                <Button sx={styles.button} onClick={() => handleClick("box")}>Box</Button>
                <Button sx={styles.button} onClick={() => setShowWarning(true)}>Mailing</Button>
                <Typography sx={styles.error}>{error}</Typography>
                </>
            )}
        </Dialog>
    )
}
export default BankRestraintGenerator