import React, { useState, useContext } from 'react';
import styles from '../merchants/merchantStyles';
import Button from '@mui/material/Button';
import PdfLoadingModal from  "../PdfLoadingModal"
import { saveAs } from 'file-saver'
import { AppContext } from "../AppContext"
import judgmentDebtor from '../../templates/Judgment Debtor.docx';
import { convertDocxToPdf, docxTemplateGenerate, loadFile } from '../utils';

function JudgmentDebtor({ merchant, selectedDate }) {
    const [isLoading, setIsLoading] = useState(false)
    const { court } = useContext(AppContext);

    let solProp;
    if (merchant && merchant.type_of_entity) {
        const merchantName  = merchant.merchants_legal_name_title_case
        const firstGuarName = merchant.first_guarantor_title_case
        const entityType = merchant.type_of_entity.toLowerCase()
        solProp = merchantName === firstGuarName || entityType === "sole proprietorship";
    }

    const data = {date: selectedDate, court: court, solProp: solProp, dateCaps: selectedDate.toUpperCase()}

    function handleClick() {
        setIsLoading(true)
        loadFile(judgmentDebtor, async (error, content) => {
            const out = docxTemplateGenerate(content, {...merchant, ...data});
            const pdfUrl = await convertDocxToPdf(out);
            saveAs(pdfUrl, `Judgment Debtor - ${merchant.merchants_legal_name_title_case}`)
            setIsLoading(false)
        })
    }
    
    return (
        <>
            <PdfLoadingModal open={isLoading}/>
            <Button sx={styles.btn} onClick={handleClick}>
                Notice of Judgment Debtor
            </Button>
        </>
    )
}
export default JudgmentDebtor