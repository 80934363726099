import React from 'react';
import styles from './payoffModalStyles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function EmailSuccessModal({ open, successMessage }) {

    return (

        <Dialog open={open} maxWidth="xl" PaperProps={{sx: styles.dialog}}>

            <Box sx={styles.box}>
                {successMessage === "Please Wait..." ? (
                    null 
                    ):( 
                    <CheckCircleIcon sx={styles.checkIcon}/>
                )}
                <Typography sx={styles.successMessage}>{successMessage}</Typography>
            </Box>

        </Dialog>
    );
};
export default EmailSuccessModal;