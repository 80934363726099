import React, { useState, useContext } from "react";
import styles from "./styles";
import ColumnHeader from "./ColumnHeader";
import LawyerPopover from "./LawyerPopover";
import LawyerRow from "./LawyerRow";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { AppContext } from "../AppContext";

function LawyerTable() {
   const { lawyers } = useContext(AppContext);

   const [anchorEl, setAnchorEl] = useState(null);
   const [column, setColumn] = useState("");
   const [search, setSearch] = useState("");
   const [sortCriteria, setSortCriteria] = useState("");

   function handleClick(event, column) { 
      setAnchorEl(event.currentTarget);
      setColumn(column);
      setSortCriteria("");
   };
   const handleSortClick = () => setSortCriteria(column);
    
   const handleSearchChange = (event) => setSearch(event.target.value);

   const sortedLawyers = lawyers.sort((a, b) => {
      if (sortCriteria === "") {
         return a.id - b.id
      } else {
         return a[sortCriteria].localeCompare(b[sortCriteria])
      }
   })
   const lawyerList = sortedLawyers
      .filter((lawyer) => {
         if (column === '') {
            return true
         }
         return lawyer[column]?.toLowerCase().includes(search.toLowerCase())
      })
      .map(lawyer => <LawyerRow key={lawyer.id} lawyer={lawyer} />
   );

   const cols = [
      {name: 'Law Firm',       column: 'law_firm',       width: "350px"},
      {name: 'Lawyer',         column: 'lawyer',         width: "225px"},
      {name: 'Lawyer Address', column: 'lawyer_address', width: "275px"},
      {name: 'City State Zip', column: 'city_state_zip', width: "250px"},
      {name: 'Lawyer Email',   column: 'lawyer_email',   width: "220px"},
      {name: 'Lawyer Tel',     column: 'lawyer_phone',   width: "130px"},
      {name: 'Lawyer Fax',     column: 'lawyer_fax',     width: "130px"},
   ];
   const columnHeaders = cols.map(col => <ColumnHeader key={col.name} col={col} handleClick={handleClick}/>);

   const handleClose = () => setAnchorEl(null);
   return (
      <>
         <TableContainer sx={styles.tblContainer}>
            <Table>
               <TableHead>
                  <TableRow>
                     {columnHeaders}
                     <TableCell sx={styles.lastHdrCell}>Trash</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {lawyerList.length > 0 ?
                     lawyerList
                     :
                     <TableRow>
                        <TableCell sx={styles.noRows} colSpan={8}>
                           No Rows to Show
                        </TableCell>
                     </TableRow>
                  }
               </TableBody>
            </Table>
         </TableContainer> 
         <LawyerPopover
            anchorEl={anchorEl}
            handleClose={handleClose} 
            search={search}
            handleSortClick={handleSortClick}
            handleSearchChange={handleSearchChange}
         /> 
      </>
   );
};
export default LawyerTable;