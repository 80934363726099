import React, { useState } from "react";
import DropZone from "../shared/DropZone";
import PdfLoadingModal from  "../PdfLoadingModal";
import mailingCoverPage from '../../templates/Mailing Cover Page.docx';
import styles from "./styles";
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import { PDFDocument } from "pdf-lib";
import { getParticipantsData } from "./servicesData";
import { loadFile , convertDocxToPdf, docxTemplateGenerate } from "../utils";

const guarantors = ["guar1", 'guar2'];

function ServicesGenerator({ open, setOpen, merchant }) {
    const [droppedFile, setDroppedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    // Determine if there is a second guarantor
    const guarantorTwo = merchant.second_guarantor_title_case

    async function mergeLawsuitWithLabel(label, filename) {
        try {
            const droppedDoc = await droppedFile.arrayBuffer();
            const idxLawsuit = await PDFDocument.load(droppedDoc);
            const mailingLbl = await PDFDocument.load(label);
            const mailingLblPages = mailingLbl.getPages();
            const [existingPage]  = idxLawsuit.getPages();
            const {width, height} = existingPage.getSize();
            const embeddedPage = await idxLawsuit.embedPage(mailingLblPages[0]);
            const mainPage = idxLawsuit.insertPage(0, [width, height]);
            mainPage.drawPage(embeddedPage, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
            const pdfBytes = await idxLawsuit.save();
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(pdfBlob, filename);
        } 
        catch {
            setError("An error occurred during generation");
        }
    }
    
    function createDocument(name) {
        loadFile(
            mailingCoverPage,
            async (error, content) => {
                const out = docxTemplateGenerate(content, {...merchant, name: name})
                const pdfUrl = await convertDocxToPdf(out)
                const label  = await fetch(pdfUrl).then((res) => res.arrayBuffer());
                mergeLawsuitWithLabel(label, `Additional Mailing - ${name}.pdf`)
            }
        )
    }
    
    function getMailingLabels(name, parType) {
        setIsLoading(true)
        const filename = `Summons & Complaint - MAILING - ${name}.pdf`
        fetch('/get-certified-mail-labels', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({...merchant, to_name: name, par_type: parType})
        })
        .then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    mergeLawsuitWithLabel(data.Data.LabelImage, filename)
                    if (guarantors.includes(parType)) { createDocument(name) }
                    setDroppedFile(null)
                    setIsLoading(false)
                    setOpen(false) 
                })
            } else {
                response.json().then(data => {
                    setError(data.error)
                    setIsLoading(false)
                })
            }
        })    
    }
    
    function generateDocuments() {
        if(!droppedFile) {
            setError("Please drop indexed lawsuit into dropzone to proceed")
            return;
        }
        const participants = getParticipantsData(merchant, guarantorTwo)
        participants.forEach(par => getMailingLabels(par.name, par.type))
    }
    const handleClose = () => setOpen(false);

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{sx: styles.container}} maxWidth="xl">
            <PdfLoadingModal open={isLoading}/>
            <Typography sx={styles.title}>SERVICES</Typography> 
            <DropZone
                droppedFile={droppedFile} 
                setDroppedFile={setDroppedFile}
                fileLabel="Indexed Lawsuit"
                setError={setError}
            />
            <Button sx={styles.button} onClick={generateDocuments}>Attach Mailing Labels</Button>
            <Typography sx={styles.error}>{error}</Typography>
        </Dialog>
    )
}
export default ServicesGenerator