function getParticipantsData(merchant, guarantorTwo) {
    const merchantName  = merchant.merchants_legal_name_title_case
    const firstGuarName = merchant.first_guarantor_title_case
    const entityType = merchant.type_of_entity.toLowerCase()
    const isNotSoleProp = merchantName !== firstGuarName && entityType !== "sole proprietorship";

    const participantsData = [ {pic: merchant.guar1_track_no} ]

    if(isNotSoleProp) {
        participantsData.push({ pic: merchant.merch_track_no })
    }

    if (guarantorTwo && isNotSoleProp) participantsData.push({pic: merchant.guar2_track_no})
    return participantsData;
}

function getAffidavitData(merchant, guarantorTwo) {
    const merchantName  = merchant.merchants_legal_name_title_case
    const firstGuarName = merchant.first_guarantor_title_case
    const entityType = merchant.type_of_entity.toLowerCase()
    const isNotSoleProp = merchantName !== firstGuarName && entityType !== "sole proprietorship";

    const documentData = [
        {
            filename: `Affidavit of Service - ${merchant.first_guarantor_title_case}.pdf`,
            data: {
                solProp: isNotSoleProp ? false : true,
                trackingNo: merchant.guar1_track_no,
                defendant: merchant.first_guarantor_title_case,
                defendantAllCaps: merchant.first_guarantor,
                merchDba: isNotSoleProp ? false : merchant.d_b_a_title_case 
            },
            aosAm: 'guar1'
        }
    ]

    if (isNotSoleProp) {
        documentData.push({
            filename: `Affidavit of Service - ${merchant.merchants_legal_name_title_case}.pdf`,
            data: {
                trackingNo: merchant.merch_track_no ,
                defendant: merchant.merchants_legal_name_title_case,
                defendantAllCaps: merchant.merchants_legal_name,
                merchDba: merchant.d_b_a_title_case
            }
        })
    }

    if (guarantorTwo && isNotSoleProp) {
        documentData.push(
            {
                filename: `Affidavit of Service - ${merchant.second_guarantor_title_case}.pdf`,
                data: { 
                    trackingNo: merchant.guar2_track_no,
                    defendant: merchant.second_guarantor_title_case,
                    defendantAllCaps: merchant.second_guarantor
                },
                aosAm: 'guar2'
            }
        )
    }
    return documentData;
}

function getAddMailData(merchant) {
    const merchantName  = merchant.merchants_legal_name_title_case
    const firstGuarName = merchant.first_guarantor_title_case
    const entityType = merchant.type_of_entity.toLowerCase()
    const isNotSoleProp = merchantName !== firstGuarName && entityType !== "sole proprietorship";

    const documentData = {
        guar1: {
            filename: `AOS - Additional Mailing - ${merchant.first_guarantor_title_case}.pdf`,
            data: {
                solProp: isNotSoleProp ? false : true,
                defendant: merchant.first_guarantor_title_case,
                defendantAllCaps: merchant.first_guarantor
            }
        },
        guar2: {
            filename: `AOS - Additional Mailing - ${merchant.second_guarantor_title_case}.pdf`,
            data: {
                defendant: merchant.second_guarantor_title_case,
                defendantAllCaps: merchant.second_guarantor
            }
        }
    }
    return documentData;
}
export { getParticipantsData, getAffidavitData, getAddMailData};