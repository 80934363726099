const styles = {

    // =============== SettlementContainer.js ============== //
    container: {
        border: 'solid 1px #ccc', 
        height: '93vh',
        minWidth: '1700px',
        borderRadius: '5px'
    },

    headingBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1
    },
    
    arrowIcon: {
        fontSize: 40,
        cursor: 'pointer',
        '&:hover': { color: '#FF9900'}
    },

    settlement: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '2.3rem', 
        fontWeight: 'bold',
        fontStyle: 'italic',
        textAlign: 'center',
        flexGrow: 1
    },

    title: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '2rem',
        textAlign: 'center',
        lineHeight: 1
    },

    midSection: {
        display: 'flex', 
        justifyContent: 'center', 
        minHeight: '23vh',
        mb: 2.5
    },

    bottomSection: {
        display: 'flex', 
        justifyContent: 'center'
    },

    generationError: {
        fontFamily: 'AmazonEmberRegular',
        color: '#c62828',
        textAlign: 'center',
        pt: 1
    },
    
    // =========== SettlementDashboard.js - DashboardField.js =========== //

    dashboardContainer: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '65%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        py: 1,
        px: 4,
        my: 2
    },

    dashboardBox: {
        display: 'flex',
        alignItems: 'baseline'
    },

    dashboardKey: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1.2rem',
        fontWeight: 'bold'
    },

    dashboardValue: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '1rem',
        pl: 1
    },

    // ======== SettlementCalculator.js - CalculationField.js ======= //

    calculatorContainer: {
        display: 'flex', 
        justifyContent: 'center',
        mb: 2.5
    },

    calculatorBox: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        m: 1,
        p: 1
    },
    
    actionPanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '330px',
        m: 1,
        p: 1
    },

    optionItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    inputRightToCureWeeks: {
        height: '10px'
    },

    InputRightToCureWeeks:{
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '50px'   
    },
    
    centeredFlex: {
        display: 'flex',
        alignItems: 'center'
    },

    checkboxLabel: {
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold'
    },
    
    iconMargin: {
        mr: 0.5
    },

    checkboxMargin: {
        py: 0.7
    },

    reset: {
        width: '100%',
        fontFamily: 'AmazonEmberRegular',
        textTransform: 'none',
        borderRadius: 0,
        mt: 1,
        p: 0.5
    },

    calculationFieldBox: {
        display: 'flex'
    },

    calculationFieldHeight: {
        height: '10px'
    },
    
    calculationFieldKey: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold',
        width: '180px',
        backgroundColor: '#A7C7E7',
        borderBottom: 'solid 1px',
        paddingLeft: '10px'
    },

    calculationFieldValue: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '150px',
        borderRadius: '0px'
    },

    calculationFieldPayoff: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '50px',
        borderRadius: '0px'
    },

    payoffAmount: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '100px', 
        border: 'solid 1px #ccc', 
        px: 1
    },

    calculationFieldLegal: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '115px',
        borderRadius: '0px'
    },

    calculationFieldLegalPercentage: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        width: '35px',
        borderRadius: '0px'
    },

    calculationPercentageInput: {
        height: '10px',
        paddingLeft: '0px',
        paddingRight: "0px",
        textAlign: 'center'
    },

    // =========== SettlementTurnover.js - TurnoverCard.js =========== //
    
    turnoverFieldRecipient: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '225px',
        margin: 2
    },

    turnoverFieldAmount: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '115px',
        margin: 2
    },

    turnoverFieldDate: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '95px',
        margin: 2
    },

    // ======== SettlementPaymentPlan.js - PaymentPlanCard.js ======== //
    
    paymentFieldAmount: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '115px',
        margin: 2
    },

    paymentFieldGeneral: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '90px',
        margin: 2
    },

    paymentFieldDay: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '105px',
        margin: 2
    },
    
    paymentFieldStartDate: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        width: '135px',
        margin: 2
    },

    select: { 
        fontFamily: "AmazonEmberRegular", 
        fontSize:'0.8rem', 
        color: 'black',
        width: '100px', 
        height: '25px'
    },

    menuItem: {
        fontFamily: "AmazonEmberRegular", 
        fontSize:'0.8rem', 
        py: 0.3
    },

    total: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        border: 'solid 1px #ccc',
        borderRadius: '4px',
        width: '100px',        
        px: 1.5,
        py: 0.3,
        ml: 0.5,
        mr: 0.5,
        backgroundColor: '#E0E0E0'
    },

    // ========== Settlement Global Styles ========== //

    sectionTitle: {
        fontFamily: 'AmazonEmberRegular',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        fontStyle: 'italic',
        textAlign: 'center',
        pb: 1
    },

    containerBorder: {   
        border: 'solid 1px #ccc'
    },

    fieldBox: {
        display: 'flex', 
        alignItems: 'center'
    },

    divider: {
        my: 0.5
    },

    fieldHeight: {
        height: '8px'
    },

    submitButton: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem',
        boxShadow: 'none',
        textTransform: 'none',
        py: 0.14,
        px: 1
    },

    errors: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.8rem', 
        color: 'red',
        padding: '0.1rem'
    },

    trashIcon: {
        flex: 1,        
        fontSize: 26,
        cursor: 'pointer',
        '&:hover': { color: '#c62828'}
    },

    inputAdornment: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem'
    },

    // ======== SettlementCommunications ======== //

    commButton: {
        fontFamily: 'AmazonEmberRegular',
        width: '180px',
        textTransform: 'none', 
        borderRadius: 0,  
        ml: 1,
        mt: 1
    },

    // =========== SettlementDocuSign.js ========== //

    flex: {
        display: 'flex'
    },

    status: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        color: '#a50e0e',
        backgroundColor: "#fce8e6",
        width: '105px',
        textAlign: 'center',
        mt: 1,
        ml: 1,
        py: 1
    },

    docuSignButton: {
        fontFamily: 'AmazonEmberRegular',
        width: '105px',
        textTransform: 'none',
        borderRadius: 0,        
        mt: 1,
        ml: 1
    },

    // ========== SettlementGenerations ========== //

    genButton: {
        fontFamily: 'AmazonEmberRegular',
        width: '130px',
        textTransform: 'none',
        borderRadius: 0,        
        ml: 1,
        mt: 1
    }
}
export default styles