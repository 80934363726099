import React, { useContext, useState, useEffect } from 'react'
import defaultJudgment from '../../templates/Default Judgment.docx';
import nonMilitaryAffirmation from '../../templates/Non Military Affirmation.docx';
import styles from './styles'
import PdfLoadingModal from "../PdfLoadingModal";
import DefJudgmentForm from './DefJudgForm';
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PizZipUtils from 'pizzip/utils/index.js'
import { PDFDocument } from "pdf-lib"
import { saveAs } from 'file-saver'
import { getDocumentData } from './documentData'
import { AppContext } from "../AppContext";
import { convertDocxToPdf, docxTemplateGenerate, formatCurrency} from "../utils"

function DefJudgmentGenerator({open, setOpen, merchant, selectedDate}) {
    const { court } = useContext(AppContext);

    const [formData, setFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("")
    useEffect(() => {
        if (merchant) {
            setFormData({
                guar1FstName: merchant.guar1_fst_name,
                guar1MdlName: merchant.guar1_mdl_name,
                guar1LstName: merchant.guar1_lst_name,
                guar2FstName: merchant.guar2_fst_name,
                guar2MdlName: merchant.guar2_mdl_name,
                guar2LstName: merchant.guar2_lst_name,
            })
        }
    }, [merchant])

    const guarantorTwo = merchant.second_guarantor_title_case;
    const costDisburse = guarantorTwo ? 590.00 : 575.00;
    const {ssn_first_guar, ssn_second_guar} = merchant;
    let interest;
    let judgmentTotal;
    let ssnNumbersFilled;
    let ssnNumbersFormatted;
    let isNotSolProp;
    const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;

    if (merchant && merchant.type_of_entity) {
        const merchantName  = merchant.merchants_legal_name_title_case
        const firstGuarName = merchant.first_guarantor_title_case
        const entityType = merchant.type_of_entity.toLowerCase()
        isNotSolProp = merchantName !== firstGuarName && entityType !== "sole proprietorship";
    }

    if (merchant.second_guarantor && isNotSolProp) {
        ssnNumbersFilled = ssn_first_guar && ssn_second_guar
        ssnNumbersFormatted = ssnPattern.test(ssn_first_guar) && ssnPattern.test(ssn_second_guar)
    } else {
        ssnNumbersFilled = ssn_first_guar
        ssnNumbersFormatted = ssnPattern.test(ssn_first_guar)
    }

    if (merchant.damages) {
        const defaultDate = new Date(merchant.default_date)
        const todaysDate = new Date()
        const diffDays = todaysDate - defaultDate;
        const diffMs = diffDays / (1000 * 60 * 60 * 24)
        const damages = parseFloat(merchant.damages.replace(/[^\d.-]/g, ''))
        const rawInterest = damages * 0.09 * (diffMs / 365)
        interest = "$" + formatCurrency(rawInterest)
        judgmentTotal = "$" + formatCurrency(damages + rawInterest + costDisburse)
    }

    const additionalData = {
        costDisburse: "$" + formatCurrency(costDisburse),
        totalDisburse: guarantorTwo ? "$390.00" : "$375.00",
        servSummComp:  guarantorTwo ? "$85.00"  : "$70.00",
        interest: interest,
        judgmentTotal: judgmentTotal,
        date: selectedDate,
        court: court
    }

    async function mergePdfWithCertificate(certificate, pdfUrl, filename) {
        const pdfFile = await fetch(pdfUrl).then((res) => res.arrayBuffer());
        const mainDoc = await PDFDocument.load(pdfFile);
        const certDoc = await PDFDocument.load(certificate);

        const certDocPages = certDoc.getPages();
        const certDocLength = certDoc.getPageCount();
        const [existingPage] = mainDoc.getPages();
        const { width, height } = existingPage.getSize();

        for(let i = 0; i < certDocLength; i++) {
            const embeddedPage = await mainDoc.embedPage(certDocPages[i]);
            const insertedPage = mainDoc.insertPage(1 + i, [width, height]);
            insertedPage.drawPage(embeddedPage, {
                x: 0,
                y: 0,
                width: width,
                height: height
            });
        };
        const pdfBytes = await mainDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(pdfBlob, filename);
    }

    function loadFile(url) {
        return new Promise((resolve) => {
            PizZipUtils.getBinaryContent(url, (error, content) => {
                resolve(content);
            });
        });
    }

    async function createDocument(template, data) {
        const content = await loadFile(template)
        const out = docxTemplateGenerate(content, {...merchant, ...data, ...additionalData})
        return await convertDocxToPdf(out)
    }

    async function getScraCertificate(doc) {
        const {fstName, mdlName, lstName, ssn, filename, data} = doc
        const response = await fetch('/instant-scra-search', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                first_name: fstName,
                middle_name: mdlName,
                last_name: lstName,
                ssn: ssn
            })
        });
        const resData = await response.json();
        if (response.ok) {
            const doc = resData.results[0].eligibility.content;
            const pdfUrl = await createDocument(nonMilitaryAffirmation, data)
            await mergePdfWithCertificate(doc, pdfUrl, filename)
        } else {
            setError(resData.error)
        }
    }
    
    async function generateDocument(doc) {
        if (doc.defJudgment) {
            const pdfUrl = await createDocument(defaultJudgment, doc.data)
            saveAs(pdfUrl, doc.filename)
        } else {
            await getScraCertificate(doc)
        }
    }

    async function handleClick() {
        if (!ssnNumbersFilled) {
            setError("SSN numbers must be filled in before generating default judgments.")
            return;
        }
        if (!ssnNumbersFormatted) {
            setError("SSN numbers must be in the XXX-XX-XXXX format.");
            return;
        }
        setIsLoading(true)
        const documents = getDocumentData(merchant, guarantorTwo, formData, isNotSolProp)
        for (const doc of documents) {
            await generateDocument(doc)
        }
        setIsLoading(false)
    }

    function handleChange(event) {
        setError("")
        setFormData({...formData, [event.target.name]: event.target.value})
    }
    const handleClose = () => {setOpen(false); setError("");}

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{sx: styles.container}}>
            <PdfLoadingModal open={isLoading}/>
            <Typography sx={styles.title}>Default Judgment</Typography>
            <Box sx={styles.box}>
                <Typography sx={styles.warning}>
                    *
                </Typography>
                <Typography sx={styles.instructions}>
                    Please verify the first, middle, and last names before proceeding.
                </Typography>
            </Box>
            <DefJudgmentForm 
                formData={formData} 
                merchant={merchant} 
                handleChange={handleChange}
                guarantorTwo={guarantorTwo}
                isNotSolProp={isNotSolProp}
            />
            <Button sx={styles.button} onClick={handleClick}>
                Generate
            </Button>  
            <Typography sx={styles.error}>{error}</Typography>
        </Dialog>
    )
}
export default DefJudgmentGenerator