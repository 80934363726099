const styles = {

    container: {
        display: 'flex',
        alignItems: 'center'
    },

    button: {
        fontFamily: 'AmazonEmberRegular', 
        fontWeight: 'bold',
        color: 'black',
        textTransform: 'none',
        borderRadius: '0px',
        width: '50%',
        display: 'block',
        overflow: "hidden",
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis",
        mt: 1, 
        backgroundColor: '#5d9bd5', 
        '&:hover': { backgroundColor: '#4c88be'}
    },

    st: {
        fontFamily: 'AmazonEmberRegular',
        fontSize: '0.9rem',
        textAlign: 'center',
        width: '50%',
        display: 'block',
        overflow: "hidden",
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis",
        mt: 1,
        ml: 1,
        py: 0.80
    },

    notStarted: {
        color: '#a50e0e',
        backgroundColor: "#fce8e6",
        border: 'solid 1px #f5c6c6'
    },
    
    completed: {
        color: '#155724',
        backgroundColor: "#d4edda",
        border: 'solid 1px #c3e6cb'
    }
}
export default styles