import React from "react";
import GuarantorForm from "./GuarantorForm";
import Divider from '@mui/material/Divider';

function DefJudgmentForm({formData, merchant, handleChange, guarantorTwo, isNotSolProp}) {
    return (
        <>
            <GuarantorForm
                title={merchant.first_guarantor_title_case}
                fstNameVal={formData.guar1FstName}
                mdlNameVal={formData.guar1MdlName}
                lstNameVal={formData.guar1LstName}
                handleChange={handleChange}
                fstName="guar1FstName"
                mdlName="guar1MdlName"
                lstName="guar1LstName"
            />
            <Divider/>
            {guarantorTwo && isNotSolProp ?
                <>
                <GuarantorForm
                    title={merchant.second_guarantor_title_case}
                    fstNameVal={formData.guar2FstName}
                    mdlNameVal={formData.guar2MdlName}
                    lstNameVal={formData.guar2LstName}
                    handleChange={handleChange}
                    fstName="guar2FstName"
                    mdlName="guar2MdlName"
                    lstName="guar2LstName"
                />
                <Divider/>
                </>
                :
                null
            }
        </>
    )
}
export default DefJudgmentForm