import React from "react";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

function PdfLoadingModal({ open }) {

    return (
        
        <Dialog open={open} PaperProps={{sx: {overflow: 'hidden'}}}>
            <CircularProgress />
        </Dialog>
    );
};
export default PdfLoadingModal;